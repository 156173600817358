import { FC } from 'react';
import { Button } from '../../../components';
import { PageSkeleton } from '../../../layout';
import styles from '../../../styles/pages/notifications.module.scss';

const Responses: FC = () => {
  return (
    <PageSkeleton
      nested
      title='Responses'
      header={<Button>Finish campaign</Button>}
    >
      <div className={styles.wrapper}>
        <h1>🛠️</h1>
        <h2>Coming Soon</h2>
        <p>We are working on it.</p>
      </div>
    </PageSkeleton>
  );
};

export default Responses;
