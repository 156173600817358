import classNames from 'classnames';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button, Input } from '../components';
import network from '../core/network';
import { useUser } from '../hooks';
import styles from '../styles/layout/authform.module.scss';

const LoginForm: FC = () => {
  const { setUser } = useUser();
  const location = useLocation();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation(['reset-form', 'common']);
  const params = new URLSearchParams(location.search);
  const token = String(params.get('token'));
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordVerify: '',
    },
    onSubmit: async (values) => {
      if (values.password !== values.passwordVerify) {
        await formik.setValues({ password: '', passwordVerify: '' });
        await formik.setFieldError('password', 'Passwords do not match');
      } else {
        // progress.start();
        const res = await network.user.resetPassword(token, values.password);
        // progress.done();
        if (res.token) {
          setUser({ ...res.user, token: res.token });
          setSuccess(true);
          setTimeout(() => history.replace('/'), 2500);
        } else setError(true);
      }
    },
  });

  const _handleChange = (value: string, name?: string) => {
    formik.setFieldValue(name ?? '', value);
  };
  return (
    <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <img src='/images/logo/triyit-trademark.png' alt='' />
      <h1>{t('title')}</h1>
      {!(success || error) ? (
        <>
          <Input
            type='password'
            className={styles.input}
            icon='user'
            onChange={_handleChange}
            name='password'
            value={formik.values.password}
            placeholder={t('common:password')}
            error={formik.errors.password}
          />
          <Input
            type='password'
            className={styles.input}
            icon='user'
            onChange={_handleChange}
            name='passwordVerify'
            value={formik.values.passwordVerify}
            placeholder={t('common:password-verify')}
          />
          <div className={styles.row}>
            <Link to='/login'>{t('common:login')}</Link>

            <Button onClick={formik.handleSubmit} primary>
              {t('common:reset')}
            </Button>
          </div>
        </>
      ) : success ? (
        <>
          <h2>{t('success')}</h2>
          <div className={classNames(styles.row, styles.center)}>
            <Link to='/'>{t('redirect')}</Link>
          </div>
        </>
      ) : (
        <>
          <h2>{t('error')}</h2>
          <div className={classNames(styles.row, styles.center)}>
            <Link to='/login'>{t('login')}</Link>
          </div>
        </>
      )}
    </form>
  );
};

export default LoginForm;
