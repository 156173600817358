import { FC } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import styles from '../../styles/components/query.module.scss';

interface iQueryProps {
  code: string;
  name?: string;
  setCode?: (val: string) => void;
  onChange?: (value: string, name?: string) => void;
  onSubmit?: () => void;
}
const Query: FC<iQueryProps> = ({
  code,
  setCode,
  onSubmit,
  name,
  onChange,
}) => {
  const _handleChange = (evn: any) => {
    onChange?.(evn.target.value, name);
    setCode?.(evn.target.value);
  };

  const _handleKeyDown = (evn: any) => {
    if (
      ['enter', 'r'].includes(evn.key.toLowerCase()) &&
      (evn.ctrlKey || evn.metaKey)
    ) {
      evn.preventDefault();
      evn.stopPropagation();
      onSubmit?.();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.editor}>
        <CodeEditor
          value={code}
          language='sql'
          placeholder='Please enter sql query.'
          onKeyDown={_handleKeyDown}
          onChange={_handleChange}
          padding={0}
          style={{
            fontSize: 14,
            fontWeight: 500,
            fontFamily:
              '"Fira Code",ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          }}
        />
      </div>
    </div>
  );
};

export default Query;
