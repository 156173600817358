import React from 'react';

export default function IconHourGlass(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-hour-glass</title>
      <g
        id='icon-hour-glass'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,20 L20,20 C20.5522847,20 21,20.4477153 21,21 C21,21.5522847 20.5522847,22 20,22 L4,22 C3.44771525,22 3,21.5522847 3,21 C3,20.4477153 3.44771525,20 4,20 L5,20 C5,18.2085438 5.68341751,16.4170876 7.05025253,15.0502525 L9,13.1005051 L9,10.8994949 L7.05025253,8.94974747 C5.68341751,7.58291245 5,5.79145622 5,4 L4,4 C3.44771525,4 3,3.55228475 3,3 C3,2.44771525 3.44771525,2 4,2 L20,2 C20.5522847,2 21,2.44771525 21,3 C21,3.55228475 20.5522847,4 20,4 L19,4 C19,5.79145622 18.3165825,7.58291245 16.9497475,8.94974747 L15,10.8994949 L15,13.1005051 L16.9497475,15.0502525 C18.3165825,16.4170876 19,18.2085438 19,20 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M17,20 L7,20 L9.82842712,17.1715729 C10.5785726,16.4214274 11,15.4040117 11,14.3431458 L11,10.0710678 L11,10.0710678 L8.46446609,7.53553391 C8.06950306,7.14057087 7.75066628,6.69174337 7.51255271,6.2086248 C7.67799895,6.14915064 7.84078192,6.07960904 8,6 C9.25902921,5.37048539 10.7409708,5.37048539 12,6 C13.2590292,6.62951461 14.7409708,6.62951461 16,6 C16.2256204,5.88718982 16.4583992,5.79459543 16.6957708,5.72221683 C16.4505506,6.39112073 16.0602128,7.01085501 15.5355339,7.53553391 L13,10.0710678 L13,14.3431458 C13,15.4040117 13.4214274,16.4214274 14.1715729,17.1715729 L17,20 L17,20 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
