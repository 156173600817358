import React from 'react';
import ReactDOM from 'react-dom';
import Navigation from './pages';
import reportWebVitals from './reportWebVitals';
import Providers from './providers';
import './styles/main.scss';
import './translation';
import './core/wdyr';

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Navigation />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
