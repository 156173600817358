import React from 'react';

export default function IconLibrary(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-library</title>
      <g
        id='icon-library'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M21,20 C21.5522847,20 22,20.4477153 22,21 C22,21.5522847 21.5522847,22 21,22 L3,22 C2.44771525,22 2,21.5522847 2,21 C2,20.4477153 2.44771525,20 3,20 L21,20 Z M9,11 L8,12 L8,16 L9,17 L4,17 L5,16 L5,12 L4,11 L9,11 Z M14.5,11 L13.5,12 L13.5,16 L14.5,17 L9.5,17 L10.5,16 L10.5,12 L9.5,11 L14.5,11 Z M20,11 L19,12 L19,16 L20,17 L15,17 L16,16 L16,12 L15,11 L20,11 Z M21,8 L21,9 C21,9.55228475 20.5522847,10 20,10 L4,10 C3.44771525,10 3,9.55228475 3,9 L3,8 L21,8 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M20,18 C20.5522847,18 21,18.4477153 21,19 L21,20 L3,20 L3,19 C3,18.4477153 3.44771525,18 4,18 L20,18 Z M12.4061385,2.08618845 L21.4061385,6.08618845 C22.393122,6.52484782 22.0800734,8 21,8 L3,8 C1.91992657,8 1.60687796,6.52484782 2.59386153,6.08618845 L11.5938615,2.08618845 C11.8524269,1.97127052 12.1475731,1.97127052 12.4061385,2.08618845 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
