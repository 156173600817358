import React from 'react';

export default function IconTextCursor(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-text-cursor</title>
      <g
        id='icon-text-cursor'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M12,7.3541756 C12.7329445,6.52375489 13.8053075,6 15,6 C15.5522847,6 16,6.44771525 16,7 C16,7.55228475 15.5522847,8 15,8 C13.8954305,8 13,8.8954305 13,10 L13,14 C13,15.1045695 13.8954305,16 15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C13.8053075,18 12.7329445,17.4762451 12,16.6458244 C11.2670555,17.4762451 10.1946925,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 C10.1045695,16 11,15.1045695 11,14 L11,10 C11,8.8954305 10.1045695,8 9,8 C8.44771525,8 8,7.55228475 8,7 C8,6.44771525 8.44771525,6 9,6 C10.1946925,6 11.2670555,6.52375489 12,7.3541756 L12,7.3541756 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
