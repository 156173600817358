import { FC, createContext, useEffect, useState } from 'react';
import { Provider } from 'use-http';
import { BASE_URL } from '../../core/config';
import network from '../../core/network';

interface iUser {
  id: string;
  firstname: string;
  lastname: string;
  name: string;
  token: string;

  is_admin?: boolean;
}

interface iContext {
  user: iUser | null | undefined;
  setUser: (user: iUser | null) => void;
}

const userKey = '@user';
export const UserContext = createContext<iContext>({
  user: undefined,
  setUser: () => {},
});

const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<iUser | null | undefined>(undefined);

  const _handleLoad = () => {
    const _user = JSON.parse(localStorage.getItem(userKey) || 'null');
    setUser(_user);
    if (_user?.token) _handleFetchUser(_user?.token, _user);
  };

  const _handleFetchUser = async (token: string, user: iUser) => {
    const _user = await network.user.getProfile(token);
    setUser({ ...user, ..._user });
  };

  const _handleSetUser = (user: iUser | null) => {
    setUser(user);
    localStorage.setItem(userKey, JSON.stringify(user));
  };

  useEffect(() => {
    _handleLoad();
  }, []);

  const fetchOptions = {
    headers: { Authorization: `Bearer ${user?.token ?? ''}` },
  };
  return (
    <>
      <UserContext.Provider value={{ user, setUser: _handleSetUser }}>
        <Provider url={BASE_URL} options={fetchOptions}>
          {user !== undefined && children}
        </Provider>
      </UserContext.Provider>
    </>
  );
};

export default UserProvider;
