import React from 'react';

export default function IconDoorExit(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-door-exit</title>
      <g
        id='icon-door-exit'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M11,4 L14,4 C14.5522847,4 15,4.44771525 15,5 L15,8 C15,8.55228475 14.5522847,9 14,9 C13.4477153,9 13,8.55228475 13,8 L13,6 L11,6 L11,18 L13,18 L13,16 C13,15.4477153 13.4477153,15 14,15 C14.5522847,15 15,15.4477153 15,16 L15,19 C15,19.5522847 14.5522847,20 14,20 L11,20 L11,21 C11,21.6643789 10.3640951,22.1440429 9.72527887,21.9615239 L2.74653531,19.9675972 C2.31698758,19.8553862 2,19.4647113 2,19 L2,5 C2,4.53528871 2.31698758,4.14461383 2.74653531,4.03240279 L9.72527887,2.03847605 C10.3640951,1.85595714 11,2.33562112 11,3 L11,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M18.5857864,11 L17.2928932,9.70710678 C16.3500842,8.76429774 17.7642977,7.35008418 18.7071068,8.29289322 L21.7071068,11.2928932 C22.0976311,11.6834175 22.0976311,12.3165825 21.7071068,12.7071068 L18.7071068,15.7071068 C17.7642977,16.6499158 16.3500842,15.2357023 17.2928932,14.2928932 L18.5857864,13 L14,13 C13.4477153,13 13,12.5522847 13,12 C13,11.4477153 13.4477153,11 14,11 L18.5857864,11 L18.5857864,11 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
