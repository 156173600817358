import React from 'react';

export default function IconShoppingBasket(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-shopping-basket</title>
      <g
        id='icon-shopping-basket'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M21.7802236,10 L18.9701425,21.2425356 C18.8588507,21.6877026 18.4588676,22 18,22 L6,22 C5.54113236,22 5.14114925,21.6877026 5.0298575,21.2425356 L2.21922359,10 L21.7802236,10 Z M7.9701425,14.7574644 C7.83619377,14.2216695 7.29325928,13.8959088 6.75746437,14.0298575 C6.22166947,14.1638062 5.89590877,14.7067407 6.0298575,15.2425356 L6.0298575,15.2425356 L7.0298575,19.2425356 C7.16380623,19.7783305 7.70674072,20.1040912 8.24253563,19.9701425 C8.77833053,19.8361938 9.10409123,19.2932593 8.9701425,18.7574644 L8.9701425,18.7574644 Z M17.2425356,14.0298575 C16.7067407,13.8959088 16.1638062,14.2216695 16.0298575,14.7574644 L16.0298575,14.7574644 L15.0298575,18.7574644 C14.8959088,19.2932593 15.2216695,19.8361938 15.7574644,19.9701425 C16.2932593,20.1040912 16.8361938,19.7783305 16.9701425,19.2425356 L16.9701425,19.2425356 L17.9701425,15.2425356 C18.1040912,14.7067407 17.7783305,14.1638062 17.2425356,14.0298575 Z M12,14 C11.4477153,14 11,14.4477153 11,15 L11,15 L11,19 C11,19.5522847 11.4477153,20 12,20 C12.5522847,20 13,19.5522847 13,19 L13,19 L13,15 C13,14.4477153 12.5522847,14 12,14 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={2}
          y={8}
          width={20}
          height={4}
          rx={1}
        />
        <path
          d='M9.70710678,9.70710678 C9.31658249,10.0976311 8.68341751,10.0976311 8.29289322,9.70710678 C7.90236893,9.31658249 7.90236893,8.68341751 8.29289322,8.29289322 L14.2928932,2.29289322 C14.6834175,1.90236893 15.3165825,1.90236893 15.7071068,2.29289322 C16.0976311,2.68341751 16.0976311,3.31658249 15.7071068,3.70710678 L9.70710678,9.70710678 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
