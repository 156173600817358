import { FC } from 'react';
import { Button, ButtonGroup, Segment, UserGroup } from '../../../components';
import { Loader, PageSkeleton } from '../../../layout';
import { useGroups } from '../../../hooks';

const GroupsPage: FC = () => {
  const { groups, loading, createGroup, openModal } = useGroups();
  return (
    <PageSkeleton
      title='User groups'
      header={
        <ButtonGroup>
          <Button
            label='Create new'
            suffix='plus'
            primary
            onClick={createGroup}
          />
        </ButtonGroup>
      }
    >
      <Loader {...{ loading }}>
        <Segment title='User groups'>
          {groups?.map((group: any) => (
            <UserGroup {...group} onModal={openModal} key={group.id} />
          ))}
        </Segment>
      </Loader>
    </PageSkeleton>
  );
};

export default GroupsPage;
