import classNames from 'classnames';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Input } from '../components';
import network from '../core/network';
import styles from '../styles/layout/authform.module.scss';

const LoginForm: FC = () => {
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation(['forgot-form', 'common']);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      setSuccess(true);
      network.user.forgotEmail(values.email);
    },
  });

  const _handleChange = (value: string, name?: string) => {
    formik.setFieldValue(name ?? '', value);
  };
  return (
    <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <img src='/images/logo/triyit-trademark.png' alt='' />
      <h1>{t('title')}</h1>
      {success ? (
        <>
          <h2>{t('success')}</h2>
          <div className={classNames(styles.row, styles.center)}>
            <Link to='/login'>{t('forgot-form:login')}</Link>
          </div>
        </>
      ) : (
        <>
          <Input
            className={styles.input}
            icon='user'
            onChange={_handleChange}
            name='email'
            value={formik.values.email}
            placeholder={t('common:email')}
          />
          <div className={styles.row}>
            <Link to='/login'>{t('common:login')}</Link>

            <Button onClick={formik.handleSubmit} primary>
              {t('common:submit')}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default LoginForm;
