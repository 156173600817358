import { FC, useMemo, useState } from 'react';
import { Table } from '.';
import Pagination from './Pagination';

interface iResultsProps {
  data: any[];
  colums?: { [key: string]: string };
  rowResolver?: (data: any) => any;
  selection?: string[];
  onSelectionChanges?: (val: string[]) => void;
  onClick?: (id: string) => void;
}

const ITEM_PER_PAGE = 50;
const Results: FC<iResultsProps> = ({
  data,
  rowResolver,
  selection,
  onSelectionChanges,
  colums,
  onClick,
}) => {
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<number | null>(null);

  const _handleSort = (column: string) => {
    if (sortKey === column && sortDirection === -1) {
      setSortKey(null);
      setSortDirection(0);
    } else if (sortKey === column) {
      setSortDirection(-1);
    } else {
      setSortKey(column);
      setSortDirection(1);
    }
  };

  const pages = useMemo(
    () => Math.ceil(data.length / ITEM_PER_PAGE),
    [data.length],
  );
  const ids = useMemo(() => data.map((_) => _._id), [data.length]);

  const pageContent = useMemo(() => {
    let val = [...data];
    if (sortKey)
      val = val.sort((a: any, b: any) => {
        if (sortKey === null) return true;
        if (sortDirection === -1) [a, b] = [b, a];
        return a?.[sortKey]?.localeCompare(b?.[sortKey]);
      });
    return val
      .map(rowResolver ?? ((e) => e))
      .slice(page * ITEM_PER_PAGE, (page + 1) * ITEM_PER_PAGE);
  }, [page, data.length, sortKey, sortDirection]);

  return (
    <>
      <Table
        selectable={!!(selection && onSelectionChanges)}
        {...{ selection, onSelectionChanges }}
        hoverable
        onSort={_handleSort}
        data={pageContent}
        ids={ids}
        colums={colums}
        onClick={onClick}
        sort={
          sortKey && sortDirection
            ? { key: sortKey, direction: sortDirection > 0 ? 1 : -1 }
            : undefined
        }
      />
      <Pagination page={page} pages={pages} onPageChange={setPage} />
    </>
  );
};

export default Results;
