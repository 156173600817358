import React from 'react';

export default function IconDesktop(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-desktop</title>
      <g
        id='icon-desktop'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15,19 C15.5522847,19 16,19.4477153 16,20 L16,20.5857864 L16.7071068,21.2928932 C17.3370716,21.9228581 16.8909049,23 16,23 L8,23 C7.10909515,23 6.66292836,21.9228581 7.29289322,21.2928932 L8,20.5857864 L8,20 C8,19.4477153 8.44771525,19 9,19 L15,19 Z M20,2 C21.1045695,2 22,2.8954305 22,4 L22,14 L20,16 L4,16 L2,14 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 Z M20,4 L4,4 L4,15 L20,15 L20,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M2,14 L22,14 L22,16 C22,17.1045695 21.1045695,18 20,18 L4,18 C2.8954305,18 2,17.1045695 2,16 L2,14 L2,14 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
