import React from 'react';

export default function IconSign(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-sign</title>
      <g
        id='icon-sign'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group' transform='translate(2.000000, 2.000000)'>
          <path
            d='M12,10 L17,10 L20,13 L17,16 L12,16 L12,20 L8,20 L8,16 L2,16 C1.44771525,16 1,15.5522847 1,15 L1,11 C1,10.4477153 1.44771525,10 2,10 L8,10 L8,9 L12,9 L12,10 Z'
            id='primary'
            fill='currentColor'
            opacity='0.4'
          />
          <path
            d='M8,2 C8,0.8954305 8.8954305,0 10,0 C11.1045695,0 12,0.8954305 12,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,7 C19,7.55228475 18.5522847,8 18,8 L3,8 L0,5 L3,2 L8,2 Z'
            id='secondary'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
}
