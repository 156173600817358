import React from 'react';

export default function IconUserGroup(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-user-group</title>
      <g
        id='icon-user-group'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M11,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,13 C2,11.3431458 3.34314575,10 5,10 L9,10 C10.6568542,10 12,11.3431458 12,13 C12,11.3431458 13.3431458,10 15,10 L19,10 C20.6568542,10 22,11.3431458 22,13 L22,16 C22,16.5522847 21.5522847,17 21,17 L13,17 C12.4477153,17 12,16.5522847 12,16 C12,16.5522847 11.5522847,17 11,17 Z M7,3 C8.65685425,3 10,4.34314575 10,6 C10,7.65685425 8.65685425,9 7,9 C5.34314575,9 4,7.65685425 4,6 C4,4.34314575 5.34314575,3 7,3 Z M17,3 C18.6568542,3 20,4.34314575 20,6 C20,7.65685425 18.6568542,9 17,9 C15.3431458,9 14,7.65685425 14,6 C14,4.34314575 15.3431458,3 17,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15,14 C16.6568542,14 18,15.3431458 18,17 L18,20 C18,20.5522847 17.5522847,21 17,21 L7,21 C6.44771525,21 6,20.5522847 6,20 L6,17 C6,15.3431458 7.34314575,14 9,14 L15,14 Z M12,7 C13.6568542,7 15,8.34314575 15,10 C15,11.6568542 13.6568542,13 12,13 C10.3431458,13 9,11.6568542 9,10 C9,8.34314575 10.3431458,7 12,7 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
