import React from 'react';

export default function IconBatteryHalf(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-battery-half</title>
      <g
        id='icon-battery-half'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M18,6 C19.1045695,6 20,6.8954305 20,8 L20,9 L21,9 C21.5522847,9 22,9.44771525 22,10 L22,14 C22,14.5522847 21.5522847,15 21,15 L19.999,15 L20,16 C20,17.1045695 19.1045695,18 18,18 L4,18 C2.8954305,18 2,17.1045695 2,16 L2,8 C2,6.8954305 2.8954305,6 4,6 L18,6 Z M18,8 L4,8 L4,16 L18,16 L18,8 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={6}
          y={10}
          width={6}
          height={4}
        />
      </g>
    </svg>
  );
}
