import classNames from 'classnames';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Icon } from '..';
import styles from '../../styles/components/tab-option.module.scss';

export interface iTabProps {
  label: string;
  href?: string;
  icon?: string;
  onClick?: () => void;
}

const Tab: FC<iTabProps> = ({ icon, label, onClick, href }) => {
  const history = useHistory();
  const location = useLocation();

  const isActive = href === location.pathname;

  const _handleClick = () => {
    onClick?.();
    if (href) history.push(href);
  };

  return (
    <button
      tabIndex={0}
      className={classNames(styles.wrapper, isActive && styles.active)}
      onClick={_handleClick}
    >
      {icon && <Icon icon={icon} />}
      <span className={classNames(!icon && styles.center)}>{label}</span>
    </button>
  );
};

export default Tab;
