import React from 'react';

export default function IconFastForward(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-fast-forward</title>
      <g
        id='icon-fast-forward'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M1,5 C1,4.22820917 1.83721439,3.74733806 2.50387103,4.1362211 L14.503871,11.1362211 C15.1653763,11.5220992 15.1653763,12.4779008 14.503871,12.8637789 L2.50387103,19.8637789 C1.83721439,20.2526619 1,19.7717908 1,19 L1,5 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
        <path
          d='M9,5 C9,4.22820917 9.83721439,3.74733806 10.503871,4.1362211 L22.503871,11.1362211 C23.1653763,11.5220992 23.1653763,12.4779008 22.503871,12.8637789 L10.503871,19.8637789 C9.83721439,20.2526619 9,19.7717908 9,19 L9,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
