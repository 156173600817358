import React from 'react';

export default function IconDeviceTablet(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-device-tablet</title>
      <g
        id='icon-device-tablet'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6,2 L18,2 C19.1045695,2 20,2.8954305 20,4 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12,20 C12.8284271,20 13.5,19.3284271 13.5,18.5 C13.5,17.6715729 12.8284271,17 12,17 C11.1715729,17 10.5,17.6715729 10.5,18.5 C10.5,19.3284271 11.1715729,20 12,20 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
