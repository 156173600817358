import React from 'react';

export default function IconViewHidden(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-view-hidden</title>
      <g
        id='icon-view-hidden'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15.0941474,19.3367881 C12.1679575,20.6099935 8.63580203,20.0500156 6.24264069,17.6568542 L1.29289322,12.7071068 C0.902368927,12.3165825 0.902368927,11.6834175 1.29289322,11.2928932 L4.17157288,8.41421356 L6.96235067,11.2049914 C6.92098477,11.4638992 6.89949494,11.72944 6.89949494,12 C6.89949494,14.7614237 9.13807119,17 11.8994949,17 C12.170055,17 12.4355958,16.9785102 12.6945036,16.9371443 L15.0941474,19.3367881 Z M8.84603314,4.60339245 C11.7439887,3.40843178 15.201553,3.98834955 17.5563492,6.34314575 L22.5060967,11.2928932 C22.8966209,11.6834175 22.8966209,12.3165825 22.5060967,12.7071068 L19.7279221,15.4852814 L16.8615652,12.6189245 C16.8866017,12.4161277 16.8994949,12.2095695 16.8994949,12 C16.8994949,9.23857625 14.6609187,7 11.8994949,7 C11.6899254,7 11.4833673,7.01289324 11.2805705,7.03792976 L8.84603314,4.60339245 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M3.29289322,4.70710678 L19.2928932,20.7071068 C19.6834175,21.0976311 20.3165825,21.0976311 20.7071068,20.7071068 C21.0976311,20.3165825 21.0976311,19.6834175 20.7071068,19.2928932 L4.70710678,3.29289322 C4.31658249,2.90236893 3.68341751,2.90236893 3.29289322,3.29289322 C2.90236893,3.68341751 2.90236893,4.31658249 3.29289322,4.70710678 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
