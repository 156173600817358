import { FC } from 'react';
import { Icon } from '../../components';
import styles from '../../styles/pages/survey-loading.module.scss';

const Loading: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Icon icon='loader' />
    </div>
  );
};

export default Loading;
