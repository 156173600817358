import { FC } from 'react';
import styles from '../../styles/pages/notifications.module.scss';

const WIP: FC = () => {
  return (
    <div className={styles.wrapper}>
      <h1>🛠️</h1>
      <h2>Coming Soon</h2>
      <p>We are working on it.</p>
    </div>
  );
};

export default WIP;
