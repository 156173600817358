import React from 'react';

export default function IconPuzzle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-puzzle</title>
      <g
        id='icon-puzzle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M18.0855129,14.5803911 C18.0600262,14.5756719 18.0473061,14.5652013 18.0515112,14.5470398 C17.4898573,14.8245076 16.8259941,15 15.9862358,15 C11.9770597,15 11.9770597,11 7.96788359,11 C7.17615707,11 6.5407796,11.1559912 6,11.4063638 L5.90020816,11.4359838 C5.96414364,11.3042154 6,11.156295 6,11 L6,8 C6,6.8954305 6.8954305,6 8,6 L11,6 C11.5522847,6 12,5.55228475 12,5 L12,4 C12,2.8954305 12.8954305,2 14,2 C15.1045695,2 16,2.8954305 16,4 L16,5 C16,5.55228475 16.4477153,6 17,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,11 C22,11.5522847 21.5522847,12 21,12 L20,12 C18.8954305,12 18,12.8954305 18,14 C18,14.2018431 18.0299002,14.3967027 18.0855129,14.5803911 L18.0855129,14.5803911 Z'
          id='Path'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M22,17 L22,20 C22,21.1045695 21.1045695,22 20,22 L17,22 L17,22 C16.4477153,22 16,21.5522847 16,21 L16,20 C16,18.8954305 15.1045695,18 14,18 C12.8954305,18 12,18.8954305 12,20 L12,21 C12,21.5522847 11.5522847,22 11,22 L8,22 C6.8954305,22 6,21.1045695 6,20 L6,17 L6,17 C6,16.4477153 5.55228475,16 5,16 L4,16 C2.8954305,16 2,15.1045695 2,14 C2,12.8954305 2.8954305,12 4,12 L5,12 C5.55228475,12 6,11.5522847 6,11 L6,10.4063638 C6.5407796,10.1559912 7.17615707,10 7.96788359,10 C11.9770597,10 11.9770597,14 15.9862358,14 C16.8259941,14 17.4898573,13.8245076 18.0515112,13.5470398 C18.0178122,13.6925826 18,13.8442112 18,14 C18,15.1045695 18.8954305,16 20,16 L21,16 C21.5522847,16 22,16.4477153 22,17 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
