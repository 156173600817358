import { FC, useCallback } from 'react';
import { Button, ButtonGroup } from '../../components';
import { tActions } from '../../providers/Modal';
import styles from '../../styles/layout/modal.module.scss';

interface iActionProps {
  actions?: tActions;
}

const ModalActions: FC<iActionProps> = ({ actions, children }) => {
  const renderAction = useCallback(
    (action) =>
      action ? (
        <Button
          primary={action.primary}
          link={action.link}
          label={action.label}
          onClick={action.onClick}
          key={action.label}
        />
      ) : null,
    [],
  );
  return (
    <footer className={styles.footer}>
      {children}
      {actions?.map((action, i) =>
        Array.isArray(action) ? (
          <ButtonGroup key={`button-group-${i}`}>
            {action.map(renderAction)}
          </ButtonGroup>
        ) : (
          renderAction(action)
        ),
      )}
    </footer>
  );
};

export default ModalActions;
