import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Input,
  Query,
  Results,
  Segment,
  Status,
} from '../../../components';
import { Loader, PageSkeleton } from '../../../layout';
import {
  useModal,
  useResponses,
  useSnapshots,
  useWhitelist,
} from '../../../hooks';
import { useParams } from 'react-router';
import { UserValidationModal } from '../../../modals';
import useFetch from 'use-http';

const WhitelistPage: FC = () => {
  const { presentModal } = useModal();
  const [search, setSearch] = useState<string>('');
  const params = useParams<{ whitelist_id: string }>();
  const sql = useFetch(
    `/campaign/whitelist/responses/${params.whitelist_id}/sql`,
  );
  const csv = useFetch(
    `/campaign/whitelist/responses/${params.whitelist_id}/csv`,
  );
  const selectionCsv = useFetch(
    `/campaign/whitelist/snapshot/${params.whitelist_id}?csv`,
  );
  const { loading, whitelist, attachToCampaign, edit } = useWhitelist(
    params.whitelist_id,
  );
  const { responses, loading: responsesLoading } = useResponses(
    params.whitelist_id,
  );
  const { users, loading: snapshotsLoading } = useSnapshots(
    params.whitelist_id,
  );
  const [selection, setSelection] = useState<string[]>([]);

  useEffect(() => {
    setSelection(
      users
        ?.map((user: any) =>
          !user.duplicate && user?.status === 'VALID' ? user.id : undefined,
        )
        .filter((_: string | undefined) => !!_),
    );
  }, [users.length]);
  const formattedResponses = useMemo(
    () =>
      responses?.map((response) => ({
        'User email address': response.email,
        ...response.answers,
      })),
    [JSON.stringify(responses)],
  );
  const [query, setQuery] = useState<string>('');

  const _handleRow = useCallback(
    (user) => ({
      _id: user.id,
      email: user.email,
      status: <Status>{user.status}</Status>,
      mobile: user.mobile,
      birthdate: user.birthdate,
      postcode: <Status>{user.postcode}</Status>,
    }),
    [],
  );

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    const mappedUsers = users?.map((user: any) => ({
      ...user,
      _id: user.id,
      status: user.duplicate ? 'DUPLICATE' : user.status,
    }));

    const _search = search.toLowerCase().trim();

    if (!_search) return mappedUsers;
    return mappedUsers.filter((user: { [key: string]: string }) => {
      if (_search.toLowerCase().includes('duplicate') && user.duplicate) {
        return true;
      }
      const searchEntries = _search.split(' ');
      const _searchEntries = _search.split(':');
      if (_searchEntries[0] === 'status' && _searchEntries[1]) {
        return (
          user.status.toLowerCase() === _searchEntries[1].toLowerCase().trim()
        );
      } else {
        return searchEntries.every((entry) => {
          const val = Object.entries(user).some(([key, value]) => {
            if (String(value)?.toLowerCase()?.trim()?.includes(entry))
              return true;
            return false;
          });

          if (val) return true;

          return false;
        });
      }
    });
  }, [search, JSON.stringify(users)]);

  const _handleModal = (id: string) => {
    const user = users.find((user: any) => user.id === id);
    presentModal({
      content: (
        <UserValidationModal
          {...user}
          status={user.duplicate ? 'DUPLICATE' : user.status}
        />
      ),
      title: user.email,
      subtitle: 'View validation',
    });
  };

  const _handleDownload = async (
    res: () => Promise<any>,
    filename: string,
    type = 'text/txt',
  ) => {
    const data = await res();
    var file = new Blob([data], { type: type });
    if ('msSaveOrOpenBlob' in window.navigator) {
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);

      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };
  return (
    <PageSkeleton
      nested
      title={whitelist?.name || 'Loading...'}
      pageTitle={`${whitelist?.name || 'Loading'} whitelist`}
      header={
        <ButtonGroup>
          <Button label='Attach to campaign' onClick={attachToCampaign} />
          <Button label='Edit' onClick={() => edit(params.whitelist_id)} />
          <Button label='Save' primary onClick={() => {}} />
        </ButtonGroup>
      }
    >
      <Loader {...{ loading: loading || responsesLoading || snapshotsLoading }}>
        <Segment
          collapsible
          collapsed
          title={`Responses (${users.length})`}
          actions={[
            {
              icon: 'database',
              onClick: () =>
                _handleDownload(sql.get, 'responses.sql', 'text/sql'),
            },
            {
              icon: 'download-cloud',
              onClick: () =>
                _handleDownload(csv.get, 'responses.csv', 'text/csv'),
            },
          ]}
        >
          <Results data={formattedResponses || []} />
        </Segment>
        <Segment collapsible collapsed title='Query users'>
          <Query setCode={setQuery} name='query' code={query} />
        </Segment>
        <Segment
          collapsible
          collapsed
          title={`Results (${selection.length})`}
          actions={[
            { icon: 'upload-cloud', onClick: () => {} },
            {
              icon: 'download-cloud',
              onClick: () =>
                _handleDownload(
                  () => selectionCsv.put({ selection }),
                  'responses.csv',
                  'text/csv',
                ),
            },
          ]}
        >
          <Input value={search} onChange={setSearch} placeholder='Search' />
          <Results
            onClick={_handleModal}
            colums={{
              email: 'Email',
              status: 'Status',
              mobile: 'Mobile number',
              birthdate: 'Birthdate',
              postcode: 'Postcode',
            }}
            {...{ selection, onSelectionChanges: setSelection }}
            rowResolver={_handleRow}
            data={filteredUsers}
          />
        </Segment>
      </Loader>
    </PageSkeleton>
  );
};

export default WhitelistPage;
