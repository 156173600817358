import React from 'react';

export default function IconLayers(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-layers</title>
      <g
        id='icon-layers'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M2.59386153,6.08618845 L11.5938615,2.08618845 C11.8524269,1.97127052 12.1475731,1.97127052 12.4061385,2.08618845 L21.4061385,6.08618845 C22.1979538,6.4381064 22.1979538,7.5618936 21.4061385,7.91381155 L12.4061385,11.9138115 C12.1475731,12.0287295 11.8524269,12.0287295 11.5938615,11.9138115 L2.59386153,7.91381155 C1.80204616,7.5618936 1.80204616,6.4381064 2.59386153,6.08618845 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M20.0872145,15.5 L21.4061385,16.0861885 C22.1979538,16.4381064 22.1979538,17.5618936 21.4061385,17.9138115 L12.4061385,21.9138115 C12.1475731,22.0287295 11.8524269,22.0287295 11.5938615,21.9138115 L2.59386153,17.9138115 C1.80204616,17.5618936 1.80204616,16.4381064 2.59386153,16.0861885 L3.91278555,15.5 L11.5938615,18.9138115 C11.8524269,19.0287295 12.1475731,19.0287295 12.4061385,18.9138115 L20.0872145,15.5 Z M20.0872145,10.5 L21.4061385,11.0861885 C22.1979538,11.4381064 22.1979538,12.5618936 21.4061385,12.9138115 L12.4061385,16.9138115 C12.1475731,17.0287295 11.8524269,17.0287295 11.5938615,16.9138115 L2.59386153,12.9138115 C1.80204616,12.5618936 1.80204616,11.4381064 2.59386153,11.0861885 L3.91278555,10.5 L11.5938615,13.9138115 C11.8524269,14.0287295 12.1475731,14.0287295 12.4061385,13.9138115 L20.0872145,10.5 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
