import classNames from 'classnames';
import { FC } from 'react';
import { Icon } from '.';
import styles from '../styles/components/checkbox-input.module.scss';

export type eCheckboxValue = 'FILLED' | 'PARTIAL' | 'EMPTY';
interface iCheckboxProps {
  value: eCheckboxValue;
  onChange: (val: eCheckboxValue) => void;
}

const Checkbox: FC<iCheckboxProps> = ({ value = 'EMPTY', onChange }) => {
  const _handleChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(['PARTIAL', 'EMPTY'].includes(value) ? 'FILLED' : 'EMPTY');
  };
  return (
    <div
      onClick={_handleChange}
      className={classNames(styles.wrapper, styles[value.toLowerCase()])}
    >
      <div className={styles[value.toLowerCase()]}>
        {value === 'FILLED' && <Icon icon='check' />}
        {value === 'PARTIAL' && <Icon icon='minus' />}
      </div>
    </div>
  );
};

export default Checkbox;
