import React from 'react';

export default function IconThumbsUp(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-thumbs-up</title>
      <g
        id='icon-thumbs-up'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M13.0001071,4.7949922 L15.9191451,11.6060807 C15.9724923,11.7305575 16,11.8645732 16,12 L16,20.0024554 C16,21.1059154 15.1057268,22 14.0024554,22 L7.99739171,22 C6.49511909,22 4.88525792,20.9375262 4.29264029,19.5547517 L2.08085497,14.3939193 C2.02750775,14.2694425 2,14.1354268 2,14 L2,12 C2,10.3378003 3.33961852,9 4.9973917,9 L9,9 L9,4.99810131 C9,3.33619935 10.3418196,2 12.0010436,2 C12.5535321,2 13.0013317,2.44803331 13.0010434,3.00052176 L13.0001071,4.7949922 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={18}
          y={11}
          width={4}
          height={11}
          rx={1}
        />
      </g>
    </svg>
  );
}
