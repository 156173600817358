import { FC } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Activate from './Activate';
import Confirm from './Confirm';
import Dashboard from './Dashboard';
import Forgot from './Forgot';
import Login from './Login';
import NotFound from './NotFound';
import Reset from './Reset';
import Register from './Register';
import Uikit from './Uikit';
import { useUser } from '../hooks';
import Survey from './Survey';

const Navigation: FC = () => {
  const { user } = useUser();
  return (
    <Router>
      {!user?.id ? (
        <Switch>
          <Route path='/survey/:survey_id' component={Survey} />
          <Route path='/login' component={Login} />
          <Route path='/forgot' component={Forgot} />
          <Route path='/reset' component={Reset} />
          <Route path='/register' component={Register} />
          <Route path='/activate' component={Activate} />
          <Route path='/confirm' component={Confirm} />

          <Route path='/uikit' component={Uikit} />

          <Redirect from='*' to='/login' />
        </Switch>
      ) : (
        <Switch>
          <Route path='/survey/:survey_id' component={Survey} />
          <Route path='/uikit' component={Uikit} />
          <Route
            path={[
              '/',
              '/points',
              '/discover',
              '/how-it-works',
              '/import-profile',
              '/admin/',
              '/admin/*',
            ]}
            exact
            component={Dashboard}
          />
          <Route path='*' component={NotFound} />
        </Switch>
      )}
    </Router>
  );
};

export default Navigation;
