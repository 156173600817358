import React from 'react';

export default function IconMap(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-map</title>
      <g
        id='icon-map'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M9,21.0012902 C8.84677507,21.0012902 8.69355015,20.9660993 8.5527864,20.8957174 L2.5527864,17.8957174 C2.21400238,17.7263254 2,17.3800623 2,17.0012902 L2,4.00129021 C2,3.25790819 2.7823125,2.77441247 3.4472136,3.10686302 L9,5.88325622 L9,21.0012902 L9,21.0012902 Z M15,3.00129021 C15.1532249,3.00129021 15.3064499,3.03648115 15.4472136,3.10686302 L21.4472136,6.10686302 C21.7859976,6.27625503 22,6.62251815 22,7.00129021 L22,20.0012902 C22,20.7446722 21.2176875,21.2281679 20.5527864,20.8957174 L15,18.1193242 L15,3.00129021 L15,3.00129021 Z'
          id='Shape'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M9,21.0012902 L9,5.88325622 L14.5527864,3.10686302 C14.6935501,3.03648115 14.8467751,3.00129021 15,3.00129021 L15,18.1193242 L9.4472136,20.8957174 C9.30644985,20.9660993 9.15322493,21.0012902 9,21.0012902 L9,21.0012902 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
