import React from 'react';

export default function IconSecurityCheck(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-security-check</title>
      <g
        id='icon-security-check'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M4,4 L12,2 L20,4 L20,13.0557281 C20,16.0859046 18.287981,18.8560095 15.5777088,20.2111456 L12,22 L12,22 L8.42229124,20.2111456 C5.71201901,18.8560095 4,16.0859046 4,13.0557281 L4,4 L4,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M10.5,12.5857864 L14.7928932,8.29289322 C15.1834175,7.90236893 15.8165825,7.90236893 16.2071068,8.29289322 C16.5976311,8.68341751 16.5976311,9.31658249 16.2071068,9.70710678 L11.2071068,14.7071068 C10.8165825,15.0976311 10.1834175,15.0976311 9.79289322,14.7071068 L7.79289322,12.7071068 C7.40236893,12.3165825 7.40236893,11.6834175 7.79289322,11.2928932 C8.18341751,10.9023689 8.81658249,10.9023689 9.20710678,11.2928932 L10.5,12.5857864 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
