import React from 'react';

export default function IconBookOpen(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-book-open</title>
      <g
        id='icon-book-open'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,21 C11.4881554,21 10.9763107,20.8047379 10.5857864,20.4142136 L9.75735931,19.5857864 C9.38228656,19.2107137 8.87357873,19 8.34314575,19 L4,19 C3.44771525,19 3,18.5522847 3,18 L3,4 C3,3.44771525 3.44771525,3 4,3 L8,3 C9.63559896,3 11.0877712,3.7853431 12,4.99951255 L12,21 Z'
          id='Path'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M12,21 L12,4.99951255 C12.9122288,3.7853431 14.364401,3 16,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,18 C21,18.5522847 20.5522847,19 20,19 L15.6568542,19 C15.1264213,19 14.6177134,19.2107137 14.2426407,19.5857864 L13.4142136,20.4142136 C13.0236893,20.8047379 12.5118446,21 12,21 L12,21 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
