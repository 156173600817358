import {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router';
import useFetch, { CachePolicies } from 'use-http';
import { useUser } from '.';
import { SurveyContext } from '../pages/Survey';
import { iQuestion } from '../types/question';
import network from '../core/network';

interface iPagerouteParams {
  survey_id: string;
  page_index?: string;
}

export const useTodos = () => {
  const { data, error, loading } = useFetch(
    '/survey/todos',
    { cachePolicy: CachePolicies.NO_CACHE },
    [],
  );

  return { loading, todos: data?.surveys, error };
};

/**
 * Use the global survey state
 * Returns the current value & the loaded survey
 * @returns
 */
export const useSurveyContext = () => {
  const history = useHistory();
  const context = useContext(SurveyContext);

  /**
   * Redirect to the first page
   */
  const _handleStart = useCallback(() => {
    history.push(`/survey/${context.survey_id}/p/1`);
  }, [context.survey_id]);

  /**
   * Redirect either to the dashboard or to the next survey
   */
  const _handleFinish = useCallback(() => {
    try {
      const url = new URL(context.survey?.finish_target || '/');
      history.push(url.pathname);
    } catch (error) {
      history.push('/');
    }
  }, [context.survey?.finish_target]);

  return { ...context, startSurvey: _handleStart, finishSurvey: _handleFinish };
};

/**
 * Loads the content of the survey
 * @param id The id of the survey
 * @returns { survey: iSurvey, error: Error | undefined, loading: boolean }
 */
export const useSurvey = (id: string) => {
  const options = {};
  const {
    data = null,
    loading,
    error,
  } = useFetch(`/survey/get/${id}?full`, options, [id]);

  return { loading, error, survey: data?.survey ?? null };
};

/**
 * Load and cache all pages for the survey
 * @param survey id of the survey
 * @returns { pages: iPage[], loading: boolean }
 */
export const usePages = (survey?: string) => {
  const { data = {}, loading } = useFetch(`/survey/page/${survey}?full`, [
    survey,
  ]);

  return { pages: data?.pages ?? [], loading };
};

/**
 * Returns the page with the given index from the page array,
 * loaded either from the network or from the cache
 * @param survey the id of the survey
 * @param index the index of the page, starting from zero
 * @returns the page or null
 */
export const usePage = (survey: string | undefined, index: number) => {
  const { pages } = usePages(survey);

  const page = useMemo(() => {
    const page = pages[index];

    if (page && index === pages.length - 1) page.isLast = true;
    else if (page && index === 0) page.isFirst = true;

    return page;
  }, [index, JSON.stringify(pages)]);

  return page;
};

/**
 * Use survey value
 * Returns the global value of the survey, keeps track of the global answers
 * @param survey the id of the survey
 * @returns { value: iValue, onChange: handle handler }
 */
export const useValue = (survey?: string) => {
  const fetchTimeout = useRef<any>({});
  const { user } = useUser();
  const [value, setValue] = useState<{ [key: string]: string | string[] }>({});

  /**
   * Load answers for the user
   */
  const _handleLoad = useCallback(async () => {
    if (!survey) return;

    const res = await network.survey.getAnswers(survey, user?.token ?? '');

    setValue(res.answerMap);
  }, [user?.token, survey]);

  /**
   * Save question to DB
   * Debounced, only saved after 500ms
   */
  const _handleSave = useCallback(
    (question: string, val: string | string[]) => {
      if (survey) {
        clearInterval(fetchTimeout.current[question]);
        fetchTimeout.current[question] = setTimeout(() => {
          network.survey.saveAnswer(survey, question, user?.token ?? '', val);
        }, 500);
      }
    },
    [survey, user?.token],
  );

  useEffect(() => {
    _handleLoad();
  }, [survey]);

  const _handleChange = (question: string, val: string | string[]) => {
    _handleSave(question, val);
    setValue({ ...value, [question]: val });
  };

  return { value, onChange: _handleChange };
};

/**
 * Use pagination for the currently displayed page
 * Returns page handlers, the current index of the current page and the questions
 * @param survey
 * @returns
 */
export const usePagination = (survey?: string) => {
  const history = useHistory();
  const { page_index, survey_id } = useParams<iPagerouteParams>();
  const page = usePage(survey, parseInt(page_index ?? '1') - 1);

  /**
   * Turns to the next page in the given direction
   */
  const _handlePageChangeInDirection = useCallback(
    (dir: number) => {
      const currentIndex = parseInt(page_index ?? '1');
      const nextIndex = Math.max(1, currentIndex + dir);
      history.push(`/survey/${survey_id}/p/${nextIndex}`);
    },
    [page_index, survey_id],
  );

  /**
   * Switches to the next page in the row
   */
  const _handleNext = useCallback(() => {
    if (page?.isLast) _handleFinish();
    else _handlePageChangeInDirection(1);
  }, [page?.isLast, survey_id, page_index]);

  /**
   * Switches to the previous page
   */
  const _handleBack = useCallback(
    () => _handlePageChangeInDirection(-1),
    [survey_id, page_index],
  );

  /**
   * Navigates to te "finish" screen of the survey
   * @returns
   */
  const _handleFinish = useCallback(
    () => history.push(`/survey/${survey_id}/finish`),
    [survey_id],
  );

  return {
    index: page?.index,
    isFirst: page?.isFirst,
    isLast: page?.isLast,
    onNext: _handleNext,
    onBack: _handleBack,
    questions: (page?.questions ?? []) as iQuestion[],
  };
};
