import React from 'react';

export default function IconInboxDownload(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-inbox-download</title>
      <g
        id='icon-inbox-download'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M8,5 L5,5 L5,5 L5,15 L7,15 C8.1045695,15 9,15.8954305 9,17 C9,18.1045695 9.8954305,19 11,19 L13,19 C14.1045695,19 15,18.1045695 15,17 C15,15.8954305 15.8954305,15 17,15 L19,15 L19,5 L16,5 C15.4477153,5 15,4.55228475 15,4 C15,3.44771525 15.4477153,3 16,3 L19,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L8,3 C8.55228475,3 9,3.44771525 9,4 C9,4.55228475 8.55228475,5 8,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,10.5857864 L11,4 C11,3.44771525 11.4477153,3 12,3 C12.5522847,3 13,3.44771525 13,4 L13,10.5857864 L14.2928932,9.29289322 C14.6834175,8.90236893 15.3165825,8.90236893 15.7071068,9.29289322 C16.0976311,9.68341751 16.0976311,10.3165825 15.7071068,10.7071068 L12.7071068,13.7071068 C12.3165825,14.0976311 11.6834175,14.0976311 11.2928932,13.7071068 L8.29289322,10.7071068 C7.90236893,10.3165825 7.90236893,9.68341751 8.29289322,9.29289322 C8.68341751,8.90236893 9.31658249,8.90236893 9.70710678,9.29289322 L11,10.5857864 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
