import { FC } from 'react';
import { Icon } from '.';
import styles from '../styles/components/pagination.module.scss';
// @ts-ignore
import ReactPaginate from 'react-paginate';

interface iPaginationProps {
  page: number;
  pages: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<iPaginationProps> = ({ page, pages, onPageChange }) => {
  const _handlePageClick = (event: { selected: number }) => {
    onPageChange(event.selected);
  };

  return (
    <div className={styles.wrapper}>
      <ReactPaginate
        page={page}
        pages={pages}
        breakLabel='...'
        nextLabel={<Icon icon='chevron-right' />}
        previousLabel={<Icon icon='chevron-left' />}
        onPageChange={_handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pages}
        forcePage={page}
        renderOnZeroPageCount={null}
        activeClassName={styles.selected}
        disabledClassName={styles.disabled}
      />
    </div>
  );
};

export default Pagination;
