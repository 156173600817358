import React from 'react';

export default function IconBookClosed(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-book-closed</title>
      <g
        id='icon-book-closed'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M5,3 L7,3 L7,3 L12,5 L17,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M7,3 L17,3 L17,14 C17,14.743382 16.2176875,15.2268777 15.5527864,14.8944272 L12,13.118034 L8.4472136,14.8944272 C7.7823125,15.2268777 7,14.743382 7,14 L7,3 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
