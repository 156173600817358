import { handleFetch } from '.';
import progress from '../progress';

const query = async (query: string, token: string) => {
  progress.start();
  const { data } = await handleFetch(
    `/database/query`,
    {
      method: 'POST',
      body: { query, params: [] },
    },
    token,
  );
  progress.done();
  return data;
};

const exported = { query };
export default exported;
