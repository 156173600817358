import React from 'react';

export default function IconDocument(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-document</title>
      <g
        id='icon-document'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6,2 L12,2 L12,8 C12,9.1045695 12.8954305,10 14,10 L20,10 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <polygon id='secondary' fill='currentColor' points='14 2 20 8 14 8' />
      </g>
    </svg>
  );
}
