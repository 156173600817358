import React from 'react';

export default function IconTrendingDown(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-trending-down</title>
      <g
        id='icon-trending-down'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M3.70710678,11.7071068 C3.31658249,12.0976311 2.68341751,12.0976311 2.29289322,11.7071068 C1.90236893,11.3165825 1.90236893,10.6834175 2.29289322,10.2928932 L8.29289322,4.29289322 C8.68341751,3.90236893 9.31658249,3.90236893 9.70710678,4.29289322 L13,7.58578644 L17.2928932,3.29289322 C17.6834175,2.90236893 18.3165825,2.90236893 18.7071068,3.29289322 C19.0976311,3.68341751 19.0976311,4.31658249 18.7071068,4.70710678 L13.7071068,9.70710678 C13.3165825,10.0976311 12.6834175,10.0976311 12.2928932,9.70710678 L9,6.41421356 L3.70710678,11.7071068 Z'
          id='secondary'
          fill='currentColor'
          transform='translate(10.500000, 7.500000) scale(1, -1) translate(-10.500000, -7.500000) '
        />
        <path
          d='M16.5857864,17 L13,13.4142136 L9.70710678,16.7071068 C9.31658249,17.0976311 8.68341751,17.0976311 8.29289322,16.7071068 L2.29289322,10.7071068 C1.90236893,10.3165825 1.90236893,9.68341751 2.29289322,9.29289322 C2.68341751,8.90236893 3.31658249,8.90236893 3.70710678,9.29289322 L9,14.5857864 L12.2928932,11.2928932 C12.6834175,10.9023689 13.3165825,10.9023689 13.7071068,11.2928932 L18,15.5857864 L20.2928932,13.2928932 C20.9228581,12.6629284 22,13.1090951 22,14 L22,20 C22,20.5522847 21.5522847,21 21,21 L15,21 C14.1090951,21 13.6629284,19.9228581 14.2928932,19.2928932 L16.5857864,17 L16.5857864,17 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
