import { FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Icon, Title } from '../components';
import styles from '../styles/layout/page.module.scss';

interface iPageProps {
  title: string;
  pageTitle?: string;
  header?: any;
  nested?: boolean;
}

const Page: FC<iPageProps> = ({
  title,
  children,
  header,
  nested,
  pageTitle,
}) => {
  const history = useHistory();
  const _handlePop = useCallback(() => history.goBack(), []);
  return (
    <>
      <Title title={pageTitle || title} />
      <div className={styles.wrapper}>
        <header>
          <h1>
            {nested && (
              <button onClick={_handlePop}>
                <Icon icon='chevron-left' />
              </button>
            )}
            {title}
          </h1>
          <div className={styles.content}>{header}</div>
        </header>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Page;
