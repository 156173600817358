import React from 'react';

export default function IconPause(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-pause</title>
      <g
        id='icon-pause'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M10,8 C10.5522847,8 11,8.44771525 11,9 L11,15 C11,15.5522847 10.5522847,16 10,16 L9,16 C8.44771525,16 8,15.5522847 8,15 L8,9 C8,8.44771525 8.44771525,8 9,8 L10,8 Z M15,8 C15.5522847,8 16,8.44771525 16,9 L16,15 C16,15.5522847 15.5522847,16 15,16 L14,16 C13.4477153,16 13,15.5522847 13,15 L13,9 C13,8.44771525 13.4477153,8 14,8 L15,8 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
