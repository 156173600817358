import React from 'react';

export default function IconImportant(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-important</title>
      <g
        id='icon-important'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12,15 C12.8284271,15 13.5,15.6715729 13.5,16.5 C13.5,17.3284271 12.8284271,18 12,18 C11.1715729,18 10.5,17.3284271 10.5,16.5 C10.5,15.6715729 11.1715729,15 12,15 Z M12.4998701,6 C13.0914536,6 13.5537075,6.5108179 13.4948427,7.09950372 L12.9948752,12.0995037 C12.8748168,13.3001654 11.1249884,13.3001654 11.00493,12.0995037 L10.5049625,7.09950372 C10.4460977,6.5108179 10.9083516,6 11.4999351,6 L12.4998701,6 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
