import React from 'react';

export default function IconKey(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-key</title>
      <g
        id='icon-key'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15,2 C18.8659932,2 22,5.13400675 22,9 C22,12.8659932 18.8659932,16 15,16 C14.3878122,16 13.7939792,15.9214136 13.2280388,15.7737787 L11,18 L9,18 L9,20 L7.00012112,20 L7.00012112,22.0311289 L2,22.0311289 L2,17 L8.22674416,10.7739644 C8.07877192,10.2074222 8,9.61291018 8,9 C8,5.13400675 11.1340068,2 15,2 Z M17,5 C15.8954305,5 15,5.8954305 15,7 C15,8.1045695 15.8954305,9 17,9 C18.1045695,9 19,8.1045695 19,7 C19,5.8954305 18.1045695,5 17,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M6.20710678,18.7071068 C5.81658249,19.0976311 5.18341751,19.0976311 4.79289322,18.7071068 C4.40236893,18.3165825 4.40236893,17.6834175 4.79289322,17.2928932 L8.79289322,13.2928932 C9.18341751,12.9023689 9.81658249,12.9023689 10.2071068,13.2928932 C10.5976311,13.6834175 10.5976311,14.3165825 10.2071068,14.7071068 L6.20710678,18.7071068 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
