import { useFormik } from 'formik';
import { FC, useCallback } from 'react';
import { Button, ButtonGroup, Input, Segment } from '../components';
import { useModal } from '../hooks';
import Actions from '../layout/Modal/Actions';
import ModalBody from '../layout/Modal/Body';
import { iSurveyInput } from '../types';

interface iSurveyProps {
  title?: string;
  subtitle?: string;
  id?: string;
  typeform_id?: string;
  short_id?: string;
  onUpdate?: (id: string, body: iSurveyInput) => void;
  onDelete?: (id: string) => void;
  onCreate?: (body: iSurveyInput) => void;
}

const Survey: FC<iSurveyProps> = ({
  title,
  subtitle,
  id,
  typeform_id,
  onUpdate,
  onDelete,
  onCreate,
  ...rest
}) => {
  const { dismissModal } = useModal();

  const formik = useFormik({
    initialValues: {
      title: title,
      subtitle: subtitle,
      typeform_id: typeform_id,
    },
    onSubmit: (values) => {
      if (id) {
        onUpdate?.(id, {
          ...values,
        });
      } else onCreate?.(values);
      dismissModal();
    },
  });

  /**
   * Update regular text fields
   */
  const _handleChange = useCallback((val: string, name?: string) => {
    if (name === 'typeform_id') {
      const id = val.split('/').pop();
      formik.setFieldValue(name ?? '', id);
    } else formik.setFieldValue(name ?? '', val);
  }, []);

  /**
   * Asks for permission and then deletes the user group
   */
  const _handleDelete = useCallback(() => {
    if (!id) return;
    if (!window.confirm('Are you sure you want to delete this survey?')) return;
    onDelete?.(id);
    dismissModal();
  }, []);

  const _handlePreview = useCallback(() => {
    if (formik.values.typeform_id) {
      window
        ?.open?.(
          `https://qfx5ygg0x8x.typeform.com/to/${formik.values.typeform_id}`,
          '_blank',
        )
        ?.focus();
    } else {
      window
        ?.open?.(`https://app.triyit.dev/survey/${rest.short_id}`, '_blank')
        ?.focus();
    }
  }, []);

  return (
    <>
      <ModalBody>
        <Segment title='Name of survey'>
          <Input
            onChange={_handleChange}
            name='title'
            value={formik.values.title}
          />
        </Segment>
        <Segment title='Subtitle'>
          <Input
            onChange={_handleChange}
            name='subtitle'
            value={formik.values.subtitle}
          />
        </Segment>
        <Segment title='Typeform url'>
          <Input
            onChange={_handleChange}
            name='typeform_id'
            value={formik.values.typeform_id}
            placeholder='wl0nk7'
          />
        </Segment>
      </ModalBody>
      <Actions>
        <Button onClick={dismissModal}>Cancel</Button>
        <ButtonGroup>
          {id && (
            <>
              <Button onClick={_handleDelete}>Delete</Button>
              <Button onClick={_handlePreview}>Preview</Button>
            </>
          )}
          <Button primary onClick={formik.handleSubmit}>
            {id ? 'Update' : 'Create'}
          </Button>
        </ButtonGroup>
      </Actions>
    </>
  );
};

export default Survey;
