import { handleFetch } from '.';
import progress from '../progress';

const login = async (email: string, password: string) => {
  progress.start();
  const { data } = await handleFetch('/auth/login', {
    method: 'POST',
    body: { email, password },
  });
  progress.done();
  return data;
};

const register = async (
  email: string,
  password: string,
  firstname: string,
  lastname: string,
) => {
  progress.start();
  const { data } = await handleFetch('/auth/register', {
    method: 'POST',
    body: { email, password, firstname, lastname },
  });
  progress.done();
  return data;
};

const verifyEmail = async (user: string, token: string) => {
  progress.start();
  const { data } = await handleFetch('/auth/activate', {
    method: 'POST',
    body: { user, token },
  });
  progress.done();
  return data;
};

const forgotEmail = async (email: string) => {
  progress.start();
  const { data } = await handleFetch('/auth/forgot', {
    method: 'POST',
    body: { email },
  });
  progress.done();
  return data;
};
const resetPassword = async (token: string, password: string) => {
  progress.start();
  const { data } = await handleFetch('/auth/reset', {
    method: 'POST',
    body: { token, password },
  });
  progress.done();
  return data;
};
const getProfile = async (token: string) => {
  const { data } = await handleFetch('/user/me', undefined, token);
  return data?.user ?? null;
};

const exported = {
  login,
  register,
  verifyEmail,
  forgotEmail,
  resetPassword,
  getProfile,
};
export default exported;
