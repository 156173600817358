import React from 'react';

export default function IconChat(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-chat</title>
      <g
        id='icon-chat'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M2,15 L2,5 C2,3.8954305 2.8954305,3 4,3 L20,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,20 C22,20.2559223 21.9023689,20.5118446 21.7071068,20.7071068 C21.3165825,21.0976311 20.6834175,21.0976311 20.2928932,20.7071068 L16.5857864,17 L4,17 C2.8954305,17 2,16.1045695 2,15 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M14,11 C14.5522847,11 15,11.4477153 15,12 C15,12.5522847 14.5522847,13 14,13 L6,13 C5.44771525,13 5,12.5522847 5,12 C5,11.4477153 5.44771525,11 6,11 L14,11 Z M18,7 C18.5522847,7 19,7.44771525 19,8 C19,8.55228475 18.5522847,9 18,9 L6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 L18,7 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
