import React from 'react';

export default function IconIdentification(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-identification</title>
      <g
        id='icon-identification'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20,4 C21.1045695,4 22,4.8954305 22,6 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 Z M19,15 L16,15 C15.4477153,15 15,15.4477153 15,16 C15,16.5522847 15.4477153,17 16,17 L16,17 L19,17 C19.5522847,17 20,16.5522847 20,16 C20,15.4477153 19.5522847,15 19,15 L19,15 Z M19,11 L15,11 C14.4477153,11 14,11.4477153 14,12 C14,12.5522847 14.4477153,13 15,13 L15,13 L19,13 C19.5522847,13 20,12.5522847 20,12 C20,11.4477153 19.5522847,11 19,11 L19,11 Z M19,7 L17,7 C16.4477153,7 16,7.44771525 16,8 C16,8.55228475 16.4477153,9 17,9 L17,9 L19,9 C19.5522847,9 20,8.55228475 20,8 C20,7.44771525 19.5522847,7 19,7 L19,7 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M10,14 C11.1045695,14 12,14.8954305 12,16 L12,17 C12,17.5522847 11.5522847,18 11,18 L5,18 C4.44771525,18 4,17.5522847 4,17 L4,16 C4,14.8954305 4.8954305,14 6,14 L10,14 Z M8,6 C9.65685425,6 11,7.34314575 11,9 C11,10.6568542 9.65685425,12 8,12 C6.34314575,12 5,10.6568542 5,9 C5,7.34314575 6.34314575,6 8,6 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
