import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { Button, ButtonGroup, Segment, Select } from '../components';
import { useCampaigns, useModal } from '../hooks';
import Actions from '../layout/Modal/Actions';
import ModalBody from '../layout/Modal/Body';

interface iAttachToCampaignProps {
  whitelist_id: string;
  campaign_id?: string;
}

const AttachToCampaign: FC<iAttachToCampaignProps> = ({
  whitelist_id,
  campaign_id,
}) => {
  const { campaigns } = useCampaigns();
  const { dismissModal } = useModal();

  const formik = useFormik({
    initialValues: {
      campaign_id,
    },

    onSubmit: (values) => {
      // todo: add network layer
      console.log(whitelist_id);
      dismissModal();
    },
  });

  /**
   * Update regular text fields
   */
  const _handleChange = useCallback(
    (val: string, name?: string) => {
      formik.setFieldValue(name ?? '', val);
    },
    [JSON.stringify(campaigns)],
  );

  const campaignOptions = useMemo(
    () => [
      { label: 'Select a campaign', value: null, disabled: true },
      ...campaigns.map((campaign) => ({
        label: campaign.name ?? '',
        value: campaign.id ?? '',
      })),
    ],
    [JSON.stringify(campaigns)],
  );

  return (
    <>
      <ModalBody>
        <Segment title='Select campaign to attach to'>
          <Select
            options={campaignOptions}
            onChange={_handleChange}
            name='campaign_id'
            value={formik.values.campaign_id ?? null}
          />
        </Segment>
      </ModalBody>
      <Actions>
        <ButtonGroup>
          <Button onClick={dismissModal}>Cancel</Button>
        </ButtonGroup>
        <Button primary onClick={formik.handleSubmit}>
          Attach
        </Button>
      </Actions>
    </>
  );
};

export default AttachToCampaign;
