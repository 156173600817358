import React from 'react';

export default function IconViewVisible(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-view-visible</title>
      <g
        id='icon-view-visible'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M17.5563492,6.34314575 L22.5060967,11.2928932 C22.8966209,11.6834175 22.8966209,12.3165825 22.5060967,12.7071068 L17.5563492,17.6568542 C14.4321549,20.7810486 9.36683502,20.7810486 6.24264069,17.6568542 L1.29289322,12.7071068 C0.902368927,12.3165825 0.902368927,11.6834175 1.29289322,11.2928932 L6.24264069,6.34314575 C9.36683502,3.21895142 14.4321549,3.21895142 17.5563492,6.34314575 Z M11.8994949,7 C9.13807119,7 6.89949494,9.23857625 6.89949494,12 C6.89949494,14.7614237 9.13807119,17 11.8994949,17 C14.6609187,17 16.8994949,14.7614237 16.8994949,12 C16.8994949,9.23857625 14.6609187,7 11.8994949,7 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <circle id='secondary' fill='currentColor' cx={12} cy={12} r={3} />
      </g>
    </svg>
  );
}
