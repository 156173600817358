import classNames from 'classnames';
import { FC } from 'react';
import styles from '../../styles/components/checkbox.module.scss';

interface iCheckboxProps {
  onChange: (val: boolean, name?: string) => void;
  value: boolean;
  disabled?: boolean;
  icon?: string;
  name?: string;
}

const Checkbox: FC<iCheckboxProps> = ({
  onChange,
  children,
  value,
  disabled,
  icon,
  name,
}) => {
  const _handleClick = () => onChange(!value, name);
  return (
    <label className={styles.wrapper}>
      <input
        type='checkbox'
        onClick={_handleClick}
        disabled={disabled}
        className={classNames(styles.checkbox)}
      />
      <span>{children}</span>
    </label>
  );
};

export default Checkbox;
