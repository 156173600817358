import { FC } from 'react';
import Tab, { iTabProps } from './Tab';
import styles from '../../styles/components/tabs.module.scss';

interface iTabsProps {
  tabs: iTabProps[];
}

const Tabs: FC<iTabsProps> = ({ tabs }) => {
  return (
    <div className={styles.wrapper}>
      {tabs.map((tab) => (
        <Tab {...tab} key={tab.label} />
      ))}
    </div>
  );
};

export default Tabs;
