import React from 'react';

export default function IconTune(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-tune</title>
      <g
        id='icon-tune'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M8,13 C6.8954305,13 6,13.8954305 6,15 C6,16.1045695 6.8954305,17 8,17 C9.1045695,17 10,16.1045695 10,15 C10,13.8954305 9.1045695,13 8,13 Z M16,7 C14.8954305,7 14,7.8954305 14,9 C14,10.1045695 14.8954305,11 16,11 C17.1045695,11 18,10.1045695 18,9 C18,7.8954305 17.1045695,7 16,7 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M17,14 C17.5522847,14 18,14.4477153 18,15 C18,15.5522847 17.5522847,16 17,16 L9.73181186,16.0010775 C9.9023677,15.7066608 10,15.364732 10,15 C10,14.6356829 9.90258972,14.2941173 9.73239368,13.9999275 L17,14 Z M14,9 C14,9.364732 14.0976323,9.70666076 14.2681881,10.0010775 L7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 L14.2677566,7.99966785 C14.0974676,8.29391627 14,8.63557574 14,9 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
