import React from 'react';

export default function IconMail(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-mail</title>
      <g
        id='icon-mail'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M22,8.61803399 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,8.61803399 L11.5527864,13.3944272 C11.8343139,13.5351909 12.1656861,13.5351909 12.4472136,13.3944272 L22,8.61803399 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M12,11.381966 L2,6.38196601 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,6.38196601 L12,11.381966 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
