import React from 'react';

export default function IconWifiOff(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-wifi-off</title>
      <g
        id='icon-wifi-off'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M9.36490068,13.6077968 L10.9099463,15.1506453 C10.2733494,15.3302881 9.67254848,15.6705973 9.17157288,16.1715729 C8.78104858,16.5620972 8.1478836,16.5620972 7.75735931,16.1715729 C7.36683502,15.7810486 7.36683502,15.1478836 7.75735931,14.7573593 C8.23993379,14.2747848 8.78425941,13.8915973 9.36490068,13.6077968 Z M19.0710678,11.9289322 C19.4615921,12.3194565 19.4615921,12.9526215 19.0710678,13.3431458 C18.6805435,13.73367 18.0473785,13.73367 17.6568542,13.3431458 C17.4427341,13.1290256 17.219496,12.9295803 16.9883899,12.7448101 L13.3307726,9.08841629 C15.4302143,9.36872966 17.4577039,10.3155683 19.0710678,11.9289322 Z M6.44265721,10.684102 L7.89211203,12.1330043 C7.33990193,12.4639041 6.81900696,12.8672845 6.34314575,13.3431458 C5.95262146,13.73367 5.31945648,13.73367 4.92893219,13.3431458 C4.5384079,12.9526215 4.5384079,12.3194565 4.92893219,11.9289322 C5.40092005,11.4569443 5.90835081,11.0420009 6.44265721,10.684102 Z M21.8994949,9.10050506 C22.2900192,9.49102935 22.2900192,10.1241943 21.8994949,10.5147186 C21.5089706,10.9052429 20.8758057,10.9052429 20.4852814,10.5147186 C17.9551419,7.98457916 14.5762614,6.82046776 11.2651143,7.02238443 L9.47087672,5.22903617 C13.8419215,4.43027557 18.5197551,5.7207652 21.8994949,9.10050506 Z M3.57470124,7.81684778 L5.00511665,9.24727404 C4.48299371,9.62271362 3.98424211,10.0451951 3.51471863,10.5147186 C3.12419433,10.9052429 2.49102935,10.9052429 2.10050506,10.5147186 C1.70998077,10.1241943 1.70998077,9.49102935 2.10050506,9.10050506 C2.56844014,8.63256999 3.06125867,8.20468423 3.57470124,7.81684778 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M3.29289322,3.29289322 C3.68341751,2.90236893 4.31658249,2.90236893 4.70710678,3.29289322 L4.70710678,3.29289322 L20.7071068,19.2928932 C21.0976311,19.6834175 21.0976311,20.3165825 20.7071068,20.7071068 C20.3165825,21.0976311 19.6834175,21.0976311 19.2928932,20.7071068 L19.2928932,20.7071068 L3.29289322,4.70710678 C2.90236893,4.31658249 2.90236893,3.68341751 3.29289322,3.29289322 Z M12,17.5 C12.8284271,17.5 13.5,18.1715729 13.5,19 C13.5,19.8284271 12.8284271,20.5 12,20.5 C11.1715729,20.5 10.5,19.8284271 10.5,19 C10.5,18.1715729 11.1715729,17.5 12,17.5 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
