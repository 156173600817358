import React from 'react';

export default function IconTicket(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-ticket</title>
      <g
        id='icon-ticket'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M22,15 L22,19 C22,19.5522847 21.5522847,20 21,20 L9,20 L9,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,9 C20.3431458,9 19,10.3431458 19,12 C19,13.6568542 20.3431458,15 22,15 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M9,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,15 C3.65685425,15 5,13.6568542 5,12 C5,10.3431458 3.65685425,9 2,9 L2,5 C2,4.44771525 2.44771525,4 3,4 L9,4 L9,20 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
