import React from 'react';

export default function IconBuoy(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-buoy</title>
      <g
        id='icon-buoy'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M2.43254511,14.9183556 C1.60436421,14.6737589 1,13.9074579 1,13 L1,11 C1,10.0925421 1.60436421,9.32624107 2.43254511,9.08164437 C3.40008115,5.90567486 5.90567486,3.40008115 9.08164437,2.43254511 C9.32624107,1.60436421 10.0925421,1 11,1 L13,1 C13.9074579,1 14.6737589,1.60436421 14.9183556,2.43254511 C18.0943251,3.40008115 20.5999189,5.90567486 21.5674549,9.08164437 C22.3956358,9.32624107 23,10.0925421 23,11 L23,13 C23,13.9074579 22.3956358,14.6737589 21.5674549,14.9183556 C20.5999189,18.0943251 18.0943251,20.5999189 14.9183556,21.5674549 C14.6737589,22.3956358 13.9074579,23 13,23 L11,23 C10.0925421,23 9.32624107,22.3956358 9.08164437,21.5674549 C5.90567486,20.5999189 3.40008115,18.0943251 2.43254511,14.9183556 Z M12,17 L13.7862803,15.5799754 C14.5624161,15.1919587 15.1947456,14.5589737 15.581936,13.7823533 L17,12 L15.5796958,10.2131603 C15.1926007,9.43917195 14.5618719,8.80824481 13.7880303,8.42089995 L12,7 L10.2128863,8.42044129 C9.43864715,8.80773615 8.80758629,9.43883217 8.42033573,10.2130974 L7,12 L8.41817859,13.7825831 C8.8050243,14.558387 9.43650185,15.1908369 10.2115865,15.5789082 L12,17 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M13.7892457,15.5784914 L16.4731143,20.9462285 C15.1271602,21.6205173 13.6078966,22 12,22 C10.3921034,22 8.87283977,21.6205173 7.52688575,20.9462285 L10.2107543,15.5784914 C10.7491359,15.8482069 11.3568414,16 12,16 C12.6431586,16 13.2508641,15.8482069 13.7892457,15.5784914 Z M3.0537715,7.52688575 L8.4215086,10.2107543 C8.15179309,10.7491359 8,11.3568414 8,12 C8,12.6431586 8.15179309,13.2508641 8.4215086,13.7892457 L3.0537715,16.4731143 C2.37948272,15.1271602 2,13.6078966 2,12 C2,10.3921034 2.37948272,8.87283977 3.0537715,7.52688575 Z M20.9462285,7.52688575 C21.6205173,8.87283977 22,10.3921034 22,12 C22,13.6078966 21.6205173,15.1271602 20.9462285,16.4731143 L15.5784914,13.7892457 C15.8482069,13.2508641 16,12.6431586 16,12 C16,11.3568414 15.8482069,10.7491359 15.5784914,10.2107543 L20.9462285,7.52688575 Z M12,2 C13.6078966,2 15.1271602,2.37948272 16.4731143,3.0537715 L13.7892457,8.4215086 C13.2508641,8.15179309 12.6431586,8 12,8 C11.3568414,8 10.7491359,8.15179309 10.2107543,8.4215086 L7.52688575,3.0537715 C8.87283977,2.37948272 10.3921034,2 12,2 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
