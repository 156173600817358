import React from 'react';

export default function IconArrowThickUpCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-arrow-thick-up-circle</title>
      <g
        id='icon-arrow-thick-up-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M14,12 L14,17 C14,17.5522847 13.5522847,18 13,18 L11,18 C10.4477153,18 10,17.5522847 10,17 L10,12 L8,12 C7.10909515,12 6.66292836,10.9228581 7.29289322,10.2928932 L11.2928932,6.29289322 C11.6834175,5.90236893 12.3165825,5.90236893 12.7071068,6.29289322 L16.7071068,10.2928932 C17.3370716,10.9228581 16.8909049,12 16,12 L14,12 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
