import React from 'react';

export default function IconThumbsDown(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-thumbs-down</title>
      <g
        id='icon-thumbs-down'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19.0001071,4.7949922 L21.919145,11.6060807 C21.9724923,11.7305575 22,11.8645732 22,12 L22,20.0024554 C22,21.1059154 21.1057268,22 20.0024553,22 L13.9973917,22 C12.4951191,22 10.8852579,20.9375262 10.2926402,19.5547517 L8.08085493,14.3939193 C8.02750771,14.2694425 7.99999996,14.1354268 7.99999996,14 L7.99999996,12 C7.99999996,10.3378003 9.33961848,9 10.9973917,9 L15,9 L15,4.99810131 C15,3.33619935 16.3418195,2 18.0010435,2 C18.553532,2 19.0013316,2.44803331 19.0010434,3.00052176 L19.0001071,4.7949922 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
          transform='translate(15.000000, 12.000000) scale(-1, -1) translate(-15.000000, -12.000000) '
        />
        <rect
          id='secondary'
          fill='currentColor'
          transform='translate(4.000000, 7.500000) scale(-1, -1) translate(-4.000000, -7.500000) '
          x={2}
          y={2}
          width={4}
          height={11}
          rx={1}
        />
      </g>
    </svg>
  );
}
