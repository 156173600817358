import React from 'react';

export default function IconCurrencyEuro(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-currency-euro</title>
      <g
        id='icon-currency-euro'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M16.9497475,7.05025253 C17.3402718,7.44077682 17.3402718,8.0739418 16.9497475,8.46446609 C16.5592232,8.85499039 15.9260582,8.85499039 15.5355339,8.46446609 C13.5829124,6.51184464 10.4170876,6.51184464 8.46446609,8.46446609 C7.74382811,9.18510408 7.28915007,10.0709884 7.10043199,11.0001468 L13,11 C13.5522847,11 14,11.4477153 14,12 C14,12.5522847 13.5522847,13 13,13 L7.10063509,13.0008526 C7.28947616,13.9296469 7.7440865,14.8151543 8.46446609,15.5355339 C10.4170876,17.4881554 13.5829124,17.4881554 15.5355339,15.5355339 C15.9260582,15.1450096 16.5592232,15.1450096 16.9497475,15.5355339 C17.3402718,15.9260582 17.3402718,16.5592232 16.9497475,16.9497475 C14.2160774,19.6834175 9.78392257,19.6834175 7.05025253,16.9497475 C5.93860797,15.8381029 5.27901271,14.4455909 5.07146675,13.0006405 L5,13 C4.44771525,13 4,12.5522847 4,12 C4,11.4477153 4.44771525,11 5,11 L5.07132303,11.0003606 C5.27870807,9.55505229 5.93835124,8.16215382 7.05025253,7.05025253 C9.78392257,4.31658249 14.2160774,4.31658249 16.9497475,7.05025253 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
