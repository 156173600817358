import { FC, useState } from 'react';
import classNames from 'classnames';

import styles from '../styles/pages/uikit.module.scss';
import { Button, ButtonGroup, Input } from '../components';
import progress from '../core/progress';

const Uikit: FC = () => {
  const [val, setVal] = useState('');

  const _handleProgress = () => {
    progress.start();
    setTimeout(() => progress.done(), 1000);
  };
  return (
    <div className={classNames(styles.wrapper)}>
      <h1>Input</h1>
      <div className={styles.list}>
        <Input value={val} onChange={setVal} placeholder='Test input' />
        <Input
          icon='anchor'
          value={val}
          onChange={setVal}
          placeholder='Test input'
        />
        <Input
          value={val}
          onChange={setVal}
          placeholder='Password input'
          type='password'
        />
        <Input
          value={val}
          onChange={setVal}
          placeholder='Error'
          error='Proident officia minim officia sunt enim culpa ullamco reprehenderit.'
        />
        <Input value={val} onChange={setVal} placeholder='Disabled' disabled />
      </div>
      <h1>Button</h1>
      <div className={styles.list}>
        <Button onClick={_handleProgress}>Regular</Button>
        <Button onClick={() => alert('pressed')} primary>
          Primary
        </Button>
        <Button disabled onClick={() => alert('pressed')}>
          Disabled Regular
        </Button>
        <Button disabled onClick={() => alert('pressed')} primary>
          Disabled Primary
        </Button>
        <Button onClick={() => alert('pressed')} icon='anchor'>
          Icon Regular
        </Button>
        <Button onClick={() => alert('pressed')} icon='anchor' primary>
          Icon Primary
        </Button>
        <ButtonGroup>
          <Button onClick={() => alert('pressed')} icon='anchor' />
          <Button onClick={() => alert('pressed')} icon='thermometer' />
          <Button onClick={() => alert('pressed')}>Icon Primary</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Uikit;
