import React from 'react';

export default function IconShoppingCart(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-shopping-cart</title>
      <g
        id='icon-shopping-cart'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M7,4 L21,4 C21.743382,4 22.2268777,4.7823125 21.8944272,5.4472136 L17.8944272,13.4472136 C17.7250352,13.7859976 17.3787721,14 17,14 L7,14 C6.44771525,14 6,13.5522847 6,13 L6,5 C6,4.44771525 6.44771525,4 7,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M17.7324356,19 C17.9026057,19.2941734 18,19.6357129 18,20 C18,21.1045695 17.1045695,22 16,22 C14.8954305,22 14,21.1045695 14,20 C14,19.6357129 14.0973943,19.2941734 14.2675644,19 L8.73243561,19 C8.90260571,19.2941734 9,19.6357129 9,20 C9,21.1045695 8.1045695,22 7,22 C5.8954305,22 5,21.1045695 5,20 C5,19.6028378 5.11576612,19.2327143 5.31539526,18.9215325 C3.98842333,18.6117968 3,17.4213277 3,16 C3,14.6937812 3.83480763,13.5825421 5,13.1707057 L5,4 L3,4 C2.44771525,4 2,3.55228475 2,3 C2,2.44771525 2.44771525,2 3,2 L6,2 C6.55228475,2 7,2.44771525 7,3 L7,13 L18,13 C18.5522847,13 19,13.4477153 19,14 C19,14.5522847 18.5522847,15 18,15 L6,15 C5.44771525,15 5,15.4477153 5,16 C5,16.5522847 5.44771525,17 6,17 L18,17 C18.5522847,17 19,17.4477153 19,18 C19,18.5522847 18.5522847,19 18,19 L17.7324356,19 L17.7324356,19 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
