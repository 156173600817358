import { FC, Fragment, memo, useCallback, useMemo } from 'react';
import { useUser } from '../../hooks';
import { useSurveyContext } from '../../hooks/survey';
import styles from '../../styles/components/question.module.scss';
import { eType, iQuestion } from '../../types/question';
import DateComp from './Date';
import Options from './Options';
import Short from './Short';

interface iQuestionProps extends iQuestion {
  id: string;
  title: string;
  explanation?: string;
  type: eType;

  options?: string[];

  value?: string | string[];
  onChange?: (key: string, value: string | string[]) => void;

  max?: number;
  min?: number;
}

const components: { [key: string]: any } = {
  SHORT: Short,
  OPTIONS: Options,
  DATE: DateComp,
};

const Question: FC<iQuestionProps> = memo(({ id, title, type, ...props }) => {
  const { parseString } = useUser();
  const { value: values, onChange } = useSurveyContext();
  const Component = useMemo(() => components[type], [type]);

  const value = useMemo(() => values[id] ?? '', [JSON.stringify(values)]);
  const _handleChange = useCallback(
    (val: string | string[]) => onChange?.(id, val),
    [id],
  );

  const titleValue = useMemo(() => {
    const lines = parseString(title).split('\n');
    const joined = lines.map((line) => (
      <Fragment key={line}>
        {line}
        <br />
      </Fragment>
    ));
    return joined;
  }, [title]);

  return (
    <div className={styles.wrapper}>
      <h2>{titleValue}</h2>
      <div>
        {Component ? (
          <Component {...props} onChange={_handleChange} value={value} />
        ) : (
          <span>Not implemented yet</span>
        )}
      </div>
    </div>
  );
});

export default Question;
