import React from 'react';

export default function IconPlay(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-play</title>
      <g
        id='icon-play'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M15.5144958,11.1425071 C16.1618347,11.5309105 16.1618347,12.4690895 15.5144958,12.8574929 L10.5144958,15.8574929 C9.84797124,16.2574076 9,15.7772945 9,15 L9,9 C9,8.22270552 9.84797124,7.74259237 10.5144958,8.14250707 L15.5144958,11.1425071 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
