import { FC } from 'react';
import { Button, ButtonGroup } from '../../../components';
import { Loader, PageSkeleton } from '../../../layout';
import { useSurveys } from '../../../hooks';
import { Survey } from '../../../components';

const SurveysPage: FC = () => {
  const { loading, surveys, openModal, createSurvey } = useSurveys();
  return (
    <PageSkeleton
      title='Surveys'
      header={
        <ButtonGroup>
          <Button
            label='Create new'
            suffix='plus'
            primary
            onClick={createSurvey}
          />
        </ButtonGroup>
      }
    >
      <Loader loading={loading}>
        {surveys.map((survey) => (
          <Survey {...survey} {...{ onModal: openModal }} key={survey.id} />
        ))}
      </Loader>
    </PageSkeleton>
  );
};

export default SurveysPage;
