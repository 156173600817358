import React from 'react';

export default function IconUserRemove(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-user-remove</title>
      <g
        id='icon-user-remove'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,13 C13.7614237,13 16,15.2385763 16,18 L16,19 C16,20.1045695 15.1045695,21 14,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,18 C2,15.2385763 4.23857625,13 7,13 L11,13 Z M21,9 C21.5522847,9 22,9.44771525 22,10 C22,10.5522847 21.5522847,11 21,11 L15,11 C14.4477153,11 14,10.5522847 14,10 C14,9.44771525 14.4477153,9 15,9 L21,9 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
