import { FC } from 'react';
import { Segment, Tabs } from '.';
import { useUser } from '../hooks';
import styles from '../styles/components/sidebar.module.scss';

// todo: place to external router config
const routes: {
  [key: string]: {
    tabs: { href: string; label: string; icon: string }[];
    adminOnly?: boolean;
  };
} = {
  general: {
    tabs: [
      { href: '/', label: 'Notifications', icon: 'icon-home' },
      { href: '/points', label: 'Points', icon: 'icon-bolt' },
      { href: '/discover', label: 'Discover', icon: 'icon-map' },
      {
        href: '/how-it-works',
        label: 'How it works',
        icon: 'icon-information',
      },
      {
        href: '/import-profile',
        label: 'Import profile data',
        icon: 'icon-cloud-upload',
      },
    ],
  },
  admin: {
    adminOnly: true,
    tabs: [
      { href: '/admin', label: 'Overview', icon: 'icon-cog' },
      { href: '/admin/query', label: 'Query database', icon: 'icon-search' },
      { href: '/admin/surveys', label: 'Surveys', icon: 'icon-survey' },
      { href: '/admin/groups', label: 'User groups', icon: 'icon-user-group' },
      {
        href: '/admin/whitelists',
        label: 'Whitelists',
        icon: 'icon-user-check',
      },
      { href: '/admin/campaigns', label: 'Campaigns', icon: 'icon-collection' },
    ],
  },
};

const Sidebar: FC = () => {
  const { user } = useUser();

  return (
    <div className={styles.wrapper}>
      {Object.entries(routes).map(([key, value]) => {
        if (value.adminOnly && !user?.is_admin) return null;
        return (
          <Segment title={key} key={key}>
            <Tabs tabs={value.tabs} />
          </Segment>
        );
      })}
    </div>
  );
};

export default Sidebar;
