import React from 'react';

export default function IconRemoveSquare(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-remove-square</title>
      <g
        id='icon-remove-square'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={3}
          y={3}
          width={18}
          height={18}
          rx={2}
        />
        <path
          d='M7,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
