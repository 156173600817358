import React from 'react';

export default function IconTrophy(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-trophy</title>
      <g
        id='icon-trophy'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M13,16 L13,19 C13,19.5522847 13.4477153,20 14,20 L16,20 C16.5522847,20 17,20.4477153 17,21 C17,21.5522847 16.5522847,22 16,22 L8,22 C7.44771525,22 7,21.5522847 7,21 C7,20.4477153 7.44771525,20 8,20 L10,20 C10.5522847,20 11,19.5522847 11,19 L11,16 L13,16 Z M7,4 L7,6 L4,6 L4,10 C4,11.1045695 4.8954305,12 6,12 L7,12 L7,14 L6,14 C3.790861,14 2,12.209139 2,10 L2,6 C2,4.8954305 2.8954305,4 4,4 L7,4 Z M20,4 C21.1045695,4 22,4.8954305 22,6 L22,10 C22,12.209139 20.209139,14 18,14 L17,14 L17,12 L18,12 C19.1045695,12 20,11.1045695 20,10 L20,6 L17,6 L17,4 L20,4 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M8,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,11 C18,14.3137085 15.3137085,17 12,17 C8.6862915,17 6,14.3137085 6,11 L6,4 C6,2.8954305 6.8954305,2 8,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
