import React from 'react';

export default function IconFlag(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-flag</title>
      <g
        id='icon-flag'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M4,15 C3.44771525,15 3,14.5522847 3,14 L3,4 C3,3.44771525 3.44771525,3 4,3 L11,3 C11.2652165,3 11.5195704,3.10535684 11.7071068,3.29289322 L13.4142136,5 L21,5 C21.743382,5 22.2268777,5.7823125 21.8944272,6.4472136 L19.618034,11 L21.8944272,15.5527864 C22.2268777,16.2176875 21.743382,17 21,17 L13,17 C12.7347835,17 12.4804296,16.8946432 12.2928932,16.7071068 L10.5857864,15 L4,15 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={2}
          y={2}
          width={2}
          height={20}
          rx={1}
        />
      </g>
    </svg>
  );
}
