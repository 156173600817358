import React from 'react';

export default function IconPin(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-pin</title>
      <g
        id='icon-pin'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M2.24431092,20.3450695 L8.74431092,12.8450695 C9.12439475,12.4065112 9.79674288,12.3825293 10.2071068,12.7928932 L11.2071068,13.7928932 C11.6174707,14.2032571 11.5934888,14.8756053 11.1549305,15.2556891 L3.65493054,21.7556891 C2.71674838,22.5687803 1.43121972,21.2832516 2.24431092,20.3450695 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M15,15.4142136 L15,18 C15,18.2652165 14.8946432,18.5195704 14.7071068,18.7071068 L13.7071068,19.7071068 C13.3165825,20.0976311 12.6834175,20.0976311 12.2928932,19.7071068 L4.29289322,11.7071068 C3.90236893,11.3165825 3.90236893,10.6834175 4.29289322,10.2928932 L5.29289322,9.29289322 C5.4804296,9.10535684 5.73478351,9 6,9 L8.58578644,9 L13,4.58578644 L13,3 C13,2.10909515 14.0771419,1.66292836 14.7071068,2.29289322 L21.7071068,9.29289322 C22.3370716,9.92285808 21.8909049,11 21,11 L19.4142136,11 L15,15.4142136 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
