import React from 'react';

export default function IconPhoneRing(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-phone-ring</title>
      <g
        id='icon-phone-ring'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M4,2 L8,2 C8.47668102,2 8.88709584,2.33645967 8.98058068,2.80388386 L9.98058068,7.80388386 C10.0461527,8.13174414 9.94353019,8.47068337 9.70710678,8.70710678 L7.19257498,11.2216386 C8.37026196,13.6546578 10.3453422,15.629738 12.7783614,16.807425 L15.2928932,14.2928932 C15.5293166,14.0564698 15.8682559,13.9538473 16.1961161,14.0194193 L21.1961161,15.0194193 C21.6635403,15.1129042 22,15.523319 22,16 L22,20 C22,21.1045802 21.1045629,22 20,22 L18,22 C9.163444,22 2,14.836556 2,6 L2,4 C2,2.8954305 2.8954305,2 4,2 L4,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M14,2 C18.418278,2 22,5.581722 22,10 C22,10.5522847 21.5522847,11 21,11 C20.4477153,11 20,10.5522847 20,10 C20,6.6862915 17.3137085,4 14,4 C13.4477153,4 13,3.55228475 13,3 C13,2.44771525 13.4477153,2 14,2 Z M14,6 C16.209139,6 18,7.790861 18,10 C18,10.5522847 17.5522847,11 17,11 C16.4477153,11 16,10.5522847 16,10 C16,8.8954305 15.1045695,8 14,8 C13.4477153,8 13,7.55228475 13,7 C13,6.44771525 13.4477153,6 14,6 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
