import { FC } from 'react';
import { Input } from '..';

interface iShortProps {
  description?: string;
  placeholder?: string;
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
}
const Short: FC<iShortProps> = ({
  value,
  onChange,
  placeholder,
  description,
}) => {
  const _handleChange = (val: string) => onChange?.(val);
  return (
    <>
      <p>{description}</p>
      <Input
        value={String(value)}
        onChange={_handleChange}
        placeholder={placeholder}
      />
    </>
  );
};

export default Short;
