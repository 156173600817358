import { FC } from 'react';
import UserProvider from './User';
import ModalProvider from './Modal';

const Prividers: FC = ({ children }) => {
  return (
    <>
      <UserProvider>
        <ModalProvider>{children}</ModalProvider>
      </UserProvider>
    </>
  );
};

export default Prividers;
