import { FC, useCallback } from 'react';
import { formatDate, formatDifference } from '../../core';
import styles from '../../styles/components/usergroup.module.scss';
import { iUserGroup } from '../../types';

interface iUserGroupProps extends iUserGroup {
  onModal: (id: string) => void;
}

const UserGroup: FC<iUserGroupProps> = ({
  id,
  name,
  user_ids,
  onModal,
  created_at,
  updated_at = '',
}) => {
  const _handleClick = useCallback(() => onModal(id), []);

  return (
    <div className={styles.wrapper} onClick={_handleClick}>
      <div className={styles.header}>
        <strong>{name}</strong>
        <span>{user_ids?.length} users</span>
      </div>
      <div className={styles.footer}>
        <span>Created: {formatDate(created_at)}</span>
        <span>Last update: {formatDifference(updated_at)} ago</span>
      </div>
    </div>
  );
};
export default UserGroup;
