import { FC, memo, useMemo } from 'react';
import { usePagination, useSurveyContext } from '../../hooks/survey';
import { SurveySkeleton } from '../../layout';
import PageContent from '../../layout/PageContent';

interface iQuestionPageProps {}

const SurveyPage: FC<iQuestionPageProps> = memo(() => {
  const { survey, value } = useSurveyContext();
  const { index, isLast, isFirst, onNext, onBack, questions } = usePagination(
    survey?.id,
  );

  // Check if page is filled
  // todo: move to an external hook
  const isPageFilled = useMemo(() => {
    if (questions.length === 0) return false;

    return questions.every(({ id, min, max }) => {
      const val = value[id];

      let empty = !val;
      if (min && val) empty = min < val?.length;
      if (max && val) empty = max > val?.length;

      return !empty;
    });
  }, [JSON.stringify(questions), JSON.stringify(value)]);

  return (
    <SurveySkeleton
      title={survey?.title || ''}
      progress={index / (survey?.page_num ?? 1 - 1)}
      isBackVisible={!isFirst}
      isNextVisible={isPageFilled}
      isFinish={isLast}
      isFooterVisible={!!survey?.id}
      onNext={onNext}
      onBack={onBack}
    >
      <PageContent survey={survey?.id ?? ''} questions={questions} />
    </SurveySkeleton>
  );
});

export default SurveyPage;
