import React from 'react';

export default function IconDocumentNotes(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-document-notes</title>
      <g
        id='icon-document-notes'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,2 L12,8 C12,9.1045695 12.8954305,10 14,10 L20,10 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 L12,2 Z M12,17 L8,17 C7.44771525,17 7,17.4477153 7,18 C7,18.5522847 7.44771525,19 8,19 L8,19 L12,19 C12.5522847,19 13,18.5522847 13,18 C13,17.4477153 12.5522847,17 12,17 L12,17 Z M16,13 L8,13 C7.44771525,13 7,13.4477153 7,14 C7,14.5522847 7.44771525,15 8,15 L8,15 L16,15 C16.5522847,15 17,14.5522847 17,14 C17,13.4477153 16.5522847,13 16,13 L16,13 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <polygon id='secondary' fill='currentColor' points='14 2 20 8 14 8' />
      </g>
    </svg>
  );
}
