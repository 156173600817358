import React from 'react';

export default function IconServer(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-server</title>
      <g
        id='icon-server'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,13 C20.1045695,13 21,13.8954305 21,15 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,15 C3,13.8954305 3.8954305,13 5,13 L19,13 Z M10,16 L7,16 C6.44771525,16 6,16.4477153 6,17 C6,17.5522847 6.44771525,18 7,18 L7,18 L10,18 C10.5522847,18 11,17.5522847 11,17 C11,16.4477153 10.5522847,16 10,16 L10,16 Z M19,3 C20.1045695,3 21,3.8954305 21,5 L21,9 C21,10.1045695 20.1045695,11 19,11 L5,11 C3.8954305,11 3,10.1045695 3,9 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={6}
          y={6}
          width={5}
          height={2}
          rx={1}
        />
      </g>
    </svg>
  );
}
