import { FC } from 'react';
import { Route, Switch } from 'react-router';
import { Title } from '../../components';
import CampaignsPage from './Campaigns';
import CampaignResponses from './Campaigns/Responses';
import GroupsPage from './Groups';
import QueryPage from './Query';
import SurveysPage from './Surveys';
import WhitelistsPage from './Whitelists';
import WhitelistPage from './Whitelists/Whitelist';
import WIP from './WIP';

const AdminRouter: FC = () => {
  return (
    <>
      <Title title='Admin' />
      <Switch>
        <Route path='/admin/query' component={QueryPage} />
        <Route path='/admin/groups' component={GroupsPage} />
        <Route path='/admin/campaigns' exact component={CampaignsPage} />
        <Route
          path='/admin/campaigns/:campaign_id/responses'
          component={CampaignResponses}
        />
        <Route path='/admin/surveys' component={SurveysPage} />
        <Route path='/admin/whitelists' exact component={WhitelistsPage} />
        <Route
          path='/admin/whitelists/:whitelist_id'
          component={WhitelistPage}
        />
        <Route path='*' component={WIP} />
      </Switch>
    </>
  );
};

export default AdminRouter;
