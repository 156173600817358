import { FC } from 'react';
import classNames from 'classnames';
import styles from '../styles/pages/auth.module.scss';
import { Title } from '../components';

interface iAuthSkeleton {
  title: string;
  subtitle: string;
  reverse?: boolean;
  pageTitle: string;
}
const AuthSkeleton: FC<iAuthSkeleton> = ({
  children,
  title,
  subtitle,
  reverse,
  pageTitle,
}) => {
  return (
    <>
      <Title title={pageTitle} />
      <div className={classNames(styles.wrapper, reverse && styles.reverse)}>
        <div className={styles.col}>{children}</div>
        <div
          className={classNames(
            styles.col,
            styles.overlay,
            styles.Activate,
            reverse && styles.reverse,
          )}
        >
          <img src='/images/logo/triyit-trademark.png' alt='' />
          <div className={styles.highlight}>
            <h4>{title}</h4>
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthSkeleton;
