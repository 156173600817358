import React from 'react';

export default function IconMoodHappy(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-mood-happy</title>
      <g
        id='icon-mood-happy'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M17.1973068,14 C17.9674799,14 18.4485583,14.8340596 18.0629127,15.500726 C16.8205975,17.6483184 14.5275184,19 12,19 C9.47248156,19 7.17940246,17.6483184 5.93708731,15.500726 C5.55144167,14.8340596 6.03252012,14 6.80269317,14 L17.1973068,14 Z M8.5,8 C9.32842712,8 10,8.67157288 10,9.5 C10,10.3284271 9.32842712,11 8.5,11 C7.67157288,11 7,10.3284271 7,9.5 C7,8.67157288 7.67157288,8 8.5,8 Z M15.5,8 C16.3284271,8 17,8.67157288 17,9.5 C17,10.3284271 16.3284271,11 15.5,11 C14.6715729,11 14,10.3284271 14,9.5 C14,8.67157288 14.6715729,8 15.5,8 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
