import { FC, memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Icon } from '../components';
import styles from '../styles/layout/survey.module.scss';

interface iSurveyHeaderProps {
  title: string;
}

const SurveyHeader: FC<iSurveyHeaderProps> = memo(({ title }) => {
  const history = useHistory();

  // TODO: add confirm modal
  const _handleClose = useCallback(() => history.push('/'), []);

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <h1>{title}</h1>
        <button className={styles.closeWrapper} onClick={_handleClose}>
          <Icon icon='x' />
        </button>
      </div>
    </div>
  );
});

export default SurveyHeader;
