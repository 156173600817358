import React from 'react';

export default function IconAt(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-at</title>
      <g
        id='icon-at'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M21.2414124,8.17578277 C21.7495416,9.40251517 22.0049712,10.6967067 22.0049712,11.9994183 L22.0026171,13.5026169 C22.0026171,15.4356136 20.4356137,17.0026169 18.5026171,17.0026169 C17.2983918,17.0026169 16.2362117,16.3944494 15.6064633,15.468501 C14.6967788,16.4141662 13.418418,17.0026169 12.0026171,17.0026169 C9.24119338,17.0026169 7.00261713,14.7640407 7.00261713,12.0026169 C7.00261713,9.2411932 9.24119338,7.00261695 12.0026171,7.00261695 C14.7640409,7.00261695 17.0026171,9.2411932 17.0026171,12.0026169 L17.0026171,13.5026169 C17.0026171,14.3310441 17.67419,15.0026169 18.5026171,15.0026169 C19.3310443,15.0026169 20.0026171,14.3310441 20.0026184,13.5010517 L20.0049724,11.9979895 C20.0048306,10.9583947 19.8008658,9.92424749 19.3936534,8.94114964 C17.7028516,4.85919303 13.0231062,2.92077905 8.94114964,4.61158084 C4.85919303,6.30238263 2.92077905,10.9821279 4.61158084,15.0640846 C6.30238263,19.1460412 10.9821279,21.0844551 15.0640846,19.3936534 C15.5743291,19.1823031 16.1592973,19.4246049 16.3706475,19.9348495 C16.5819977,20.445094 16.339696,21.0300622 15.8294514,21.2414124 C10.7270057,23.3549147 4.87732401,20.9318972 2.76382177,15.8294514 C0.650319533,10.7270057 3.07333701,4.87732401 8.17578277,2.76382177 C13.2782285,0.650319533 19.1279102,3.07333701 21.2414124,8.17578277 Z M12.0026171,9.00261695 C10.3457629,9.00261695 9.00261713,10.3457627 9.00261713,12.0026169 C9.00261713,13.6594712 10.3457629,15.0026169 12.0026171,15.0026169 C13.6594714,15.0026169 15.0026171,13.6594712 15.0026171,12.0026169 C15.0026171,10.3457627 13.6594714,9.00261695 12.0026171,9.00261695 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
