import React from 'react';

export default function IconHeart(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-heart</title>
      <g
        id='icon-heart'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M12.8786797,8.87867966 C14.0502525,7.70710678 15.9497475,7.70710678 17.1213203,8.87867966 C18.2928932,10.0502525 18.2928932,11.9497475 17.1213203,13.1213203 L12.7071068,17.5355339 C12.3165825,17.9260582 11.6834175,17.9260582 11.2928932,17.5355339 L6.87867966,13.1213203 C5.70710678,11.9497475 5.70710678,10.0502525 6.87867966,8.87867966 C8.05025253,7.70710678 9.94974747,7.70710678 11.1213203,8.87867966 L12,9.75735931 L12.8786797,8.87867966 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
