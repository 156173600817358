import { FC } from 'react';
import styles from '../../styles/pages/survey-start.module.scss';
import { SurveySkeleton } from '../../layout';

interface iStartPageProps {}

const ErrorPage: FC<iStartPageProps> = () => {
  return (
    <SurveySkeleton title={'Error'}>
      <div className={styles.wrapper}></div>
    </SurveySkeleton>
  );
};

export default ErrorPage;
