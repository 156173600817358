import React from 'react';

export default function IconNews(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-news</title>
      <g
        id='icon-news'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M10,6 L7,6 C6.44771525,6 6,6.44771525 6,7 L6,7 L6,11 C6,11.5522847 6.44771525,12 7,12 L7,12 L10,12 C10.5522847,12 11,11.5522847 11,11 L11,11 L11,7 C11,6.44771525 10.5522847,6 10,6 L10,6 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M17,14 C17.5522847,14 18,14.4477153 18,15 L18,17 C18,17.5522847 17.5522847,18 17,18 L7,18 C6.44771525,18 6,17.5522847 6,17 L6,15 C6,14.4477153 6.44771525,14 7,14 L17,14 Z M17,10 C17.5522847,10 18,10.4477153 18,11 C18,11.5522847 17.5522847,12 17,12 L14,12 C13.4477153,12 13,11.5522847 13,11 C13,10.4477153 13.4477153,10 14,10 L17,10 Z M17,6 C17.5522847,6 18,6.44771525 18,7 C18,7.55228475 17.5522847,8 17,8 L14,8 C13.4477153,8 13,7.55228475 13,7 C13,6.44771525 13.4477153,6 14,6 L17,6 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
