import React from 'react';

export default function IconMoodSad(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-mood-sad</title>
      <g
        id='icon-mood-sad'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M12,14 C14.0172834,14 15.8983086,14.8593391 17.2173479,16.3330862 C17.793872,16.9772298 17.3366787,18 16.472213,18 L7.52778699,18 C6.66332131,18 6.20612802,16.9772298 6.7826521,16.3330862 C8.10169144,14.8593391 9.98271664,14 12,14 Z M8.5,8 C9.32842712,8 10,8.67157288 10,9.5 C10,10.3284271 9.32842712,11 8.5,11 C7.67157288,11 7,10.3284271 7,9.5 C7,8.67157288 7.67157288,8 8.5,8 Z M15.5,8 C16.3284271,8 17,8.67157288 17,9.5 C17,10.3284271 16.3284271,11 15.5,11 C14.6715729,11 14,10.3284271 14,9.5 C14,8.67157288 14.6715729,8 15.5,8 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
