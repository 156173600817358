import React from 'react';

export default function IconCalendarAdd(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-calendar-add</title>
      <g
        id='icon-calendar-add'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,4 C20.1045695,4 21,4.8954305 21,6 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,6 C3,4.8954305 3.8954305,4 5,4 L19,4 Z M19,9 L5,9 L5,19 L19,19 L19,9 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12,10 C12.5522847,10 13,10.4477153 13,11 L13,13 L15,13 C15.5522847,13 16,13.4477153 16,14 C16,14.5522847 15.5522847,15 15,15 L13,15 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,15 L9,15 C8.44771525,15 8,14.5522847 8,14 C8,13.4477153 8.44771525,13 9,13 L11,13 L11,11 C11,10.4477153 11.4477153,10 12,10 Z M7,2 C7.55228475,2 8,2.44771525 8,3 L8,6 C8,6.55228475 7.55228475,7 7,7 C6.44771525,7 6,6.55228475 6,6 L6,3 C6,2.44771525 6.44771525,2 7,2 Z M17,2 C17.5522847,2 18,2.44771525 18,3 L18,6 C18,6.55228475 17.5522847,7 17,7 C16.4477153,7 16,6.55228475 16,6 L16,3 C16,2.44771525 16.4477153,2 17,2 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
