import React from 'react';

export default function IconArrowThinLeftCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-arrow-thin-left-circle</title>
      <g
        id='icon-arrow-thin-left-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M14.5857864,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L14.5857864,11 L12.2928932,8.70710678 C11.9023689,8.31658249 11.9023689,7.68341751 12.2928932,7.29289322 C12.6834175,6.90236893 13.3165825,6.90236893 13.7071068,7.29289322 L17.7071068,11.2928932 C18.0976311,11.6834175 18.0976311,12.3165825 17.7071068,12.7071068 L13.7071068,16.7071068 C13.3165825,17.0976311 12.6834175,17.0976311 12.2928932,16.7071068 C11.9023689,16.3165825 11.9023689,15.6834175 12.2928932,15.2928932 L14.5857864,13 Z'
          id='secondary'
          fill='currentColor'
          transform='translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) '
        />
      </g>
    </svg>
  );
}
