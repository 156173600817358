import React from 'react';

export default function IconCertificate(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-certificate</title>
      <g
        id='icon-certificate'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group' transform='translate(2.000000, 3.000000)'>
          <path
            d='M18,0 C19.1045695,0 20,0.8954305 20,2 L20,14 C20,15.1045695 19.1045695,16 18,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,2 C0,0.8954305 0.8954305,0 2,0 L18,0 Z M16,2 L4,2 C4,3.1045695 3.1045695,4 2,4 L2,12 C3.1045695,12 4,12.8954305 4,14 L16,14 C16,12.8954305 16.8954305,12 18,12 L18,4 C16.8954305,4 16,3.1045695 16,2 Z M14,4 C14.5522847,4 15,4.44771525 15,5 C15,5.55228475 14.5522847,6 14,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L14,4 Z'
            id='primary'
            fill='currentColor'
            opacity='0.4'
            fillRule='nonzero'
          />
          <path
            d='M12,8 C14.209139,8 16,9.790861 16,12 C16,13.3294819 15.3513945,14.5074739 14.3533525,15.2348068 L16.8512205,18.6682576 L14.6222462,18.490287 L13.142485,20.166684 L12.5542548,15.9619106 C12.3730991,15.9870213 12.1880652,16 12,16 C11.8119348,16 11.6269009,15.9870213 11.4457452,15.9619106 L10.857515,20.166684 L9.37775385,18.490287 L7.14877954,18.6682576 L9.64664751,15.2348068 C8.64860555,14.5074739 8,13.3294819 8,12 C8,9.790861 9.790861,8 12,8 Z M12,10 C10.8954305,10 10,10.8954305 10,12 C10,13.1045695 10.8954305,14 12,14 C13.1045695,14 14,13.1045695 14,12 C14,10.8954305 13.1045695,10 12,10 Z'
            id='secondary'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
}
