import { useCallback, useEffect, useMemo, useRef } from 'react';
import useFetch from 'use-http';
import { useModal } from '.';
import { WhitelistAttachToCampaignModal, WhitelistEditModal } from '../modals';
import { iWhitelist } from '../types';
interface iUserWithAnswers {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  answers: { [key: string]: string };
}
interface iWhitelistsHook {
  whitelists: iWhitelist[];
  activeWhitelists: iWhitelist[];
  inactiveWhitelists: iWhitelist[];
  loading: boolean;
  openModal: (survey: string | iWhitelist) => void;
}

interface iWhitelistResponsesHook {
  responses: iUserWithAnswers[] | null;
  loading: boolean;
}

export const useWhitelists = (): iWhitelistsHook => {
  const { presentModal } = useModal();

  const res = useFetch('/campaign/whitelist', []);
  const whitelistsRef = useRef<iWhitelist[]>([]);

  /**
   * Open survey in modal
   */
  const _handleOpenGroup = useCallback((id: string | iWhitelist) => {
    let whitelist: string | iWhitelist | undefined;
    if (typeof id === 'string') {
      whitelist = whitelistsRef.current?.find((s) => s.id === id);
    }

    if (!whitelist || typeof whitelist === 'string') return;

    presentModal({
      title: whitelist.name ?? 'Unnamed whitelist',
      subtitle: 'Edit whitelist',
      content: (
        <WhitelistEditModal
          {...{
            ...whitelist,
          }}
        />
      ),
    });
  }, []);

  // Update group ref
  useEffect(() => {
    whitelistsRef.current = res.data?.whitelists ?? [];
  }, [JSON.stringify(res.data?.whitelists)]);

  const activeWhitelists = useMemo(() => {
    return (
      res?.data?.whitelists?.filter(
        (whitelist: iWhitelist) => whitelist.is_active,
      ) ?? []
    );
  }, [!!res?.data?.whitelists]);
  const inactiveWhitelists = useMemo(() => {
    return (
      res?.data?.whitelists?.filter(
        (whitelist: iWhitelist) => !whitelist.is_active,
      ) ?? []
    );
  }, [!!res?.data?.whitelists]);

  return {
    whitelists: res?.data?.whitelists ?? [],
    activeWhitelists,
    inactiveWhitelists,
    loading: res.loading,
    openModal: _handleOpenGroup,
  };
};

export const useWhitelist = (id: string) => {
  const { presentModal } = useModal();
  const res = useFetch(`/campaign/whitelist/${id}`, [id]);
  const whitelistsRef = useRef<iWhitelist[]>([]);

  // Update group ref
  useEffect(() => {
    whitelistsRef.current = res.data?.whitelists ?? [];
  }, [JSON.stringify(res.data?.whitelists)]);

  const _handleAttachToCampaign = useCallback(() => {
    const whitelist = res?.data?.whitelist;
    if (!whitelist) return;
    presentModal({
      title: whitelist.name ?? 'Unnamed whitelist',
      subtitle: 'Attach to campaign',
      content: (
        <WhitelistAttachToCampaignModal
          {...{
            ...whitelist,
            whitelist_id: whitelist.id,
          }}
        />
      ),
    });
  }, [!!res?.data?.whitelist]);

  /**
   * Open survey in modal
   */
  const _handleEdit = useCallback((id: string | iWhitelist) => {
    const whitelist = res.data?.whitelist;
    if (!whitelist) return;
    presentModal({
      title: whitelist.name ?? 'Unnamed whitelist',
      subtitle: 'Edit whitelist',
      content: (
        <WhitelistEditModal
          {...{
            ...whitelist,
          }}
        />
      ),
    });
  }, []);

  return {
    whitelist: res?.data?.whitelist ?? null,

    attachToCampaign: _handleAttachToCampaign,
    edit: _handleEdit,
    loading: res.loading,
  };
};

export const useResponses = (id: string): iWhitelistResponsesHook => {
  const res = useFetch(`/campaign/whitelist/responses/${id}`, [id]);

  return {
    responses: res?.data?.results,
    loading: res.loading,
  };
};
export const useSnapshots = (id: string) => {
  const res = useFetch(`/campaign/whitelist/snapshot/${id}`, [id]);

  return {
    users: res?.data?.users ?? [],
    loading: res.loading,
  };
};
