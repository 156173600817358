import React from 'react';

export default function IconOffice(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-office</title>
      <g
        id='icon-office'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20,9 C21.1045695,9 22,9.8954305 22,11 L22,19 C22,20.1045695 21.1045695,21 20,21 L10,21 L10,9 L20,9 Z M9,15 L9,21 L5,21 L5,15 L9,15 Z M20,16 L17,16 L17,19 L20,19 L20,16 Z M15,16 L12,16 L12,19 L15,19 L15,16 Z M20,11 L17,11 L17,14 L20,14 L20,11 Z M15,11 L12,11 L12,14 L15,14 L15,11 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M9,4 L10,4 C11.1045695,4 12,4.8954305 12,6 L12,19 C12,20.1045695 11.1045695,21 10,21 L9,21 L9,15 L5,15 L5,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,6 C2,4.8954305 2.8954305,4 4,4 L5,4 C5,2.8954305 5.8954305,2 7,2 C8.1045695,2 9,2.8954305 9,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
