import React from 'react';

export default function IconStore(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-store</title>
      <g
        id='icon-store'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,8 C19.5522847,8 20,8.44771525 20,9 L20,20 C20,20.5522847 19.5522847,21 19,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,9 C4,8.44771525 4.44771525,8 5,8 L19,8 Z M18,10 L6,10 L6,17 L18,17 L18,10 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M6,12 C3.790861,12 2,10.209139 2,8 C2,7.8447549 2.03614509,7.69164184 2.10557281,7.5527864 L4.10557281,3.5527864 C4.27496482,3.21400238 4.62122794,3 5,3 L19,3 C19.3787721,3 19.7250352,3.21400238 19.8944272,3.5527864 L21.8944272,7.5527864 C21.9638549,7.69164184 22,7.8447549 22,8 C22,10.209139 20.209139,12 18,12 C16.8053075,12 15.7329445,11.4762451 15,10.6458244 C14.2670555,11.4762451 13.1946925,12 12,12 C10.8053075,12 9.73294445,11.4762451 9,10.6458244 C8.26705555,11.4762451 7.19469253,12 6,12 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
