import { FC, createContext, useState, useCallback } from 'react';
import Modal from '../../layout/Modal';

export interface iAction {
  label: string;
  onClick: () => void;
  primary?: boolean;
  link?: boolean;
}

export type tAction = iAction | null | false | undefined;

export type tActions = (tAction | tAction[])[];
export interface iModal {
  title: string;
  subtitle?: string;
  content: any;
}

interface iModalContext {
  content: iModal | null;
  presentModal: (content: iModal) => void;
  dismissModal: () => void;
}
export const ModalContext = createContext<iModalContext>({
  content: null,
  presentModal: () => null,
  dismissModal: () => null,
});

const ModalProvider: FC = ({ children }) => {
  const [content, setContent] = useState<iModal | null>(null);

  const _handleOpen = (content: iModal) => setContent(content);

  const _handleDismiss = useCallback(() => setContent(null), []);

  return (
    <ModalContext.Provider
      value={{
        content,
        presentModal: _handleOpen,
        dismissModal: _handleDismiss,
      }}
    >
      {children}
      {content && <Modal {...content} />}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
