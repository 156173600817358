import React from 'react';

export default function IconStar(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-star</title>
      <g
        id='icon-star'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M9.52641487,16.9302164 C8.79278748,17.3159071 7.93535505,16.692946 8.07546541,15.8760399 L8.54787814,13.1216624 L6.54670573,11.1710014 C5.95318871,10.5924653 6.28069875,9.58449301 7.1009191,9.46530801 L9.86647136,9.06344975 L11.1032639,6.55743429 C11.4700776,5.81418857 12.5299224,5.81418857 12.8967361,6.55743429 L14.1335286,9.06344975 L16.8990809,9.46530801 C17.7193012,9.58449301 18.0468113,10.5924653 17.4532943,11.1710014 L15.4521219,13.1216624 L15.9245346,15.8760399 C16.0646449,16.692946 15.2072125,17.3159071 14.4735851,16.9302164 L12,15.6297757 L9.52641487,16.9302164 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
