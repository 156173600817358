import React from 'react';

export default function IconCloudUpload(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cloud-upload</title>
      <g
        id='icon-cloud-upload'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M17.9998752,14.9725494 C17.992977,14.2139583 17.7001253,13.4574847 17.1213203,12.8786797 L14.1213203,9.87867966 C12.9497475,8.70710678 11.0502525,8.70710678 9.87867966,9.87867966 L6.87867966,12.8786797 C6.29289322,13.4644661 6,14.232233 6,15 C3.790861,15 2,13.209139 2,11 C2,9.13157771 3.28104938,7.56236054 5.01273426,7.12276238 C5.20657536,4.26115113 7.58922761,2 10.5,2 C13.1269746,2 15.3237913,3.84172532 15.8696859,6.3044119 C16.3751862,6.10784518 16.9249988,6 17.5,6 C19.9852814,6 22,8.01471863 22,10.5 C22,12.8163197 20.2499105,14.723884 17.9998752,14.9725494 L17.9998752,14.9725494 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,14.4142136 L11,21 C11,21.5522847 11.4477153,22 12,22 C12.5522847,22 13,21.5522847 13,21 L13,14.4142136 L14.2928932,15.7071068 C14.6834175,16.0976311 15.3165825,16.0976311 15.7071068,15.7071068 C16.0976311,15.3165825 16.0976311,14.6834175 15.7071068,14.2928932 L12.7071068,11.2928932 C12.3165825,10.9023689 11.6834175,10.9023689 11.2928932,11.2928932 L8.29289322,14.2928932 C7.90236893,14.6834175 7.90236893,15.3165825 8.29289322,15.7071068 C8.68341751,16.0976311 9.31658249,16.0976311 9.70710678,15.7071068 L11,14.4142136 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
