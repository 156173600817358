import React from 'react';

export default function IconCode(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-code</title>
      <g
        id='icon-code'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={3}
          y={3}
          width={18}
          height={18}
          rx={2}
        />
        <path
          d='M13.1961161,6.01941932 C13.7376759,6.12773127 14.0888926,6.65455638 13.9805807,7.19611614 L11.9805807,17.1961161 C11.8722687,17.7376759 11.3454436,18.0888926 10.8038839,17.9805807 C10.2623241,17.8722687 9.91110737,17.3454436 10.0194193,16.8038839 L12.0194193,6.80388386 C12.1277313,6.26232411 12.6545564,5.91110737 13.1961161,6.01941932 Z M8.70710678,9.29289322 C9.09763107,9.68341751 9.09763107,10.3165825 8.70710678,10.7071068 L7.41421356,12 L8.70710678,13.2928932 C9.09763107,13.6834175 9.09763107,14.3165825 8.70710678,14.7071068 C8.31658249,15.0976311 7.68341751,15.0976311 7.29289322,14.7071068 L5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L7.29289322,9.29289322 C7.68341751,8.90236893 8.31658249,8.90236893 8.70710678,9.29289322 Z M15.2928932,9.29289322 C15.6834175,8.90236893 16.3165825,8.90236893 16.7071068,9.29289322 L16.7071068,9.29289322 L18.7071068,11.2928932 C19.0976311,11.6834175 19.0976311,12.3165825 18.7071068,12.7071068 L18.7071068,12.7071068 L16.7071068,14.7071068 C16.3165825,15.0976311 15.6834175,15.0976311 15.2928932,14.7071068 C14.9023689,14.3165825 14.9023689,13.6834175 15.2928932,13.2928932 L15.2928932,13.2928932 L16.5857864,12 L15.2928932,10.7071068 C14.9023689,10.3165825 14.9023689,9.68341751 15.2928932,9.29289322 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
