import React from 'react';

export default function IconFilm(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-film</title>
      <g
        id='icon-film'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20,3 C21.1045695,3 22,3.8954305 22,5 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,5 C2,3.8954305 2.8954305,3 4,3 L20,3 Z M6,17 L4,17 L4,19 L6,19 L6,17 Z M20,17 L18,17 L18,19 L20,19 L20,17 Z M6,13 L4,13 L4,15 L6,15 L6,13 Z M20,13 L18,13 L18,15 L20,15 L20,13 Z M6,9 L4,9 L4,11 L6,11 L6,9 Z M20,9 L18,9 L18,11 L20,11 L20,9 Z M6,5 L4,5 L4,7 L6,7 L6,5 Z M20,5 L18,5 L18,7 L20,7 L20,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15,13 C15.5522847,13 16,13.4477153 16,14 L16,18 C16,18.5522847 15.5522847,19 15,19 L9,19 C8.44771525,19 8,18.5522847 8,18 L8,14 C8,13.4477153 8.44771525,13 9,13 L15,13 Z M15,5 C15.5522847,5 16,5.44771525 16,6 L16,10 C16,10.5522847 15.5522847,11 15,11 L9,11 C8.44771525,11 8,10.5522847 8,10 L8,6 C8,5.44771525 8.44771525,5 9,5 L15,5 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
