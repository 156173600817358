import { handleFetch } from '.';
import { iCampaign } from '../../types';

const createCampaign = async (
  name: string,
  token: string,
  userGroupId?: string,
) => {
  const { data } = await handleFetch(
    `/campaign`,
    {
      method: 'PUT',
      body: { name, user_group_id: userGroupId },
    },
    token,
    true,
  );
  return data?.campaign;
};
const deleteCampaign = async (id: string, token: string) => {
  const { data } = await handleFetch(
    `/campaign/${id}`,
    {
      method: 'DELETE',
      body: {},
    },
    token,
    true,
  );
  return data?.campaign;
};

/**
 * Update user group
 * @param id id of the group
 * @param group the new group body
 * @param token user authorisation token
 * @returns the new usergroup
 */
const updateCampaign = async (
  id: string,
  campaign: iCampaign,
  token: string,
) => {
  const { data } = await handleFetch(
    `/campaign/${id}`,
    {
      method: 'PATCH',
      body: campaign,
    },
    token,
    true,
  );
  return data;
};

// /**
//  * Deletes user group
//  * @param id id of the group
//  * @param token user authorisation token
//  * @returns null
//  */
// const deleteUserGroup = async (id: string, token: string) => {
//   const { data } = await handleFetch(
//     `/user/group/${id}`,
//     {
//       method: 'DELETE',
//       body: {},
//     },
//     token,
//     true,
//   );
//   return data;
// };

const exported = { createCampaign, deleteCampaign, updateCampaign };
export default exported;
