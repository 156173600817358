import React from 'react';

export default function IconInboxUpload(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-inbox-upload</title>
      <g
        id='icon-inbox-upload'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M8,4 C8,4.55228475 7.55228475,5 7,5 L5,5 L5,5 L5,15 L7,15 C8.1045695,15 9,15.8954305 9,17 C9,18.1045695 9.8954305,19 11,19 L13,19 C14.1045695,19 15,18.1045695 15,17 C15,15.8954305 15.8954305,15 17,15 L19,15 L19,5 L17,5 C16.4477153,5 16,4.55228475 16,4 C16,3.44771525 16.4477153,3 17,3 L19,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L7,3 C7.55228475,3 8,3.44771525 8,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,6.41421356 L11,13 C11,13.5522847 11.4477153,14 12,14 C12.5522847,14 13,13.5522847 13,13 L13,6.41421356 L14.2928932,7.70710678 C14.6834175,8.09763107 15.3165825,8.09763107 15.7071068,7.70710678 C16.0976311,7.31658249 16.0976311,6.68341751 15.7071068,6.29289322 L12.7071068,3.29289322 C12.3165825,2.90236893 11.6834175,2.90236893 11.2928932,3.29289322 L8.29289322,6.29289322 C7.90236893,6.68341751 7.90236893,7.31658249 8.29289322,7.70710678 C8.68341751,8.09763107 9.31658249,8.09763107 9.70710678,7.70710678 L11,6.41421356 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
