import React from 'react';

export default function IconUmbrella(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-umbrella</title>
      <g
        id='icon-umbrella'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,1 C12.5522847,1 13,1.44771525 13,2 L13.0010997,3.04948449 C18.0538953,3.55171825 22,7.81502805 22,13 C22,14.3333333 20,14.3333333 20,13 C20,11.8954305 19.1045695,11 18,11 C16.8954305,11 16,11.8954305 16,13 C16,14.3333333 14,14.3333333 14,13 C14,11.8954305 13.1045695,11 12,11 C10.8954305,11 10,11.8954305 10,13 C10,14.3333333 8,14.3333333 8,13 C8,11.8954305 7.1045695,11 6,11 C4.8954305,11 4,11.8954305 4,13 C4,14.3333333 2,14.3333333 2,13 C2,7.81468642 5.94662476,3.55115644 10.9998991,3.04938526 L11,2 C11,1.44771525 11.4477153,1 12,1 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,14 C11,13.4477153 11.4477153,13 12,13 C12.5522847,13 13,13.4477153 13,14 L13,19 C13,20.6568542 11.6568542,22 10,22 C8.34314575,22 7,20.6568542 7,19 C7,18.4477153 7.44771525,18 8,18 C8.55228475,18 9,18.4477153 9,19 C9,19.5522847 9.44771525,20 10,20 C10.5522847,20 11,19.5522847 11,19 L11,14 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
