import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import activateStyles from '../styles/pages/activate.module.scss';
import { AuthSkeleton } from '../layout';

const Activate: FC = () => {
  const { t } = useTranslation('activate');

  return (
    <AuthSkeleton
      pageTitle={t('page_title')}
      title={t('meta_title')}
      subtitle={t('meta_subtitle')}
    >
      <main className={activateStyles.wrapper}>
        <img draggable={false} src='/images/logo/triyit-trademark.png' alt='' />
        <h1>
          {t('title').replace(
            '__FIRSTNAME__',
            sessionStorage.getItem('firstname') ?? 'pal',
          )}
        </h1>
        <h2>{t('subtitle')}</h2>
        <ol>
          <li>{t('step_1')}</li>
          <li>{t('step_2')}</li>
        </ol>
        <hr />
        <h3>{t('warning')}</h3>
        <p>{t('spam')}</p>
      </main>
    </AuthSkeleton>
  );
};

export default Activate;
