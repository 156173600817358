import React from 'react';

export default function IconCheveronDown(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cheveron-down</title>
      <g
        id='icon-cheveron-down'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15.2928932,10.2928932 C15.6834175,9.90236893 16.3165825,9.90236893 16.7071068,10.2928932 C17.0976311,10.6834175 17.0976311,11.3165825 16.7071068,11.7071068 L12.7071068,15.7071068 C12.3165825,16.0976311 11.6834175,16.0976311 11.2928932,15.7071068 L7.29289322,11.7071068 C6.90236893,11.3165825 6.90236893,10.6834175 7.29289322,10.2928932 C7.68341751,9.90236893 8.31658249,9.90236893 8.70710678,10.2928932 L12,13.5857864 L15.2928932,10.2928932 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
