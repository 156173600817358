import { useContext } from 'react';
import { UserContext } from '../providers/User';

export const useUser = () => {
  const { user, setUser } = useContext(UserContext);

  const _handleParseString = (str: string) => {
    const values = Object.entries(user ?? {});
    values.forEach(([key, val]) => {
      str = str.replace(new RegExp(`{{${key.toUpperCase()}}}`, 'g'), val);
    });
    return str;
  };

  return {
    parseString: _handleParseString,
    user: !user
      ? null
      : { ...user, name: `${user.firstname} ${user.lastname}`.trim() },
    setUser,
  };
};
