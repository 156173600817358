import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { Button, Input, Segment, Select } from '../components';
import { useGroups, useModal, useSurveys } from '../hooks';
import Actions from '../layout/Modal/Actions';
import ModalBody from '../layout/Modal/Body';

interface iWhitelistEditProps {
  name?: string;
  user_group_id?: string;
  user_ids?: string[];
  survey_id?: string;
  is_active?: boolean;
  field_resolver_address?: string[];
  field_resolver_birthdate?: string[];
  field_resolver_mobile?: string[];
  field_resolver_postcode?: string[];
}

const WhitelistEdit: FC<iWhitelistEditProps> = ({
  name,
  user_ids,
  user_group_id,
  survey_id,
  is_active,
  field_resolver_address,
  field_resolver_birthdate,
  field_resolver_mobile,
  field_resolver_postcode,
  ...e
}) => {
  const { groups } = useGroups();
  const { surveys } = useSurveys();
  const { dismissModal } = useModal();

  console.log(e);

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      survey_id: survey_id ?? '',
      user_ids: (user_ids ?? []).join('\n'),
      user_group_id,
    },

    onSubmit: (values) => {
      const confirmed = !window.confirm(
        'Are you sure you want to send the confirmation email to these users? This action can not be undone.',
      );
      if (confirmed) return;

      // todo: add network layer
      dismissModal();
    },
  });

  /**
   * Update regular text fields
   */
  const _handleChange = useCallback(
    (val: string, name?: string) => {
      if (name === 'user_group_id') {
        const group = groups?.find((g) => g.id === val);
        formik.setFieldValue('user_ids', group?.user_ids.join('\n') ?? '');
      } else if (name === 'user_ids') formik.setFieldValue('user_group_id', '');

      formik.setFieldValue(name ?? '', val);
    },
    [JSON.stringify(groups)],
  );

  const [groupOptions, surveyOptions] = useMemo(() => {
    // Generate group options
    const groupOptions = [
      { label: 'No user group selected', value: null },
      ...groups.map((group) => ({
        label: group.name,
        value: group.id,
      })),
    ];

    // generate survey options
    const surveyOptions = [
      { label: 'Select a survey', value: null, disabled: true },
      ...surveys
        ?.filter((survey) => !!survey.typeform_id)
        .map((survey) => ({ label: survey.title, value: survey.id })),
    ];

    return [groupOptions, surveyOptions];
  }, [groups.length, surveys.length]);

  return (
    <>
      <ModalBody>
        <Segment title='Name of whitelist'>
          <Input
            onChange={_handleChange}
            name='name'
            value={formik.values.name}
            placeholder='Coca cola products whitelist'
          />
        </Segment>
        {!(user_group_id || is_active) && (
          <Segment title='Select user group'>
            <Select
              options={groupOptions}
              onChange={_handleChange}
              name='user_group_id'
              value={formik.values.user_group_id ?? null}
            />
          </Segment>
        )}
        {!is_active && (
          <>
            <Segment
              title={`Target users (${
                formik.values.user_ids.split('\n').filter((_) => _.trim())
                  .length
              })`}
            >
              <Input
                textarea
                onChange={_handleChange}
                name='user_ids'
                value={formik.values.user_ids}
                disabled={!!user_ids?.length}
              />
            </Segment>
            <Segment title='Select survey (Only typeform-based surveys are supported)'>
              <Select
                options={surveyOptions}
                onChange={_handleChange}
                name='survey_id'
                value={formik.values.survey_id ?? null}
              />
            </Segment>
          </>
        )}
        <Segment title='Birthdate resolvers'>
          <Input value={field_resolver_birthdate?.join('\n')} textarea />
        </Segment>
        <Segment title='Mobile resolvers'>
          <Input value={field_resolver_mobile?.join('\n')} textarea />
        </Segment>

        <Segment title='Postcode resolvers'>
          <Input value={field_resolver_postcode?.join('\n')} textarea />
        </Segment>
        <Segment title='Address resolvers'>
          <Input value={field_resolver_address?.join('\n')} textarea />
        </Segment>
      </ModalBody>
      <Actions>
        <Button onClick={dismissModal}>Cancel</Button>
        <Button primary onClick={formik.handleSubmit}>
          {is_active ? 'Save' : 'Send confirmation'}
        </Button>
      </Actions>
    </>
  );
};

export default WhitelistEdit;
