import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthSkeleton, ForgotForm } from '../layout';

const Forgot: FC = () => {
  const { t } = useTranslation('forgot');

  return (
    <AuthSkeleton
      pageTitle={t('page_title')}
      title={t('meta_title')}
      subtitle={t('meta_subtitle')}
    >
      <ForgotForm />
    </AuthSkeleton>
  );
};

export default Forgot;
