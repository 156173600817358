import React from 'react';

export default function IconDupicate(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-dupicate</title>
      <g
        id='icon-dupicate'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <rect
          id='secondary'
          fill='currentColor'
          x={3}
          y={3}
          width={14}
          height={14}
          rx={2}
        />
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={7}
          y={7}
          width={14}
          height={14}
          rx={2}
        />
      </g>
    </svg>
  );
}
