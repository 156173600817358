import React from 'react';

export default function IconLink(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-link</title>
      <g
        id='icon-link'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19.4814128,13.0288722 C19.4755055,13.0193797 19.4697338,13.0097552 19.4641016,13 C19.1879592,12.5217074 19.3518344,11.910117 19.830127,11.6339746 C20.3084196,11.3578322 20.92001,11.5217074 21.1961524,12 C21.2006188,12.0077359 21.20497,12.0155068 21.2092067,12.0233105 L21.2103481,12.0226583 C21.71279,12.9000387 22,13.9164786 22,15 C22,18.3137085 19.3137085,21 16,21 L12,21 C8.6862915,21 6,18.3137085 6,15 C6,11.6862915 8.6862915,9 12,9 L13,9 C13.5522847,9 14,9.44771525 14,10 C14,10.5522847 13.5522847,11 13,11 L12,11 C9.790861,11 8,12.790861 8,15 C8,17.209139 9.790861,19 12,19 L16,19 C18.209139,19 20,17.209139 20,15 C20,14.2833125 19.8115156,13.6106478 19.4814128,13.0288722 L19.4814128,13.0288722 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
        <path
          d='M4.51858717,10.9711278 C4.52449447,10.9806203 4.5302662,10.9902448 4.53589838,11 C4.81204076,11.4782926 4.6481656,12.089883 4.16987298,12.3660254 C3.69158036,12.6421678 3.07998995,12.4782926 2.80384758,12 C2.79938123,11.9922641 2.79502998,11.9844932 2.79079332,11.9766895 L2.78965195,11.9773417 C2.28720996,11.0999613 2,10.0835214 2,9 C2,5.6862915 4.6862915,3 8,3 L12,3 C15.3137085,3 18,5.6862915 18,9 C18,12.3137085 15.3137085,15 12,15 L11,15 C10.4477153,15 10,14.5522847 10,14 C10,13.4477153 10.4477153,13 11,13 L12,13 C14.209139,13 16,11.209139 16,9 C16,6.790861 14.209139,5 12,5 L8,5 C5.790861,5 4,6.790861 4,9 C4,9.71668753 4.18848443,10.3893522 4.51858717,10.9711278 L4.51858717,10.9711278 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
