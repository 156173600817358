import React from 'react';

export default function IconStop(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-stop</title>
      <g
        id='icon-stop'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <rect
          id='secondary'
          fill='currentColor'
          x='8.5'
          y='8.5'
          width={7}
          height={7}
          rx={1}
        />
      </g>
    </svg>
  );
}
