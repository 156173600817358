import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { WhitelistWizardModal } from '.';
import { Input, Query, Segment } from '../components';
import { useModal } from '../hooks';
import Actions from '../layout/Modal/Actions';
import ModalBody from '../layout/Modal/Body';
import { iUserGroup } from '../types';

interface iUserGroupProps extends iUserGroup {
  onUpdate: (id: string, body: iUserGroup) => void;
  onDelete: (id: string) => void;
}

const UserGroup: FC<iUserGroupProps> = ({
  name,
  id,
  user_ids,
  query,
  onUpdate,
  onDelete,
}) => {
  const { dismissModal, presentModal } = useModal();

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      user_ids: (user_ids ?? []).join('\n'),
      query: query ?? '',
    },
    onSubmit: (values) => {
      onUpdate(id, {
        id,
        ...values,
        user_ids: values.user_ids.split('\n').filter((u) => u.length > 0),
      });
      dismissModal();
    },
  });

  /**
   * Update regular text fields
   */
  const _handleChange = useCallback((val: string, name?: string) => {
    formik.setFieldValue(name ?? '', val);
  }, []);

  /**
   * Update group SQL query
   */
  const _handleQueryChange = useCallback((code: string) => {
    formik.setFieldValue('query', code);
  }, []);

  /**
   * Asks for permission and then deletes the user group
   */
  const _handleDelete = useCallback(() => {
    if (!window.confirm('Are you sure you want to delete this group?')) return;
    onDelete(id);
    dismissModal();
  }, []);

  const _handleConfirm = useCallback(() => {
    presentModal({
      title: 'Send confirmation email',
      subtitle: 'Whitelist wizard',
      content: <WhitelistWizardModal {...{ user_ids, user_group_id: id }} />,
    });
  }, []);

  /**
   * Generate modal actions
   */
  const actions = useMemo(() => {
    return [
      { label: 'Cancel', onClick: dismissModal, link: true },
      [
        { label: 'Delete', onClick: _handleDelete },
        { label: 'Confirm users', onClick: _handleConfirm },
        { label: 'Update user list', onClick: () => {} },
        { label: 'Save', primary: true, onClick: formik.handleSubmit },
      ],
    ];
  }, [formik]);

  return (
    <>
      <ModalBody>
        <Segment title='Name of user group'>
          <Input
            onChange={_handleChange}
            name='name'
            value={formik.values.name}
          />
        </Segment>
        <Segment title='User query'>
          <Query
            code={formik.values.query ?? ''}
            setCode={_handleQueryChange}
          />
        </Segment>
        <Segment title='Users in the group'>
          <Input
            textarea
            onChange={_handleChange}
            name='user_ids'
            value={formik.values.user_ids}
          />
        </Segment>
      </ModalBody>
      <Actions actions={actions} />
    </>
  );
};

export default UserGroup;
