import React from 'react';

export default function IconBug(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-bug</title>
      <g
        id='icon-bug'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M9,17 L9,19 L8,19 C6.8954305,19 6,19.8954305 6,21 C6,21.5522847 5.55228475,22 5,22 C4.44771525,22 4,21.5522847 4,21 C4,18.790861 5.790861,17 8,17 L9,17 Z M16,17 C18.209139,17 20,18.790861 20,21 C20,21.5522847 19.5522847,22 19,22 C18.4477153,22 18,21.5522847 18,21 C18,19.8954305 17.1045695,19 16,19 L15,19 L15,17 L16,17 Z M7,14 C7.55228475,14 8,14.4477153 8,15 C8,15.5522847 7.55228475,16 7,16 L3,16 C2.44771525,16 2,15.5522847 2,15 C2,14.4477153 2.44771525,14 3,14 L7,14 Z M21,14 C21.5522847,14 22,14.4477153 22,15 C22,15.5522847 21.5522847,16 21,16 L17,16 C16.4477153,16 16,15.5522847 16,15 C16,14.4477153 16.4477153,14 17,14 L21,14 Z M4,8 C4.55228475,8 5,8.44771525 5,9 C5,10.1045695 5.8954305,11 7,11 L8,11 L8,13 L7,13 C4.790861,13 3,11.209139 3,9 C3,8.44771525 3.44771525,8 4,8 Z M20,8 C20.5522847,8 21,8.44771525 21,9 C21,11.209139 19.209139,13 17,13 L16,13 L16,11 L17,11 C18.1045695,11 19,10.1045695 19,9 C19,8.44771525 19.4477153,8 20,8 Z M12,2 C13.4398461,2 14.7852189,2.61462842 15.7263763,3.66617243 C16.8794244,4.9544595 15.9650379,7 14.2361065,7 L9.76389349,7 C8.03496214,7 7.12057556,4.9544595 8.27362371,3.66617243 C9.21478108,2.61462842 10.5601539,2 12,2 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M13,19.5839563 L13,13 C13,12.4477153 12.5522847,12 12,12 C11.4477153,12 11,12.4477153 11,13 L11,19.5839563 C11,20.3048976 10.260265,20.7889536 9.5996284,20.5003092 C7.43142995,19.552983 6,17.4075642 6,15 L6,12 C6,9.790861 7.790861,8 10,8 L14,8 C16.209139,8 18,9.790861 18,12 L18,15 C18,17.4075642 16.5685701,19.552983 14.4003716,20.5003092 C13.739735,20.7889536 13,20.3048976 13,19.5839563 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
