import React from 'react';

export default function IconSend(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-send</title>
      <g
        id='icon-send'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group' transform='translate(1.000000, 2.000000)'>
          <path
            d='M12,1 L12,16.9716797 L11,18.0943175 L2.40613847,19.9138115 C1.54880842,20.2948471 0.686000904,19.3919302 1.10557281,18.5527864 L10.1055728,0.552786405 C10.2898349,0.184262135 10.6449175,0 11,0 L12,1 Z'
            id='Path'
            fill='currentColor'
            opacity='0.4'
          />
          <path
            d='M11,0 C11.3550825,0 11.7101651,0.184262135 11.8944272,0.552786405 L20.8944272,18.5527864 C21.3139991,19.3919302 20.4511916,20.2948471 19.5938615,19.9138115 L11,18.0943175 L11,0 Z'
            id='secondary'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </svg>
  );
}
