import { FC } from 'react';
import { Button } from '../../components';
import styles from '../../styles/pages/survey-start.module.scss';
import md from 'marked';
import { useUser } from '../../hooks';
import { SurveySkeleton } from '../../layout';
import { useSurveyContext } from '../../hooks/survey';

interface iStartPageProps {}

const StartPage: FC<iStartPageProps> = () => {
  const { parseString } = useUser();
  const { survey, startSurvey } = useSurveyContext();

  return (
    <SurveySkeleton title={survey?.title || ''}>
      <div className={styles.wrapper}>
        <div
          dangerouslySetInnerHTML={{
            __html: md(parseString(survey?.welcome ?? '')),
          }}
        ></div>
        <Button primary onClick={startSurvey}>
          Let's do it!
        </Button>
      </div>
    </SurveySkeleton>
  );
};

export default StartPage;
