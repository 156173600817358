import React from 'react';

export default function IconAttach(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-attach</title>
      <g
        id='icon-attach'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20.1231782,11.9542126 L13.5358189,18.5368534 C11.5829441,20.4897282 8.41711921,20.4897282 6.46449776,18.5371067 C4.5118763,16.5844852 4.5118763,13.4186604 6.46449776,11.4660389 L13.0473919,4.87842628 C14.2187115,3.70710678 16.121352,3.70710678 17.2929249,4.87867966 C18.4644978,6.05025253 18.4644978,7.95289307 17.2929249,9.12446594 L10.7072229,15.7085951 C10.3166142,16.0992039 9.68344917,16.0992039 9.29292488,15.7086796 C8.90240059,15.3181553 8.90240059,14.6849903 9.29292488,14.294466 L15.877223,7.70859513 C16.2677006,7.3180242 16.267625,6.68485923 15.8770541,6.29438158 C15.4864831,5.90390393 14.8533182,5.90397954 14.4628405,6.29455047 L7.87862688,12.8803369 C6.70713844,14.0518253 6.70713844,15.9513203 7.87871132,17.1228931 C9.05028419,18.294466 10.9497791,18.294466 12.121352,17.1228931 L18.707054,10.5387639 C20.6597599,8.58605805 20.6597599,5.41708755 18.7071384,3.46446609 C16.754517,1.51184464 13.5855465,1.51184464 11.632925,3.46446609 L5.05003091,10.0520787 C2.31661415,12.7854954 2.31661415,17.2176502 5.05028419,19.9513203 C7.78395424,22.6849903 12.2161091,22.6849903 14.9497791,19.9513203 L21.536885,13.3689327 C21.9275492,12.9785484 21.927776,12.3453834 21.5373917,11.9547192 C21.1470073,11.5640551 20.5138424,11.5638282 20.1231782,11.9542126 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
