import { FC } from 'react';
import { Helmet } from 'react-helmet';

interface iTitleProps {
  title: string;
}

const Title: FC<iTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>
        {title}
        {title ? ' | ' : ''}Triyit
      </title>
    </Helmet>
  );
};

export default Title;
