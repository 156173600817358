import React from 'react';

export default function IconWidgetAdd(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-widget-add</title>
      <g
        id='icon-widget-add'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M9,13 C10.1045695,13 11,13.8954305 11,15 L11,19 C11,20.1045695 10.1045695,21 9,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,15 C3,13.8954305 3.8954305,13 5,13 L9,13 Z M19,13 C20.1045695,13 21,13.8954305 21,15 L21,19 C21,20.1045695 20.1045695,21 19,21 L15,21 C13.8954305,21 13,20.1045695 13,19 L13,15 C13,13.8954305 13.8954305,13 15,13 L19,13 Z M9,3 C10.1045695,3 11,3.8954305 11,5 L11,9 C11,10.1045695 10.1045695,11 9,11 L5,11 C3.8954305,11 3,10.1045695 3,9 L3,5 C3,3.8954305 3.8954305,3 5,3 L9,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M18,6 L20,6 C20.5522847,6 21,6.44771525 21,7 C21,7.55228475 20.5522847,8 20,8 L18,8 L18,10 C18,10.5522847 17.5522847,11 17,11 C16.4477153,11 16,10.5522847 16,10 L16,8 L14,8 C13.4477153,8 13,7.55228475 13,7 C13,6.44771525 13.4477153,6 14,6 L16,6 L16,4 C16,3.44771525 16.4477153,3 17,3 C17.5522847,3 18,3.44771525 18,4 L18,6 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
