import React from 'react';

export default function IconClose(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-close</title>
      <g
        id='icon-close'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15.7781746,14.363961 C16.1686989,14.7544853 16.1686989,15.3876503 15.7781746,15.7781746 C15.3876503,16.1686989 14.7544853,16.1686989 14.363961,15.7781746 L11.5355339,12.9497475 L8.70710678,15.7781746 C8.31658249,16.1686989 7.68341751,16.1686989 7.29289322,15.7781746 C6.90236893,15.3876503 6.90236893,14.7544853 7.29289322,14.363961 L10.1213203,11.5355339 L7.29289322,8.70710678 C6.90236893,8.31658249 6.90236893,7.68341751 7.29289322,7.29289322 C7.68341751,6.90236893 8.31658249,6.90236893 8.70710678,7.29289322 L11.5355339,10.1213203 L14.363961,7.29289322 C14.7544853,6.90236893 15.3876503,6.90236893 15.7781746,7.29289322 C16.1686989,7.68341751 16.1686989,8.31658249 15.7781746,8.70710678 L12.9497475,11.5355339 L15.7781746,14.363961 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
