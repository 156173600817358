import React from 'react';

export default function IconTarget(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-target</title>
      <g
        id='icon-target'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,2 C13.128666,2 14.2136593,2.18698495 15.2256922,2.53166712 L14.8786797,2.87867966 C14.4902329,3.26712639 14.2193123,3.7511298 14.0888032,4.2754397 C13.4229223,4.09581705 12.7226508,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,11.2773492 19.904183,10.5770777 19.7245603,9.91119684 C20.2488702,9.7806877 20.7328736,9.50976707 21.1213203,9.12132034 L21.4683329,8.77430781 C21.813015,9.78634071 22,10.871334 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,6 C12.537478,6 13.0584497,6.07067176 13.5541296,6.20322972 L11.7496524,8.00770695 C9.65713403,8.13692709 8,9.87494135 8,12 C8,14.209139 9.790861,16 12,16 C14.1250587,16 15.8630729,14.342866 15.9922931,12.2503476 L17.7967703,10.4458704 C17.9293282,10.9415503 18,11.462522 18,12 C18,15.3137085 15.3137085,18 12,18 C8.6862915,18 6,15.3137085 6,12 C6,8.6862915 8.6862915,6 12,6 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M16,6.58578644 L16,5 C16,4.73478351 16.1053568,4.4804296 16.2928932,4.29289322 L18.2928932,2.29289322 C18.9228581,1.66292836 20,2.10909515 20,3 L20,4 L21,4 C21.8909049,4 22.3370716,5.07714192 21.7071068,5.70710678 L19.7071068,7.70710678 C19.5195704,7.89464316 19.2652165,8 19,8 L17.4142136,8 L12.7071068,12.7071068 C12.3165825,13.0976311 11.6834175,13.0976311 11.2928932,12.7071068 C10.9023689,12.3165825 10.9023689,11.6834175 11.2928932,11.2928932 L16,6.58578644 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
