import { FC } from 'react';
import styles from '../../styles/layout/modal.module.scss';

interface iModalBodyProps {}

const ModalBody: FC<iModalBodyProps> = ({ children }) => {
  return <main className={styles.body}>{children}</main>;
};

export default ModalBody;
