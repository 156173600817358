import { useCallback, useEffect, useRef, useState } from 'react';
import { useModal, useUser } from '.';
import { network } from '../core';
import { SurveyModal } from '../modals';
import { iSurvey, iSurveyInput } from '../types';

interface iSurveyHook {
  surveys: iSurvey[];
  loading: boolean;
  openModal: (survey: string | iSurveyInput) => void;
  createSurvey: () => void;
}

export const useSurveys = (): iSurveyHook => {
  const { user } = useUser();
  const [surveys, setSurveys] = useState<iSurvey[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const surveysRef = useRef<iSurvey[]>([]);

  const { presentModal } = useModal();

  /**
   * Open survey in modal
   */
  const _handleOpenGroup = useCallback(
    (id: string | iSurveyInput) => {
      let survey: string | iSurvey | undefined;
      if (typeof id === 'string') {
        survey = surveys.find((s) => s.id === id);
      }

      if (!survey || typeof survey === 'string') return;

      presentModal({
        title: survey.title,
        subtitle: 'Edit survey',
        content: (
          <SurveyModal
            {...{
              onUpdate: _handleUpdate,
              onDelete: _handleDelete,
              ...survey,
            }}
          />
        ),
      });
    },
    [JSON.stringify(surveys)],
  );

  const _handleCreate = () => {
    presentModal({
      title: 'New survey',
      subtitle: 'Edit survey',
      content: (
        <SurveyModal
          {...{
            onCreate: _handleNew,
            onDelete: _handleDelete,
          }}
        />
      ),
    });
    // const _surveys = [...surveysRef.current];
    // const index = _surveys.findIndex((survey) => survey.id === id);
    // _surveys[index] = { ..._surveys[index], ...body };
    // setSurveys(_surveys);

    // network.survey.updateSurvey(id, body, user?.token ?? '');
  };

  const _handleSetSurvey = (id: string, survey: iSurveyInput) => {
    const _surveys = [...surveysRef.current];
    const index = _surveys.findIndex((survey) => survey.id === id);
    _surveys[index] = { ..._surveys[index], ...survey };

    setSurveys(_surveys);
  };
  const _handleUpdate = (id: string, body: iSurveyInput) => {
    _handleSetSurvey(id, body);

    network.survey.updateSurvey(id, body, user?.token ?? '');
  };
  const _handleNew = (body: iSurveyInput) => {
    const id = Date.now().toString();
    const _surveys = [...surveysRef.current];
    _surveys.unshift({ ...body, id } as iSurvey);
    setSurveys(_surveys);

    network.survey.createSurvey(body, user?.token ?? '').then((data) => {
      if (data.survey.id) _handleSetSurvey(id, data.survey);
    });
  };
  const _handleDelete = (id: string) => {
    const _surveys = [...surveysRef.current];
    const index = _surveys.findIndex((survey) => survey.id === id);
    _surveys.splice(index, 1);
    setSurveys(_surveys);

    network.survey.deleteSurvey(id, user?.token ?? '');
  };

  useEffect(() => {
    network.survey.getAll(user?.token ?? '').then((res) => {
      setLoading(false);
      setSurveys(res?.surveys ?? []);
    });
  }, []);
  useEffect(() => {
    surveysRef.current = surveys;
  }, [JSON.stringify(surveys)]);

  return {
    surveys,
    loading,
    createSurvey: _handleCreate,
    openModal: _handleOpenGroup,
  };
};
