import React from 'react';

export default function IconInterface(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-interface</title>
      <g
        id='icon-interface'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20,2 C21.1045695,2 22,2.8954305 22,4 L22,17 C22,18.1045695 21.1045695,19 20,19 L4,19 C2.8954305,19 2,18.1045695 2,17 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 Z M20,6 L4,6 L4,17 L20,17 L20,6 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12.3511234,11.0636708 L20.3511234,14.0636708 C21.2699362,14.4082256 21.1945291,15.7321441 20.2425356,15.9701425 L17.9547491,16.5407491 L21.7071068,20.2928932 C22.0976311,20.6834175 22.0976311,21.3165825 21.7071068,21.7071068 C21.3165825,22.0976311 20.6834175,22.0976311 20.2928932,21.7071068 L16.5407491,17.9547491 L15.9701425,20.2425356 C15.7321441,21.1945291 14.4082256,21.2699362 14.0636708,20.3511234 L11.0636708,12.3511234 C10.7622134,11.5472371 11.5472371,10.7622134 12.3511234,11.0636708 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
