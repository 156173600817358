import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Checkbox, Input } from '../components';
import network from '../core/network';
import styles from '../styles/layout/authform.module.scss';
import * as yup from 'yup';

const RegisterForm: FC = () => {
  const { t } = useTranslation('register-form');
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      firstname: '',
      lastname: '',
      password: '',
      password_verify: '',
      over18: false,
      mainland: false,
      terms: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Email must be a valid email')
        .required('Please fill this field'),
      firstname: yup.string().required('Please fill this field'),
      lastname: yup.string().required('Please fill this field'),
      password: yup.string().required('Please fill this field'),
      password_verify: yup.string().required('Please fill this field'),
    }),
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      if (values.password !== values.password_verify) {
        await formik.setFieldValue('password', '');
        await formik.setFieldValue('password_verify', '');
        await formik.setFieldError('password', 'Passwords do not match');
      } else {
        const res = await network.user.register(
          values.email,
          values.password,
          values.firstname,
          values.lastname,
        );

        if (res.message === 'Success') {
          sessionStorage.setItem('firstname', values.firstname);
          history.replace('/activate');
        } else if ((res.error = 'EMAIL_TAKEN')) {
          await formik.setFieldValue('email', '');
          await formik.setFieldValue('password', '');
          await formik.setFieldValue('password_verify', '');
          await formik.setFieldError('email', 'Email is already in use');
        }
      }
    },
  });

  const _handleChange = (value: string | boolean, name?: string) => {
    formik.setFieldValue(name ?? '', value);
  };
  return (
    <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <img src='/images/logo/triyit-trademark.png' alt='' />
      <h1>{t('title')}</h1>
      <h2>{t('subtitle')}</h2>
      <Input
        className={styles.input}
        value={formik.values.email}
        onChange={_handleChange}
        name='email'
        error={formik.errors.email}
        placeholder='Email address'
      />
      <Input
        className={styles.input}
        value={formik.values.firstname}
        onChange={_handleChange}
        name='firstname'
        error={formik.errors.firstname}
        placeholder='First name'
      />
      <Input
        className={styles.input}
        value={formik.values.lastname}
        onChange={_handleChange}
        name='lastname'
        error={formik.errors.lastname}
        placeholder='Last name'
      />
      <Input
        className={styles.input}
        type='password'
        value={formik.values.password}
        onChange={_handleChange}
        name='password'
        error={formik.errors.password}
        placeholder='Password'
      />
      <Input
        className={styles.input}
        type='password'
        value={formik.values.password_verify}
        onChange={_handleChange}
        name='password_verify'
        error={formik.errors.password_verify}
        placeholder='Repeat Password'
      />
      <Checkbox
        name='over18'
        onChange={_handleChange}
        value={Boolean(formik.values.password_verify)}
      >
        I am over 18 years old <strong>(18+ only)</strong>
      </Checkbox>
      <Checkbox
        name='mainland'
        onChange={_handleChange}
        value={Boolean(formik.values.mainland)}
      >
        I live in the mainland UK <strong>(Mainland UK ONLY)</strong>
      </Checkbox>
      <Checkbox
        name='terms'
        onChange={_handleChange}
        value={Boolean(formik.values.terms)}
      >
        I have read and agree to the{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://triyit.co.uk/terms-and-conditions'
        >
          terms and privacy policy
        </a>
      </Checkbox>
      <div className={styles.row}>
        <Link to='/login'>Sign In</Link>

        <Button onClick={formik.handleSubmit} primary type='button'>
          Register
        </Button>
      </div>
    </form>
  );
};

export default RegisterForm;
