import { FC, useMemo } from 'react';
import { Campaign, Segment } from '..';
import { iCampaign } from '../../types';

interface iCampaignStatusProps {
  status: string;
  campaigns: iCampaign[] | null;
  openModal: (id: string | iCampaign) => void;
}

const CampaignStatus: FC<iCampaignStatusProps> = ({
  status,
  campaigns,
  openModal,
}) => {
  const statusCampaigns = useMemo(() => {
    return campaigns?.filter((c) => c.status === status) || [];
  }, [JSON.stringify(campaigns)]);

  //   todo: create campaign component
  return (
    <Segment collapsible title={`${status} (${statusCampaigns.length})`}>
      {statusCampaigns.map((campaign: iCampaign) => (
        <Campaign
          {...campaign}
          onModal={openModal as unknown as any}
          key={campaign.id}
        />
      ))}
    </Segment>
  );
};

export default CampaignStatus;
