import React from 'react';

export default function IconInboxFull(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-inbox-full</title>
      <g
        id='icon-inbox-full'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M19,5 L5,5 L5,15 L7,15 C8.1045695,15 9,15.8954305 9,17 C9,18.1045695 9.8954305,19 11,19 L13,19 C14.1045695,19 15,18.1045695 15,17 C15,15.8954305 15.8954305,15 17,15 L19,15 L19,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M16,11 C16.5522847,11 17,11.4477153 17,12 C17,12.5522847 16.5522847,13 16,13 L8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 L16,11 Z M16,7 C16.5522847,7 17,7.44771525 17,8 C17,8.55228475 16.5522847,9 16,9 L8,9 C7.44771525,9 7,8.55228475 7,8 C7,7.44771525 7.44771525,7 8,7 L16,7 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
