const translations = {
  alert: {
    delete: 'Are your sure you want to delete this item? It can not be undone.',
    initialisation:
      'Are your sure you want to initialise this campaign? It can not be undone and it can not be edited anymore',
    start: 'Are your sure you want to manually start this campaign?',
  },
  common: {
    email: 'Email address',
    password: 'Password',
    'password-verify': 'Verify your password',
    firtname: 'First name',
    lastname: 'Last name',
    login: 'Sign In',
    submit: 'Submit',
    reset: 'Reset',
    save: 'Save',
    delete: 'Delete',
    cancel: 'Cancel',
    initiate: 'Initiate',
    start: 'Start manually',
  },
  login: {
    page_title: 'Log in',
    title: 'Log in',
    meta_title: 'Meet Triyit…',
    meta_subtitle:
      'The Product Discovery Club that helps you discover new favourite products and brands - at home, for free!',
    meta_banner_alt: 'Unboxing a Triyit box',
  },
  register: {
    page_title: 'Sign up',
    title: 'Log in',
    meta_title: 'Meet Triyit…',
    meta_subtitle:
      'The Product Discovery Club that helps you discover new favourite products and brands - at home, for free!',
    meta_banner_alt: 'Unboxing a Triyit box',
  },
  forgot: {
    page_title: 'Forgot password',
    title: 'Forgot your password?',
    meta_title: 'Meet Triyit…',
    meta_subtitle:
      'The Product Discovery Club that helps you discover new favourite products and brands - at home, for free!',
    meta_banner_alt: 'Unboxing a Triyit box',
  },
  reset: {
    page_title: 'Reset password',
  },
  'login-form': {
    title: 'Welcome back!',
    subtitle: 'Good to see you again.',
  },
  'reset-form': {
    title: 'Reset your password',
    success: 'Successfully reset your password.',
    error: 'Ooops, failed to reset your password.',
    login: 'Back to login',
    redirect: 'Redirecting to dashboard',
  },
  'register-form': {
    title: 'Join in on the fun!',
    subtitle:
      'Complete the form below and then create your profile to become eligible for all future Triyit campaigns.',
  },
  'forgot-form': {
    title: 'Forgot your password?',
    login: 'Back to login',
    success: 'Thank you! We have sent you an email with the next steps.',
  },
  activate: {
    page_title: 'Activate your account',
    title:
      'Hold up, __FIRSTNAME__. We just need to verify that email address 👮✋',
    subtitle: 'What you need to do…',
    step_1: '1️⃣ Open the email we’ve just sent you.',
    step_2: '2️⃣ Click the “Verify my email address” button',
    warning: '⚠️ DON’T FORGET ⚠️',
    spam: `Check your junk/spam folder incase our verification message gets lost. You can also mark us as a “Safe Sender” to make sure you don’t miss out on campaign invites.`,
  },
  confirm: {
    page_title: 'Confirm your account',
    title: 'Thanks for verifying your email address __FIRSTNAME__! ✅',
    subtitle: 'The next steps…',
    step_1:
      '1️⃣ Log-in and finish your sign up by creating a Triyit profile - which helps us get to know you a little better and work out what products are best suited to your likes and needs.',
    step_2:
      '2️⃣ Once you’ve FULLY completed your profile you’ll be automatically be entered into the matchmaking process for all future campaigns.',
    step_3: '3️⃣ Just 5-10 mins to complete 👍',
    submit: "Let's go!",
  },
  modal: {
    campaign: 'Edit campaign',
    usergroup: 'Edit user group',
  },
  campaign: {
    INITIALISED: 'Initialised',
    ACTIVE: 'Active',
    FINISHED: 'Finished',
    PENDING: 'Inactive',
    title: 'Campaigns',
    name: 'Name of campaign',
    description: 'Campaign description',
    group: 'Target user group',
    users: 'Target users',
    starting: 'Starting date',
    ending: 'Ending date',
  },
};

export default translations;
