import { FC } from 'react';
import { Segment, Whitelist } from '../../../components';
import { Loader, PageSkeleton } from '../../../layout';
import { useWhitelists } from '../../../hooks';

const CampaigsPage: FC = () => {
  const { loading, activeWhitelists, inactiveWhitelists, openModal } =
    useWhitelists();
  return (
    <PageSkeleton
      title='Whitelists'
      // header={
      //   <ButtonGroup>
      //     <Button label='Create new' suffix='plus' primary onClick={() => {}} />
      //   </ButtonGroup>
      // }
    >
      <Loader {...{ loading }}>
        <Segment title='Inactive'>
          {inactiveWhitelists.map((whitelist) => (
            <Whitelist onClick={openModal} {...whitelist} key={whitelist.id} />
          ))}
        </Segment>
        <Segment title='Active'>
          {activeWhitelists.map((whitelist) => (
            <Whitelist onClick={openModal} {...whitelist} key={whitelist.id} />
          ))}
        </Segment>
      </Loader>
    </PageSkeleton>
  );
};

export default CampaigsPage;
