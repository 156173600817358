import React from 'react';

export default function IconHome(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-home</title>
      <g
        id='icon-home'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,3 L20,11 L20,21 C20,21.5522847 19.5522847,22 19,22 L15,22 C14.4477153,22 14,21.5522847 14,21 L14,17 C14,16.4477153 13.5522847,16 13,16 L11,16 C10.4477153,16 10,16.4477153 10,17 L10,21 C10,21.5522847 9.55228475,22 9,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,11 L12,3 Z M12,9 C10.8954305,9 10,9.8954305 10,11 C10,12.1045695 10.8954305,13 12,13 C13.1045695,13 14,12.1045695 14,11 C14,9.8954305 13.1045695,9 12,9 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M12.012787,4.41530478 L3.70710678,12.720985 C3.31658249,13.1115093 2.68341751,13.1115093 2.29289322,12.720985 C1.90236893,12.3304607 1.90236893,11.6972957 2.29289322,11.3067714 L11.3067714,2.29289322 C11.6977216,1.90194304 12.3317276,1.90243187 12.7220745,2.29398443 L21.7081963,11.3078626 C22.0981179,11.6989886 22.0971416,12.3321529 21.7060156,12.7220745 C21.3148895,13.1119961 20.6817253,13.1110198 20.2918037,12.7198938 L12.012787,4.41530478 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
