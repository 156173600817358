import { FC, memo } from 'react';
import { Question } from '../components';
import { iQuestion } from '../types/question';
import styles from '../styles/pages/survey-page.module.scss';

interface iPageContentProps {
  survey: string;
  questions: iQuestion[];
}

const PageContent: FC<iPageContentProps> = memo(({ survey, questions }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.questions}>
        <div className={styles.content}>
          {questions?.map((question: iQuestion) => (
            <Question
              key={question.id}
              options={question.options ?? []}
              {...question}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default PageContent;
