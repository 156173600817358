import { createContext, FC } from 'react';
import { Route, useParams } from 'react-router-dom';
import FinishPage from './Finish';
import SurveyPage from './Page';
import StartPage from './Start';
import { useSurvey } from '../../hooks';
import Loading from './Loading';
import ErrorPage from './Error';
import { usePages, useValue } from '../../hooks/survey';
import { iSurvey } from '../../types';

export const SurveyContext = createContext<{
  value: { [key: string]: string | string[] };
  survey: iSurvey | null;
  survey_id: string | null;
  onChange: (key: string, value: string | string[]) => void;
}>({ value: {}, onChange: () => null, survey: null, survey_id: null });

const Survey: FC = () => {
  const { survey_id } = useParams<{ survey_id: string; question_id: string }>();
  const { survey, loading, error } = useSurvey(survey_id ?? '');

  // Load all pages
  usePages(survey?.id);

  // Load initial value
  const { value, onChange } = useValue(survey?.id);

  if (loading) return <Loading />;
  if (error || !survey) return <ErrorPage />;

  return (
    <SurveyContext.Provider value={{ value, onChange, survey, survey_id }}>
      <Route path='/survey/:survey_id' exact component={StartPage} />
      <Route path='/survey/:survey_id/finish' component={FinishPage} />
      <Route path='/survey/:survey_id/p/:page_index' component={SurveyPage} />
    </SurveyContext.Provider>
  );
};

export default Survey;
