import { handleFetch } from '.';
import { iUserGroup } from '../../types';

/**
 * Creates new user group
 * @param name name of the group
 * @param query the SQL query of the group
 * @param token user authorisation token
 * @returns the new user group
 */
const createUserGroup = async (
  name: string,
  query: string | null,
  token: string,
) => {
  const { data } = await handleFetch(
    `/user/group`,
    {
      method: 'PUT',
      body: { query, name },
    },
    token,
    true,
  );
  return data?.group;
};

/**
 * Update user group
 * @param id id of the group
 * @param group the new group body
 * @param token user authorisation token
 * @returns the new usergroup
 */
const updateUserGroup = async (
  id: string,
  group: iUserGroup,
  token: string,
) => {
  const { data } = await handleFetch(
    `/user/group/${id}`,
    {
      method: 'PATCH',
      body: group,
    },
    token,
    true,
  );
  return data;
};

/**
 * Deletes user group
 * @param id id of the group
 * @param token user authorisation token
 * @returns null
 */
const deleteUserGroup = async (id: string, token: string) => {
  const { data } = await handleFetch(
    `/user/group/${id}`,
    {
      method: 'DELETE',
      body: {},
    },
    token,
    true,
  );
  return data;
};

const exported = { createUserGroup, updateUserGroup, deleteUserGroup };
export default exported;
