import { FC } from 'react';
import { SurveySkeleton } from '../../layout';
import styles from '../../styles/pages/survey-start.module.scss';
import { useUser } from '../../hooks';
import md from 'marked';
import { Button } from '../../components';
import { useSurveyContext } from '../../hooks/survey';

interface iFinishPage {}
const FinishPage: FC<iFinishPage> = () => {
  const { parseString } = useUser();

  const { survey, finishSurvey } = useSurveyContext();

  return (
    <SurveySkeleton title={survey?.title || ''} progress={1}>
      <div className={styles.wrapper}>
        <div
          dangerouslySetInnerHTML={{
            __html: md(parseString(survey?.finish ?? '')),
          }}
        ></div>
        {survey?.finish_action && (
          <Button primary onClick={finishSurvey}>
            Let's do it!
          </Button>
        )}
      </div>
    </SurveySkeleton>
  );
};

export default FinishPage;
