import React from 'react';

export default function IconSearch(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-search</title>
      <g
        id='icon-search'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={10}
          cy={10}
          r={7}
        />
        <path
          d='M16.3198574,14.9056439 L17.4165237,16.0023102 C17.8277311,15.9795312 18.2465281,16.1252077 18.5606602,16.4393398 L21.5606602,19.4393398 C22.1464466,20.0251263 22.1464466,20.9748737 21.5606602,21.5606602 C20.9748737,22.1464466 20.0251263,22.1464466 19.4393398,21.5606602 L16.4393398,18.5606602 C16.1252077,18.2465281 15.9795312,17.8277311 16.0023102,17.4165237 L14.9056439,16.3198574 C13.5509601,17.3729184 11.8487115,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C18,11.8487115 17.3729184,13.5509601 16.3198574,14.9056439 L16.3198574,14.9056439 Z M10,16 C13.3137085,16 16,13.3137085 16,10 C16,6.6862915 13.3137085,4 10,4 C6.6862915,4 4,6.6862915 4,10 C4,13.3137085 6.6862915,16 10,16 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
