import React from 'react';

export default function IconLaunch(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-launch</title>
      <g
        id='icon-launch'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M13.7071068,6.46446609 C14.0971281,6.46446609 14.4019143,6.67090282 14.5684905,6.95588323 C15.2439964,6.76229506 16.0026933,6.93162549 16.5355339,7.46446609 C17.0683745,7.9973067 17.2377049,8.75600358 17.0435252,9.43239307 C17.3290972,9.59808566 17.5355339,9.9028719 17.5355339,10.2928932 L17.5355339,17.363961 C17.5355339,17.6291775 17.4301771,17.8835314 17.2426407,18.0710678 L13,22.3137085 C12.3700351,22.9436734 11.2928932,22.4975066 11.2928932,21.6066017 L11.2931495,18.0298571 C11.1345501,18.0948163 10.9748426,18.1572374 10.8140768,18.2170986 C10.3867385,18.3762183 9.90587676,18.2260908 9.6449782,17.8521 C9.17408023,17.177081 8.64150484,16.5409998 8.05025253,15.9497475 C7.45900022,15.3584952 6.82291901,14.8259198 6.14789995,14.3550218 C5.77390916,14.0941232 5.62378168,13.6132615 5.78290137,13.1859232 L5.97039353,12.7065887 L2.39339828,12.7071068 C1.50249343,12.7071068 1.05632664,11.6299649 1.6862915,11 L5.92893219,6.75735931 C6.11646857,6.56982293 6.37082248,6.46446609 6.63603897,6.46446609 L13.7071068,6.46446609 Z M13.1889471,9.39565451 L11.0387148,11.5478997 C11.3552598,11.699415 11.6416314,11.8842721 11.8786797,12.1213203 C12.1157279,12.3583686 12.300585,12.6447402 12.4521003,12.9612852 L14.6043455,10.8110529 C14.2756592,10.7234476 13.9649413,10.5507277 13.7071068,10.2928932 C13.4492723,10.0350587 13.2765524,9.72434081 13.1889471,9.39565451 Z M20.1728285,2.79031085 C20.7542844,2.76845217 21.2315478,3.2457156 21.2096891,3.82717148 C21.1772769,4.68936046 21.0761531,5.54267506 20.9085474,6.37982936 C20.4354406,5.77521736 18.9205785,6.19265644 18.363961,5.63603897 C17.8073436,5.0794215 18.2247826,3.56455936 17.6201706,3.09145257 C18.4573249,2.92384692 19.3106395,2.82272315 20.1728285,2.79031085 Z'
          id='Combined-Shape'
          fill='currentColor'
        />
        <path
          d='M7.22752246,10.2637703 C7.85975523,9.25973781 8.60753806,8.32139413 9.46368929,7.46524289 C11.7327246,5.19620756 14.5691211,3.7022995 17.6201706,3.09145257 C18.2247826,3.56455936 18.8073436,4.0794215 19.363961,4.63603897 C19.9205785,5.19265644 20.4354406,5.77521736 20.9085474,6.37982936 C20.2977005,9.43087888 18.8037924,12.2672754 16.5347571,14.5363107 C15.6786059,15.3924619 14.7402622,16.1402448 13.7362297,16.7724413 C12.9534055,15.4712343 12.000908,14.2435487 10.8786797,13.1213203 C9.75645128,11.999092 8.52876566,11.0465945 7.22752246,10.2638278 L7.22752246,10.2637703 Z M6.36772159,15.7580219 C6.70376172,16.0430913 7.02912416,16.3428327 7.34314575,16.6568542 C7.65716734,16.9708758 7.95690872,17.2962383 8.2419781,17.6322784 C8.70730685,18.1808085 8.45866586,19.0264895 7.77049956,19.2358773 C6.9820914,19.4757657 6.17630841,19.6542264 5.36060061,19.7700539 C4.70125095,19.8636791 4.13632089,19.298749 4.2299461,18.6393994 C4.34577358,17.8236916 4.52423431,17.0179086 4.76412267,16.2295004 C4.97351052,15.5413341 5.81919148,15.2926931 6.36772159,15.7580219 Z M13.7071068,10.2928932 C14.4881554,11.0739418 15.7544853,11.0739418 16.5355339,10.2928932 C17.3165825,9.51184464 17.3165825,8.24551468 16.5355339,7.46446609 C15.7544853,6.68341751 14.4881554,6.68341751 13.7071068,7.46446609 C12.9260582,8.24551468 12.9260582,9.51184464 13.7071068,10.2928932 Z'
          id='Shape'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
