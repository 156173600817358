import React from 'react';

export default function IconBox(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-box</title>
      <g
        id='icon-box'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,5 C20.1045695,5 21,5.8954305 21,7 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,7 C3,5.8954305 3.8954305,5 5,5 L19,5 Z M15,10 L9,10 C8.44771525,10 8,10.4477153 8,11 C8,11.5522847 8.44771525,12 9,12 L9,12 L15,12 C15.5522847,12 16,11.5522847 16,11 C16,10.4477153 15.5522847,10 15,10 L15,10 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M4,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,6 C22,6.55228475 21.5522847,7 21,7 L3,7 C2.44771525,7 2,6.55228475 2,6 L2,5 C2,3.8954305 2.8954305,3 4,3 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
