import React from 'react';

export default function IconDeviceSmartphone(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-device-smartphone</title>
      <g
        id='icon-device-smartphone'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M8,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.1045695,22 16,22 L8,22 C6.8954305,22 6,21.1045695 6,20 L6,4 C6,2.8954305 6.8954305,2 8,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12,20 C12.8284271,20 13.5,19.3284271 13.5,18.5 C13.5,17.6715729 12.8284271,17 12,17 C11.1715729,17 10.5,17.6715729 10.5,18.5 C10.5,19.3284271 11.1715729,20 12,20 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
