import React from 'react';

export default function IconPieChart(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-pie-chart</title>
      <g
        id='icon-pie-chart'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M14,13 L20.7769836,13 L20.7769836,13 C21.4185633,13 21.8941654,13.5956124 21.7521924,14.2212866 C20.7267749,18.7402952 16.6941279,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,7.30587205 5.25970479,3.27322507 9.77871338,2.24780761 C10.4043876,2.10583459 11,2.58143667 11,3.22301642 L11,10 C11,11.6568542 12.3431458,13 14,13 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M20.7769836,11 L14,11 C13.4477153,11 13,10.5522847 13,10 L13,3.22301642 C13,2.58143667 13.5956124,2.10583459 14.2212866,2.24780761 C17.9615365,3.09651534 20.9034847,6.0384635 21.7521924,9.77871338 C21.8941654,10.4043876 21.4185633,11 20.7769836,11 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
