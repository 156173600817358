import { useFormik } from 'formik';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Input } from '../components';
import network from '../core/network';
import { useUser } from '../hooks';
import styles from '../styles/layout/authform.module.scss';

const LoginForm: FC = () => {
  const history = useHistory();
  const { setUser } = useUser();
  const { t } = useTranslation('login-form');
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const res = await network.user.login(values.email, values.password);
      if (res.error === 'USER_NOT_FOUND') {
        await formik.setFieldValue('password', '');
        await formik.setFieldValue('email', '');
        await formik.setFieldError('email', 'No user found with email address');
      } else if (res.error === 'BAD_CREDENTIALS') {
        await formik.setFieldValue('password', '');
        await formik.setFieldError('password', 'Wrong credentials');
      } else if (res.error === 'INACTIVE') {
        await formik.setFieldValue('password', '');
        await formik.setFieldError('email', 'User not active');
        history.replace('/activate');
      } else {
        setUser({ ...res.user, token: res.token });
        history.push('/');
      }
    },
  });

  const _handleChange = (value: string, name?: string) =>
    formik.setFieldValue(name ?? '', value);
  return (
    <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <img src='/images/logo/triyit-trademark.png' alt='' />
      <h1>{t('title')}</h1>
      <h2>{t('subtitle')}</h2>
      <Input
        className={styles.input}
        icon='user'
        onChange={_handleChange}
        name='email'
        value={formik.values.email}
        placeholder='Email address'
        error={formik.errors.email}
      />
      <Input
        className={styles.input}
        icon='lock'
        onChange={_handleChange}
        name='password'
        value={formik.values.password}
        placeholder='Password'
        type='password'
        error={formik.errors.password}
      />
      <div className={styles.row}>
        <Link to='/forgot'>Forgot Password?</Link>
        <Link to='/register'>Sign Up</Link>

        <Button type='submit' primary>
          Sign In
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
