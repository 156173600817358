import React from 'react';

export default function IconInboxCheck(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-inbox-check</title>
      <g
        id='icon-inbox-check'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M19,5 L5,5 L5,15 L7,15 C8.1045695,15 9,15.8954305 9,17 C9,18.1045695 9.8954305,19 11,19 L13,19 C14.1045695,19 15,18.1045695 15,17 C15,15.8954305 15.8954305,15 17,15 L19,15 L19,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,11.5857864 L14.2928932,8.29289322 C14.6834175,7.90236893 15.3165825,7.90236893 15.7071068,8.29289322 C16.0976311,8.68341751 16.0976311,9.31658249 15.7071068,9.70710678 L11.7071068,13.7071068 C11.3165825,14.0976311 10.6834175,14.0976311 10.2928932,13.7071068 L8.29289322,11.7071068 C7.90236893,11.3165825 7.90236893,10.6834175 8.29289322,10.2928932 C8.68341751,9.90236893 9.31658249,9.90236893 9.70710678,10.2928932 L11,11.5857864 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
