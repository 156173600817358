import React from 'react';

export default function IconPaint(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-paint</title>
      <g
        id='icon-paint'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M13,14 C13.5522847,14 14,14.4477153 14,15 L14,21 C14,21.5522847 13.5522847,22 13,22 L11,22 C10.4477153,22 10,21.5522847 10,21 L10,15 C10,14.4477153 10.4477153,14 11,14 L11,12.6043432 C11,11.1982473 11.976596,9.98080707 13.3492086,9.67578203 L19.2169305,8.37184385 C19.674468,8.27016884 20,7.86435544 20,7.39565679 L20,7 C20,6.44771525 19.5522847,6 19,6 L18,6 L18,4 L19,4 C20.6568542,4 22,5.34314575 22,7 L22,7.39565679 C22,8.80175273 21.023404,10.0191929 19.6507914,10.324218 L13.7830695,11.6281562 C13.325532,11.7298312 13,12.1356446 13,12.6043432 L13,14 Z'
          id='secondary'
          fill='currentColor'
        />
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={2}
          y={2}
          width={17}
          height={6}
          rx={2}
        />
      </g>
    </svg>
  );
}
