import { useCallback, useEffect, useRef, useState } from 'react';
import useFetch from 'use-http';
import { useModal, useUser } from '.';
import { network } from '../core';
import { UserGroupModal } from '../modals';
import { iUserGroup } from '../types';

export const useGroups = () => {
  const { user } = useUser();
  const req = useFetch('/user/group');
  const [groups, setGroups] = useState<iUserGroup[] | null>(null);
  const groupsRef = useRef<iUserGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { presentModal } = useModal();

  /**
   * Load user groups
   */
  const _handleLoad = useCallback(async () => {
    const res = await req.get();
    setLoading(false);
    if (res?.groups) setGroups(res.groups);
  }, []);

  /**
   * Open usergroup in modal
   */
  const _handleOpenGroup = useCallback((id: string | iUserGroup) => {
    const group =
      typeof id === 'string' ? groupsRef.current?.find((g) => g.id === id) : id;
    if (!group) return;

    presentModal({
      title: group.name,
      subtitle: 'Edit user group',
      content: (
        <UserGroupModal
          {...{ ...group, onUpdate: _handleUpdate, onDelete: _handleDelete }}
        />
      ),
    });
  }, []);

  /**
   * Update group by id
   */
  const _handleCreate = useCallback(async () => {
    if (!groupsRef.current || !user) return;
    const group = await network.group.createUserGroup(
      'Unnamed user group',
      null,
      user?.token,
    );

    if (!group) return window.alert('Failed to create group');

    _handleOpenGroup(group);

    // update in list
    const _groups = [...groupsRef.current];
    _groups.push(group);
    setGroups(_groups);
  }, []);

  /**
   * Update group by id
   */
  const _handleUpdate = useCallback(async (id: string, body: iUserGroup) => {
    if (!groupsRef.current || !user) return;
    network.group.updateUserGroup(id, body, user?.token);

    // update in list
    const _groups = [...groupsRef.current];
    const _index = _groups.findIndex((g) => g.id === id);
    _groups[_index] = body;
    setGroups(_groups);
  }, []);

  /**
   * Delete group by id
   */
  const _handleDelete = useCallback(async (id: string) => {
    if (!groupsRef.current || !user) return;
    network.group.deleteUserGroup(id, user?.token);

    // update in list
    const _groups = [...groupsRef.current];
    const _index = _groups.findIndex((g) => g.id === id);
    _groups.splice(_index, 1);
    setGroups(_groups);
  }, []);

  // Load groups
  useEffect(() => {
    _handleLoad();
  }, []);

  // Update group ref
  useEffect(() => {
    groupsRef.current = groups ?? [];
  }, [JSON.stringify(groups)]);

  return {
    groups: groups ?? [],
    loading,
    createGroup: _handleCreate,
    updateGroup: _handleUpdate,
    deleteGroup: _handleDelete,
    openModal: _handleOpenGroup,
  };
};
