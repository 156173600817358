import React from 'react';

export default function IconWork(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-work</title>
      <g
        id='icon-work'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M10,14.9178524 L10,16 C10,16.5522847 10.4477153,17 11,17 L13,17 C13.5522847,17 14,16.5522847 14,16 L14,14.9178524 C16.834994,14.6839702 19.5302727,13.9573511 22,12.8238311 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,12.8238311 C4.4697273,13.9573511 7.165006,14.6839702 10,14.9178524 L10,14.9178524 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M14,2 C15.6568542,2 17,3.34314575 17,5 L17,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,10.8238311 C19.5302727,11.9573511 16.834994,12.6839702 14,12.9178524 L14,12 C14,11.4477153 13.5522847,11 13,11 L11,11 C10.4477153,11 10,11.4477153 10,12 L10,12.9178524 C7.165006,12.6839702 4.4697273,11.9573511 2,10.8238311 L2,8 C2,6.8954305 2.8954305,6 4,6 L7,6 L7,5 C7,3.34314575 8.34314575,2 10,2 L14,2 Z M14,4 L10,4 C9.44771525,4 9,4.44771525 9,5 L9,6 L15,6 L15,5 C15,4.44771525 14.5522847,4 14,4 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
