import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthSkeleton, LoginForm } from '../layout';

const Login: FC = () => {
  const { t } = useTranslation('login');

  return (
    <AuthSkeleton
      pageTitle={t('page_title')}
      title={t('meta_title')}
      subtitle={t('meta_subtitle')}
    >
      <LoginForm />
    </AuthSkeleton>
  );
};

export default Login;
