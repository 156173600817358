import { BASE_URL } from '../config';
import user from './user';
import survey from './survey';
import group from './group';
import database from './database';
import campaign from './campaign';
import { progress } from '..';

type tParams =
  | {
      method: 'GET' | undefined;
      body: undefined;
      query?: { [key: string]: string | number };
    }
  | {
      method: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
      body: any;
      query?: { [key: string]: string | number };
    };

export const handleFetch = async (
  path: string,
  params?: tParams,
  authorisation?: string,
  loader?: boolean,
) => {
  try {
    if (loader) progress.start();
    const url = new URL(path.replace('//', '/'), BASE_URL);
    const search = new URLSearchParams();
    Object.entries(params?.query || {}).forEach(([key, val]) =>
      search.set(key, String(val)),
    );
    let uri = url.toString();
    if (search.toString()) uri += `?${search.toString()}`;
    const res = await fetch(uri, {
      method: params?.method ?? 'GET',
      body: params?.body ? JSON.stringify(params.body) : undefined,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorisation}`,
      },
    });

    const data = await res.json();
    if (loader) progress.done();

    return { data, res, error: null };
  } catch (error) {
    console.log('[ERROR] Failed to fetch', error);
    return { data: null, res: null, error };
  }
};

const exported = { user, survey, database, group, campaign };
export default exported;
