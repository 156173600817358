import dayjs from 'dayjs';

export const formatDate = (
  date?: string | Date,
  format = 'MMMM D, YYYY',
): string | null => {
  if (!date) return null;

  return dayjs(date).format(format);
};

/**
 * Get the difference between the current date and a future or past date
 * Formatted in the DD HH MM SS format
 * @param date
 * @returns
 */
export const formatDifference = (date?: string | Date): string | null => {
  if (!date) return null;

  const now = new Date();
  const updated = new Date(date ?? '');

  const delta = now.getTime() - updated.getTime();

  const seconds = Math.floor(delta / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let res = '';
  if (days > 0) res += `${days}d `;
  if (hours > 0) res += `${hours % 24}h `;
  if (days < 1 && minutes > 0) res += `${minutes % 60}m `;
  if (days < 1 && hours < 1 && seconds > 0) res += `${seconds % 60}s `;

  return res;
};
