import React from 'react';

export default function IconCheck(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-check</title>
      <g
        id='icon-check'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M10,14.5857864 L16.2928932,8.29289322 C16.6834175,7.90236893 17.3165825,7.90236893 17.7071068,8.29289322 C18.0976311,8.68341751 18.0976311,9.31658249 17.7071068,9.70710678 L10.7071068,16.7071068 C10.3165825,17.0976311 9.68341751,17.0976311 9.29289322,16.7071068 L6.29289322,13.7071068 C5.90236893,13.3165825 5.90236893,12.6834175 6.29289322,12.2928932 C6.68341751,11.9023689 7.31658249,11.9023689 7.70710678,12.2928932 L10,14.5857864 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
