import React from 'react';

export default function IconArchive(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-archive</title>
      <g
        id='icon-archive'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20.76547,5 C20.76547,5.07379031 20.7613862,5.14752407 20.7532374,5.22086305 L19.1976819,19.2208631 C19.0851413,20.2337284 18.2290128,21 17.2099144,21 L6.79008559,21 C5.77098715,21 4.91485871,20.2337284 4.80231812,19.2208631 L3.24676256,5.22086305 C3.23851847,5.14666625 3.23435517,5.07296725 3.23435517,5 L20.76547,5 L20.76547,5 Z'
          id='Path'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M9.70710678,10.2928932 L12,12.585 L14.2928932,10.2928932 C14.6834175,9.90236893 15.3165825,9.90236893 15.7071068,10.2928932 C16.0976311,10.6834175 16.0976311,11.3165825 15.7071068,11.7071068 L13.415,14 L15.7071068,16.2928932 C16.0976311,16.6834175 16.0976311,17.3165825 15.7071068,17.7071068 C15.3165825,18.0976311 14.6834175,18.0976311 14.2928932,17.7071068 L12,15.415 L9.70710678,17.7071068 C9.31658249,18.0976311 8.68341751,18.0976311 8.29289322,17.7071068 C7.90236893,17.3165825 7.90236893,16.6834175 8.29289322,16.2928932 L10.585,14 L8.29289322,11.7071068 C7.90236893,11.3165825 7.90236893,10.6834175 8.29289322,10.2928932 C8.68341751,9.90236893 9.31658249,9.90236893 9.70710678,10.2928932 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M4,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,6 C22,6.55228475 21.5522847,7 21,7 L3,7 C2.44771525,7 2,6.55228475 2,6 L2,5 C2,3.8954305 2.8954305,3 4,3 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
