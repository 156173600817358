import { FC, useCallback, useEffect } from 'react';
import { Icon } from '../../components';
import { useModal } from '../../hooks';
import { iModal } from '../../providers/Modal';
import styles from '../../styles/layout/modal.module.scss';
interface iModalProps extends iModal {}

const ModalContent: FC<iModalProps> = ({ title, content, subtitle }) => {
  const { dismissModal } = useModal();
  const _handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') dismissModal();
  }, []);
  useEffect(() => {
    window.addEventListener('keydown', _handleKeyDown);
    return () => {
      window.removeEventListener('keydown', _handleKeyDown);
    };
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.backdrop} onClick={dismissModal} />
      <div className={styles.wrapper}>
        <header>
          <h3>{title}</h3>
          {subtitle && <h4>{subtitle}</h4>}
          <button onClick={dismissModal}>
            <Icon icon='x' />
          </button>
        </header>
        {content}
      </div>
    </div>
  );
};

export default ModalContent;
