import classNames from 'classnames';
import { FC, memo } from 'react';
import { Icon } from '..';
import styles from '../../styles/components/button.module.scss';

interface iButtonProps {
  onClick?: () => void;
  type?: 'button' | 'submit';
  primary?: boolean;
  link?: boolean;
  disabled?: boolean;
  icon?: string;
  label?: string;
  suffix?: string;
}

const Button: FC<iButtonProps> = memo(
  ({
    onClick,
    children,
    type,
    primary,
    disabled,
    icon,
    label,
    suffix,
    link,
  }) => {
    const _handleClick = () => onClick?.();
    return (
      <button
        type={type ?? 'button'}
        onClick={_handleClick}
        disabled={disabled}
        className={classNames(
          styles.wrapper,
          primary && styles.primary,
          link && styles.link,
          disabled && styles.disabled,
          children && styles.filled,
        )}
      >
        {icon && <Icon icon={icon} />}
        {label || children}
        {suffix && <Icon icon={suffix} />}
      </button>
    );
  },
);

export default Button;
