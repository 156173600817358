import React from 'react';

export default function IconOrderVertical(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-order-vertical</title>
      <g
        id='icon-order-vertical'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M11.5857864,16 L2,16 C1.44771525,16 1,15.5522847 1,15 C1,14.4477153 1.44771525,14 2,14 L11.5857864,14 L9.29289322,11.7071068 C8.90236893,11.3165825 8.90236893,10.6834175 9.29289322,10.2928932 C9.68341751,9.90236893 10.3165825,9.90236893 10.7071068,10.2928932 L14.7071068,14.2928932 C15.0976311,14.6834175 15.0976311,15.3165825 14.7071068,15.7071068 L10.7071068,19.7071068 C10.3165825,20.0976311 9.68341751,20.0976311 9.29289322,19.7071068 C8.90236893,19.3165825 8.90236893,18.6834175 9.29289322,18.2928932 L11.5857864,16 Z'
          id='secondary'
          fill='currentColor'
          transform='translate(8.000000, 15.000000) rotate(-270.000000) translate(-8.000000, -15.000000) '
        />
        <path
          d='M19.5857864,10 L10,10 C9.44771525,10 9,9.55228475 9,9 C9,8.44771525 9.44771525,8 10,8 L19.5857864,8 L17.2928932,5.70710678 C16.9023689,5.31658249 16.9023689,4.68341751 17.2928932,4.29289322 C17.6834175,3.90236893 18.3165825,3.90236893 18.7071068,4.29289322 L22.7071068,8.29289322 C23.0976311,8.68341751 23.0976311,9.31658249 22.7071068,9.70710678 L18.7071068,13.7071068 C18.3165825,14.0976311 17.6834175,14.0976311 17.2928932,13.7071068 C16.9023689,13.3165825 16.9023689,12.6834175 17.2928932,12.2928932 L19.5857864,10 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          transform='translate(16.000000, 9.000000) rotate(-90.000000) translate(-16.000000, -9.000000) '
        />
      </g>
    </svg>
  );
}
