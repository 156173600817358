import React from 'react';

export default function IconLocationPin(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-location-pin</title>
      <g
        id='icon-location-pin'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,1 L12,7 C10.3431458,7 9,8.34314575 9,10 C9,11.6568542 10.3431458,13 12,13 L12,22.3137085 C11.7440777,22.3137085 11.4881554,22.2160774 11.2928932,22.0208153 L5.63603897,16.363961 C2.12132034,12.8492424 2.12132034,7.15075759 5.63603897,3.63603897 C7.39339828,1.87867966 9.69669914,1 12,1 L12,1 Z'
          id='Path'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M12,1 C14.3033009,1 16.6066017,1.87867966 18.363961,3.63603897 C21.8786797,7.15075759 21.8786797,12.8492424 18.363961,16.363961 L12.7071068,22.0208153 C12.5118446,22.2160774 12.2559223,22.3137085 12,22.3137085 L12,13 C13.6568542,13 15,11.6568542 15,10 C15,8.34314575 13.6568542,7 12,7 L12,1 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
