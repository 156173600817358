import React from 'react';

export default function IconAsterisk(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-asterisk</title>
      <g
        id='icon-asterisk'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M11,10.6236181 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,10.6236181 L16.4462656,9.50385851 C16.9715196,9.33319314 17.5356737,9.62064402 17.7063391,10.145898 C17.8770045,10.671152 17.5895536,11.2353062 17.0642996,11.4059715 L13.618034,12.5257311 L15.7479433,15.4572997 C16.0725681,15.9041075 15.9735193,16.5294771 15.5267115,16.854102 C15.0799038,17.1787268 14.4545341,17.079678 14.1299093,16.6328702 L12,13.7013016 L9.87009073,16.6328702 C9.5454659,17.079678 8.92009623,17.1787268 8.47328849,16.854102 C8.02648074,16.5294771 7.92743191,15.9041075 8.25205674,15.4572997 L10.381966,12.5257311 L6.93570042,11.4059715 C6.41044641,11.2353062 6.12299553,10.671152 6.2936609,10.145898 C6.46432628,9.62064402 7.0284804,9.33319314 7.55373441,9.50385851 L11,10.6236181 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
