import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../components';
import styles from '../styles/layout/survey.module.scss';
import SurveyHeader from './SurveyHeader';

interface iSurveySkeleton {
  title: string;
  progress?: number;
  isBackVisible?: boolean;
  isNextVisible?: boolean;
  isFinish?: boolean;
  isFooterVisible?: boolean;
  onNext?: () => void;
  onBack?: () => void;
}
const SurveySkeleton: FC<iSurveySkeleton> = ({
  children,
  title,
  progress,
  isBackVisible,
  isNextVisible,
  isFinish,
  isFooterVisible,
  onNext,
  onBack,
}) => {
  const location = useLocation();
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    wrapper.current?.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={styles.wrapper}>
      <SurveyHeader title={title} />
      <main ref={wrapper}>{children}</main>
      {isFooterVisible && (
        <div className={styles.footer}>
          <div className={styles.content}>
            <Button
              icon='arrow-left'
              onClick={onBack}
              disabled={!isBackVisible}
            />
            <div className={styles.progressWrapper}>
              <div className={styles.bar}>
                <div
                  className={styles.progress}
                  style={{ width: `${(progress ?? 0) * 100}%` }}
                />
              </div>
            </div>
            <Button
              disabled={!isNextVisible}
              primary
              onClick={onNext}
              label={isFinish ? 'Finish ' : 'Next question '}
              suffix='arrow-right'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveySkeleton;
