import React from 'react';

export default function IconTag(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-tag</title>
      <g
        id='icon-tag'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M11.9885089,2 C12.522631,1.99397803 13.0293306,2.20093805 13.4142461,2.58585349 L21.4142461,10.5858535 C22.1952946,11.3669021 22.1952946,12.633232 21.4142461,13.4142806 L13.4142461,21.4142806 C12.6331975,22.1953292 11.3668675,22.1953292 10.5858189,21.4142806 L2.58581894,13.4142806 C2.20362319,13.0320849 1.99701904,12.5244017 2,12.000067 L2,7.00002073 L2,7.00002073 C2,4.2385971 4.23863749,2 7.00006121,2 L11.9885089,2 L11.9885089,2 Z M7.0000325,5.00006705 C5.895463,5.00006705 5.0000325,5.89549755 5.0000325,7.00006705 C5.0000325,8.10463655 5.895463,9.00006705 7.0000325,9.00006705 C8.104602,9.00006705 9.0000325,8.10463655 9.0000325,7.00006705 C9.0000325,5.89549755 8.104602,5.00006705 7.0000325,5.00006705 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12.0000325,18.000067 L18.0000325,12.000067 C15.3333658,9.33340038 14.0000325,8.00006705 14.0000325,8.00006705 C14.0000325,8.00006705 12.0000215,10.003451 7.99999954,14.0102188 L12.0000325,18.000067 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
