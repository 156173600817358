import React from 'react';

export default function IconArrowThickLeftCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-arrow-thick-left-circle</title>
      <g
        id='icon-arrow-thick-left-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M12,10 L17,10 C17.5522847,10 18,10.4477153 18,11 L18,13 C18,13.5522847 17.5522847,14 17,14 L12,14 L12,16 C12,16.8909049 10.9228581,17.3370716 10.2928932,16.7071068 L6.29289322,12.7071068 C5.90236893,12.3165825 5.90236893,11.6834175 6.29289322,11.2928932 L10.2928932,7.29289322 C10.9228581,6.66292836 12,7.10909515 12,8 L12,10 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
