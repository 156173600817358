import { FC } from 'react';
import Loading from '../pages/Survey/Loading';

interface iLoaderProps {
  loading: boolean;
}
const Loader: FC<iLoaderProps> = ({ loading, children }) => {
  if (loading) return <Loading />;

  return <>{children}</>;
};

export default Loader;
