import React from 'react';

export default function IconMoney(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-money</title>
      <g
        id='icon-money'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M17,3 C18.1045695,3 19,3.8954305 19,5 L19,13 C19,13.7439869 18.5937659,14.3930927 17.9910107,14.7376045 C17.8560841,12.6504787 16.120787,11 14,11 C11.790861,11 10,12.790861 10,15 L3,15 C1.8954305,15 1,14.1045695 1,13 L1,5 C1,3.8954305 1.8954305,3 3,3 L17,3 Z M10,6 C8.34314575,6 7,7.34314575 7,9 C7,10.6568542 8.34314575,12 10,12 C11.6568542,12 13,10.6568542 13,9 C13,7.34314575 11.6568542,6 10,6 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M21,9 C22.1045695,9 23,9.8954305 23,11 L23,19 C23,20.1045695 22.1045695,21 21,21 L7,21 C5.8954305,21 5,20.1045695 5,19 L5,11 C5,9.8954305 5.8954305,9 7,9 L21,9 Z M14,12 C12.3431458,12 11,13.3431458 11,15 C11,16.6568542 12.3431458,18 14,18 C15.6568542,18 17,16.6568542 17,15 C17,13.3431458 15.6568542,12 14,12 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
