import React from 'react';

export default function IconCheveronUp(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cheveron-up</title>
      <g
        id='icon-cheveron-up'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M8.70710678,13.7071068 C8.31658249,14.0976311 7.68341751,14.0976311 7.29289322,13.7071068 C6.90236893,13.3165825 6.90236893,12.6834175 7.29289322,12.2928932 L11.2928932,8.29289322 C11.6834175,7.90236893 12.3165825,7.90236893 12.7071068,8.29289322 L16.7071068,12.2928932 C17.0976311,12.6834175 17.0976311,13.3165825 16.7071068,13.7071068 C16.3165825,14.0976311 15.6834175,14.0976311 15.2928932,13.7071068 L12,10.4142136 L8.70710678,13.7071068 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
