import React from 'react';

export default function IconCollection(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-collection</title>
      <g
        id='icon-collection'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={2}
          y={10}
          width={20}
          height={12}
          rx={2}
        />
        <path
          d='M18,6 C19.1045695,6 20,6.8954305 20,8 L4,8 C4,6.8954305 4.8954305,6 6,6 L18,6 Z M16,2 C17.1045695,2 18,2.8954305 18,4 L6,4 C6,2.8954305 6.8954305,2 8,2 L16,2 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
