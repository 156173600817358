import React from 'react';

export default function IconHelp(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-help</title>
      <g
        id='icon-help'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M12,19.5 C11.1715729,19.5 10.5,18.8284271 10.5,18 C10.5,17.1715729 11.1715729,16.5 12,16.5 C12.8284271,16.5 13.5,17.1715729 13.5,18 C13.5,18.8284271 12.8284271,19.5 12,19.5 Z M13,14 C13,14.5522847 12.5522847,15 12,15 C11.4477153,15 11,14.5522847 11,14 L11,12.59 C11,12.2110114 11.2142446,11.8645892 11.5533242,11.6953041 L13.9857363,10.484381 C14.6440539,10.0882411 15,9.54403444 15,9 C15,7.96814575 13.709139,7 12,7 C10.6540964,7 9.51409117,7.61525172 9.13198149,8.42674148 C8.89670326,8.92640396 8.30091609,9.14072975 7.80125361,8.90545152 C7.30159112,8.6701733 7.08726533,8.07438613 7.32254356,7.57472364 C8.06045045,6.00762415 9.92748604,5 12,5 C14.709139,5 17,6.71814575 17,9 C17,10.2999379 16.2398242,11.4621721 14.9480073,12.2359091 L13,13.2084494 L13,14 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
