import React from 'react';

export default function IconHarddrive(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-harddrive</title>
      <g
        id='icon-harddrive'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M18,2 C19.1045695,2 20,2.8954305 20,4 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 L18,2 Z M17,9 C17,8.10909515 15.9228581,7.66292836 15.2928932,8.29289322 L15.2928932,8.29289322 L14.1168732,9.46891327 C13.4740342,9.16806975 12.7566445,9 12,9 C9.23857625,9 7,11.2385763 7,14 C7,16.7614237 9.23857625,19 12,19 C14.7614237,19 17,16.7614237 17,14 L17,14 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M18,19 C18.5522847,19 19,19.4477153 19,20 C19,20.5522847 18.5522847,21 18,21 C17.4477153,21 17,20.5522847 17,20 C17,19.4477153 17.4477153,19 18,19 Z M6,19 C6.55228475,19 7,19.4477153 7,20 C7,20.5522847 6.55228475,21 6,21 C5.44771525,21 5,20.5522847 5,20 C5,19.4477153 5.44771525,19 6,19 Z M12,12 C13.1045695,12 14,12.8954305 14,14 C14,15.1045695 13.1045695,16 12,16 C10.8954305,16 10,15.1045695 10,14 C10,12.8954305 10.8954305,12 12,12 Z M12,6 C12.5522847,6 13,6.44771525 13,7 C13,7.55228475 12.5522847,8 12,8 L8,8 C7.44771525,8 7,7.55228475 7,7 C7,6.44771525 7.44771525,6 8,6 L12,6 Z M18,3 C18.5522847,3 19,3.44771525 19,4 C19,4.55228475 18.5522847,5 18,5 C17.4477153,5 17,4.55228475 17,4 C17,3.44771525 17.4477153,3 18,3 Z M6,3 C6.55228475,3 7,3.44771525 7,4 C7,4.55228475 6.55228475,5 6,5 C5.44771525,5 5,4.55228475 5,4 C5,3.44771525 5.44771525,3 6,3 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
