import React from 'react';

export default function IconNotification(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-notification</title>
      <g
        id='icon-notification'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle id='secondary' fill='currentColor' cx={12} cy={19} r={3} />
        <path
          d='M10.0200035,4.28395803 C10.0068199,4.19120234 10,4.09639761 10,4 C10,2.8954305 10.8954305,2 12,2 C13.1045695,2 14,2.8954305 14,4 C14,4.09639761 13.9931801,4.19120234 13.9799965,4.28395803 C16.8817592,5.13808456 19,7.82167146 19,11 L19,16 C19,16.5522847 19.4477153,17 20,17 C20.5522847,17 21,17.4477153 21,18 C21,18.5522847 20.5522847,19 20,19 L4,19 C3.44771525,19 3,18.5522847 3,18 C3,17.4477153 3.44771525,17 4,17 C4.55228475,17 5,16.5522847 5,16 L5,11 C5,7.82167146 7.11824076,5.13808456 10.0200035,4.28395803 L10.0200035,4.28395803 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
