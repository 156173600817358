import React from 'react';

export default function IconTime(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-time</title>
      <g
        id='icon-time'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M13,11.5857864 L16.2071068,14.7928932 C16.5976311,15.1834175 16.5976311,15.8165825 16.2071068,16.2071068 C15.8165825,16.5976311 15.1834175,16.5976311 14.7928932,16.2071068 L11.2928932,12.7071068 C11.1053568,12.5195704 11,12.2652165 11,12 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11.5857864 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
