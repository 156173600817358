import React from 'react';

export default function IconCreditCard(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-credit-card</title>
      <g
        id='icon-credit-card'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20,4 C21.1045695,4 22,4.8954305 22,6 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 Z M19,16 L17,16 C16.4477153,16 16,16.4477153 16,17 C16,17.5522847 16.4477153,18 17,18 L17,18 L19,18 C19.5522847,18 20,17.5522847 20,17 C20,16.4477153 19.5522847,16 19,16 L19,16 Z M13,16 L11,16 C10.4477153,16 10,16.4477153 10,17 C10,17.5522847 10.4477153,18 11,18 L11,18 L13,18 C13.5522847,18 14,17.5522847 14,17 C14,16.4477153 13.5522847,16 13,16 L13,16 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={2}
          y={7}
          width={20}
          height={4}
        />
      </g>
    </svg>
  );
}
