import React from 'react';

export default function IconDeliver(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-deliver</title>
      <g
        id='icon-deliver'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M7,16 C8.1045695,16 9,16.8954305 9,18 C9,19.1045695 8.1045695,20 7,20 C5.8954305,20 5,19.1045695 5,18 C5,16.8954305 5.8954305,16 7,16 Z M17,16 C18.1045695,16 19,16.8954305 19,18 C19,19.1045695 18.1045695,20 17,20 C15.8954305,20 15,19.1045695 15,18 C15,16.8954305 15.8954305,16 17,16 Z M19.3333333,8 C19.7637637,8 20.1459026,8.27543009 20.2820166,8.68377223 L21.9486833,13.6837722 C21.9826721,13.7857387 22,13.8925179 22,14 L22,17 C22,17.5522847 21.5522847,18 21,18 L20,18 C20,16.3431458 18.6568542,15 17,15 C15.3431458,15 14,16.3431458 14,18 C13.4477153,18 13,17.5522847 13,17 L13,9 C13,8.44771525 13.4477153,8 14,8 L19.3333333,8 Z M18.6125741,10 L16,10 L16,13 L19.6125741,13 L18.6125741,10 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M10,18 C10,16.3431458 8.65685425,15 7,15 C5.34314575,15 4,16.3431458 4,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,6 C2,5.44771525 2.44771525,5 3,5 L13,5 C13.5522847,5 14,5.44771525 14,6 L14,18 L10,18 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
