import { FC, useCallback } from 'react';
import { iCampaign } from '../../types';
import styles from '../../styles/components/usergroup.module.scss';
import { formatDate } from '../../core';
import { useHistory } from 'react-router';

interface iCampaignProps extends iCampaign {
  onModal: (campaign: string | iCampaign) => void;
}

const Campaign: FC<iCampaignProps> = ({
  onModal,
  id = '',
  name,
  user_ids,
  end_date,
  start_date,
  status,
}) => {
  const history = useHistory();
  const _handleClick = useCallback(() => {
    if (status === 'ACTIVE') {
      return history.push(`/admin/campaigns/${id}/responses`);
    }
    onModal(id);
  }, [status]);

  return (
    <div className={styles.wrapper} onClick={_handleClick}>
      <div className={styles.header}>
        <strong>{name}</strong>
        <span>{user_ids?.length} users</span>
      </div>
      <div className={styles.footer}>
        <span>Starting</span>
        <span>{formatDate(start_date)}</span>
      </div>
      <div className={styles.footer}>
        <span>Ending</span>
        <span>{formatDate(end_date)}</span>
      </div>
    </div>
  );
};

export default Campaign;
