import React from 'react';

export default function IconAnnouncement(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-announcement</title>
      <g
        id='icon-announcement'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M5.04402314,14.2943471 L4.94590745,14 L4,14 C2.8954305,14 2,13.1045695 2,12 L2,10 C2,8.8954305 2.8954305,8 4,8 L5,8 C5,7.44771525 5.44771525,7 6,7 L12.3431458,7 C13.1387952,7 13.901857,6.68392948 14.4644661,6.12132034 L16.1213203,4.46446609 C17.0590022,3.5267842 18.3307718,3 19.6568542,3 L21,3 C21.5522847,3 22,3.44771525 22,4 L22,18 C22,18.5522847 21.5522847,19 21,19 L19.6568542,19 C18.3307718,19 17.0590022,18.4732158 16.1213203,17.5355339 L14.4644661,15.8786797 C13.901857,15.3160705 13.1387952,15 12.3431458,15 L11,15 L11,20 C11,20.5522847 10.5522847,21 10,21 L8,21 C7.56956959,21 7.18743075,20.7245699 7.0513167,20.3162278 L5.0586982,14.3383722 C5.05347763,14.3238515 5.04858352,14.3091751 5.0440247,14.2943518 L5.04402314,14.2943471 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={4}
          y={6}
          width={8}
          height={10}
          rx={1}
        />
      </g>
    </svg>
  );
}
