import React from 'react';

export default function IconDiscount(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-discount</title>
      <g
        id='icon-discount'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M10.745452,21.1866391 L9.89524112,21.4674664 C8.68591501,21.8669106 7.36645256,21.3203714 6.79377956,20.182799 L6.39116459,19.3830342 C6.00535669,18.6166555 5.3833445,17.9946433 4.61696577,17.6088354 L3.81720101,17.2062204 C2.67962863,16.6335474 2.13308938,15.314085 2.53253357,14.1047589 L2.81336087,13.254548 C3.0824651,12.439829 3.0824651,11.560171 2.81336087,10.745452 L2.53253357,9.89524112 C2.13308938,8.68591501 2.67962863,7.36645256 3.81720101,6.79377956 L4.61696577,6.39116459 C5.3833445,6.00535669 6.00535669,5.3833445 6.39116459,4.61696577 L6.79377956,3.81720101 C7.36645256,2.67962863 8.68591501,2.13308938 9.89524112,2.53253357 L10.745452,2.81336087 C11.560171,3.0824651 12.439829,3.0824651 13.254548,2.81336087 L14.1047589,2.53253357 C15.314085,2.13308938 16.6335474,2.67962863 17.2062204,3.81720101 L17.6088354,4.61696577 C17.9946433,5.3833445 18.6166555,6.00535669 19.3830342,6.39116459 L20.182799,6.79377956 C21.3203714,7.36645256 21.8669106,8.68591501 21.4674664,9.89524112 L21.1866391,10.745452 C20.9175349,11.560171 20.9175349,12.439829 21.1866391,13.254548 L21.4674664,14.1047589 C21.8669106,15.314085 21.3203714,16.6335474 20.182799,17.2062204 L19.3830342,17.6088354 C18.6166555,17.9946433 17.9946433,18.6166555 17.6088354,19.3830342 L17.2062204,20.182799 C16.6335474,21.3203714 15.314085,21.8669106 14.1047589,21.4674664 L13.254548,21.1866391 C12.439829,20.9175349 11.560171,20.9175349 10.745452,21.1866391 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15.5,14 C16.3284271,14 17,14.6715729 17,15.5 C17,16.3284271 16.3284271,17 15.5,17 C14.6715729,17 14,16.3284271 14,15.5 C14,14.6715729 14.6715729,14 15.5,14 Z M16.7071068,7.29289322 C17.0976311,7.68341751 17.0976311,8.31658249 16.7071068,8.70710678 L8.70710678,16.7071068 C8.31658249,17.0976311 7.68341751,17.0976311 7.29289322,16.7071068 C6.90236893,16.3165825 6.90236893,15.6834175 7.29289322,15.2928932 L15.2928932,7.29289322 C15.6834175,6.90236893 16.3165825,6.90236893 16.7071068,7.29289322 Z M8.5,7 C9.32842712,7 10,7.67157288 10,8.5 C10,9.32842712 9.32842712,10 8.5,10 C7.67157288,10 7,9.32842712 7,8.5 C7,7.67157288 7.67157288,7 8.5,7 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
