import React from 'react';

export default function IconPhoto(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-photo</title>
      <g
        id='icon-photo'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M14,5 C12.8954305,5 12,5.8954305 12,7 C12,8.1045695 12.8954305,9 14,9 C15.1045695,9 16,8.1045695 16,7 C16,5.8954305 15.1045695,5 14,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15.2928932,12.2928932 C15.6834175,11.9023689 16.3165825,11.9023689 16.7071068,12.2928932 L18.7071068,14.2928932 C18.8946432,14.4804296 19,14.7347835 19,15 L19,18 C19,18.5522847 18.5522847,19 18,19 L6,19 C5.44771525,19 5,18.5522847 5,18 L5,15 C5,14.7347835 5.10535684,14.4804296 5.29289322,14.2928932 L9.29289322,10.2928932 C9.68341751,9.90236893 10.3165825,9.90236893 10.7071068,10.2928932 L14,13.5857864 L15.2928932,12.2928932 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
