import React from 'react';

export default function IconPackage(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-package</title>
      <g
        id='icon-package'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <polygon
          id='Path'
          fill='currentColor'
          opacity='0.4'
          points='15 3.33333333 15 4.67 18.0075 4.67 21 6 14 12 12 22 3 18 3 6 12 2'
        />
        <path
          d='M21,6 L21,18 L12,22 L12,10 L21,6 Z M15,3.333 L18,4.67 L9,8.66666667 L9,11.6666667 L6,10.3333333 L6,7.33333333 L15,3.333 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
