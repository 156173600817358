import React from 'react';

export default function IconGlobe(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-globe</title>
      <g
        id='icon-globe'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M2.04937524,11 C2.55109967,5.94667731 6.8146519,2 12,2 C13.0452057,2 14.0529588,2.16035383 15,2.45780228 L15,6.00208688 C15,7.10550383 14.1125667,8 13.000385,8 L12,8 L12,9.00208688 C12,9.74310925 11.5997613,10.3899069 11,10.7348306 L11,12 L13.0020869,12 C14.1055038,12 15,12.8982606 15,13.9979131 L15,15 L16.9940809,15 C18.1019194,15 19,15.8982606 19,16.9979131 L19,19.1414328 C17.1962493,20.9096817 14.7254534,22 12,22 L12,18 L10.9979131,18 C9.89449617,18 9,17.1017394 9,16.0020869 L9,14 C7.8954305,14 7,13.1132936 7,12.0018986 L7,11 L2.04937524,11 L2.04937524,11 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
