import { FC } from 'react';
import { Link } from 'react-router-dom';

const NotFound: FC = () => {
  return (
    <div className='auth-wrapper not-found'>
      <main>
        <h2>Page not found</h2>
        <h2>Available pages:</h2>
        <ul>
          <li>
            <Link to='/uikit'>UI Kit</Link>
          </li>
          <li>
            <Link to='/login'>Login</Link>
          </li>
          <li>
            <Link to='/register'>Register</Link>
          </li>
          <li>
            <Link to='/forgot'>Forgot password</Link>
          </li>
          <li>
            <Link to='/activate'>Activate user</Link>
          </li>
          <li>
            <Link to='/confirm'>Confirm user</Link>
          </li>
        </ul>
      </main>
    </div>
  );
};

export default NotFound;
