import { handleFetch } from '.';
import { iSurveyInput } from '../../types';
import progress from '../progress';

const saveAnswer = async (
  survey: string,
  question: string,
  token: string,
  answer: string | string[],
) => {
  progress.start();
  const { data } = await handleFetch(
    `/survey/answer/${survey}/${question}`,
    {
      method: 'POST',
      body: { answer },
    },
    token,
  );
  progress.done();
  return data;
};

const getAnswers = async (survey: string, token: string) => {
  const { data } = await handleFetch(
    `/survey/answer/${survey}`,
    undefined,
    token,
  );
  return data;
};

const getAll = async (token: string) => {
  const { data } = await handleFetch(`/survey/get`, undefined, token);
  return data;
};
const updateSurvey = async (survey: string, input: any, token: string) => {
  const { data } = await handleFetch(
    `/survey/update/${survey}`,
    {
      method: 'PATCH',
      body: {
        title: input.title,
        subtitle: input.subtitle,
        description: input.description,
        welcome: input.welcome,
        finish: input.finish,
        finish_target: input.finish_target,
        finish_action: input.finish_action,
        is_public: input.is_public,
        is_closed: input.is_closed,
        is_mandatory: input.is_mandatory,
        typeform_id: input.typeform_id,
      },
    },
    token,
  );
  return data;
};

const deleteSurvey = async (survey: string, token: string) => {
  const { data } = await handleFetch(
    `/survey/update/${survey}`,
    {
      method: 'DELETE',
      body: {},
    },
    token,
  );
  return data;
};

const createSurvey = async (input: iSurveyInput, token: string) => {
  const { data } = await handleFetch(
    `/survey/create`,
    {
      method: 'PUT',
      body: input,
    },
    token,
  );
  return data;
};

const exported = {
  saveAnswer,
  getAnswers,
  updateSurvey,
  getAll,
  createSurvey,
  deleteSurvey,
};
export default exported;
