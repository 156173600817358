import React from 'react';

export default function IconApplication(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-application</title>
      <g
        id='icon-application'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20,3 C21.1045695,3 22,3.8954305 22,5 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,5 C2,3.8954305 2.8954305,3 4,3 L20,3 Z M20,9 L4,9 L4,19 L20,19 L20,9 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M5,5 C5.55228475,5 6,5.44771525 6,6 C6,6.55228475 5.55228475,7 5,7 C4.44771525,7 4,6.55228475 4,6 C4,5.44771525 4.44771525,5 5,5 Z M8,5 C8.55228475,5 9,5.44771525 9,6 C9,6.55228475 8.55228475,7 8,7 C7.44771525,7 7,6.55228475 7,6 C7,5.44771525 7.44771525,5 8,5 Z M11,5 C11.5522847,5 12,5.44771525 12,6 C12,6.55228475 11.5522847,7 11,7 C10.4477153,7 10,6.55228475 10,6 C10,5.44771525 10.4477153,5 11,5 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
