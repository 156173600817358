import React from 'react';

export default function IconExternalWindow(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-external-window</title>
      <g
        id='icon-external-window'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,8 C12,8.55228475 11.5522847,9 11,9 L5,9 L5,9 L5,19 L15,19 L15,13 C15,12.4477153 15.4477153,12 16,12 C16.5522847,12 17,12.4477153 17,13 L17,19 L17,19 C17,20.1045695 16.1045695,21 15,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,9 C3,7.8954305 3.8954305,7 5,7 L11,7 C11.5522847,7 12,7.44771525 12,8 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M19,6.41421356 L8.70710678,16.7071068 C8.31658249,17.0976311 7.68341751,17.0976311 7.29289322,16.7071068 C6.90236893,16.3165825 6.90236893,15.6834175 7.29289322,15.2928932 L17.5857864,5 L14,5 C13.4477153,5 13,4.55228475 13,4 C13,3.44771525 13.4477153,3 14,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,10 C21,10.5522847 20.5522847,11 20,11 C19.4477153,11 19,10.5522847 19,10 L19,6.41421356 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
