import React from 'react';

export default function IconBolt(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-bolt</title>
      <g
        id='icon-bolt'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M14,10 L16,10 C16.8133726,10 17.2864967,10.9193697 16.8137335,11.5812382 L11.8137335,18.5812382 C11.2482824,19.3728697 10,18.9728389 10,18 L10,14 L8,14 C7.18662744,14 6.71350333,13.0806303 7.18626653,12.4187618 L12.1862665,5.41876181 C12.7517176,4.62713025 14,5.02716112 14,6 L14,10 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
