import classNames from 'classnames';
import { FC, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import Icon from '../Icon';
import styles from '../../styles/components/input.module.scss';

interface iSelectProps {
  value: string | null;
  name?: string;
  icon?: string;
  onChange: (value: string, name?: string) => void;
  className?: string;
  error?: string;
  disabled?: boolean;
  options: { label: string; value: string | null; disabled?: boolean }[];
}

const Select: FC<iSelectProps> = ({
  value,
  name,
  onChange,
  icon,
  className,
  error,
  disabled,
  options,
}) => {
  const errorWrapper = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<[number, number, number]>([
    0, 0, 100,
  ]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const _handleFocus = (focused: boolean) => () => setIsFocused(focused);
  const _handleChange = ({ target: { value } }: any) => onChange(value, name);

  useEffect(() => {}, [error]);

  const _handleMouseEnter = () => {
    const { left, top, width, height } =
      errorWrapper.current?.getBoundingClientRect?.() ?? {};
    setVisible(true);
    const _left = (left ?? 0) + (width ?? 0) + 8;
    const _width = window.innerWidth - _left - 12;

    if (_width > 240) {
      setPosition([_left, (top ?? 0) + (height ?? 0) / 2, _width]);
    } else {
      setPosition([_left, (top ?? 0) + (height ?? 0) / 2, _width]);
    }
  };
  const _handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        icon && styles.icon,
        isFocused && styles.focused,
        error && styles.error,
        disabled && styles.disabled,
        className,
      )}
    >
      {icon && <Icon icon={icon} />}
      {error && (
        <div
          ref={errorWrapper}
          className={styles.error}
          onMouseEnter={_handleMouseEnter}
          onMouseLeave={_handleMouseLeave}
        >
          <Icon icon='alert-circle' />
          {visible && <Popup value={error} position={position} />}
        </div>
      )}
      <div className={styles.chevron}>
        <Icon icon='chevron-down' />
      </div>
      <select
        onFocus={_handleFocus(true)}
        onBlur={_handleFocus(false)}
        onChange={_handleChange}
        value={value ?? 'null'}
      >
        {options.map((o) => (
          <option key={o.value} disabled={o.disabled} value={o.value ?? 'null'}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
};

interface iPopupProps {
  value: string;
  position: [number, number, number];
}

const Popup: FC<iPopupProps> = ({ value, position }) => {
  const wrapper = document.querySelector('#popup');

  if (!wrapper) return null;
  return createPortal(
    <div
      className={styles.popup}
      style={{ top: position[1], left: position[0] }}
    >
      {value}
    </div>,
    wrapper,
  );
};

export default Select;
