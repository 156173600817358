import React from 'react';

export default function IconInformation(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-information</title>
      <g
        id='icon-information'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M13,10 C13.6643789,10 14.1440429,10.6359049 13.9615239,11.2747211 L12.32573,17 L13,17 C13.5522847,17 14,17.4477153 14,18 C14,18.5522847 13.5522847,19 13,19 L11,19 C10.3356211,19 9.85595714,18.3640951 10.0384761,17.7252789 L11.67427,12 L11,12 C10.4477153,12 10,11.5522847 10,11 C10,10.4477153 10.4477153,10 11,10 L13,10 Z M13,5 C13.8284271,5 14.5,5.67157288 14.5,6.5 C14.5,7.32842712 13.8284271,8 13,8 C12.1715729,8 11.5,7.32842712 11.5,6.5 C11.5,5.67157288 12.1715729,5 13,5 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
