import React from 'react';

export default function IconDocumentAdd(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-document-add</title>
      <g
        id='icon-document-add'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6,2 L12,2 L12,2 L12,8 C12,9.1045695 12.8954305,10 14,10 L20,10 L20,10 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M13,15 L15,15 C15.5522847,15 16,15.4477153 16,16 C16,16.5522847 15.5522847,17 15,17 L13,17 L13,19 C13,19.5522847 12.5522847,20 12,20 C11.4477153,20 11,19.5522847 11,19 L11,17 L9,17 C8.44771525,17 8,16.5522847 8,16 C8,15.4477153 8.44771525,15 9,15 L11,15 L11,13 C11,12.4477153 11.4477153,12 12,12 C12.5522847,12 13,12.4477153 13,13 L13,15 Z'
          id='secondary'
          fill='currentColor'
        />
        <polygon id='secondary' fill='currentColor' points='14 2 20 8 14 8' />
      </g>
    </svg>
  );
}
