import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import activateStyles from '../styles/pages/activate.module.scss';
import { useHistory, useLocation } from 'react-router';
import { AuthSkeleton } from '../layout';
import { Button } from '../components';
import network from '../core/network';
import { useUser } from '../hooks';

const Confirm: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { setUser } = useUser();

  const params = new URLSearchParams(location.search);
  const token = String(params.get('token'));
  const user = String(params.get('user'));
  const firstname = String(params.get('name'));
  const { t } = useTranslation('confirm');

  const _handleConfirm = async () => {
    const res = await network.user.verifyEmail(user, token);
    if (res.user) setUser({ ...res.user, token: res.token });
    else history.replace('/login');
  };

  useEffect(() => {
    _handleConfirm();
  }, []);
  const _handleSubmit = async () => {
    history.push('/');
  };
  return (
    <AuthSkeleton
      pageTitle={t('page_title')}
      title={t('meta_title').replace('__FIRSTNAME__', firstname)}
      subtitle={t('meta_subtitle').replace('__FIRSTNAME__', firstname)}
      reverse
    >
      <main className={activateStyles.wrapper}>
        <img draggable={false} src='/images/logo/triyit-trademark.png' alt='' />
        <h1>{t('title').replace('__FIRSTNAME__', firstname)}</h1>
        <h2>{t('subtitle')}</h2>
        <ul>
          <li>{t('step_1')}</li>
          <li>{t('step_2')}</li>
          <li>{t('step_3')}</li>
        </ul>
        <Button primary onClick={_handleSubmit}>
          {t('submit')}
        </Button>
      </main>
    </AuthSkeleton>
  );
};

export default Confirm;
