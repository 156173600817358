import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Segment, Select } from '../components';
import { useGroups, useModal, useSurveys } from '../hooks';
import Actions from '../layout/Modal/Actions';
import ModalBody from '../layout/Modal/Body';

interface iWhitelistWizardProps {
  name?: string;
  user_group_id?: string;
  user_ids?: string[];
  survey_id?: string;
}

const WhitelistWizard: FC<iWhitelistWizardProps> = ({
  name,
  user_ids,
  user_group_id,
  survey_id,
}) => {
  const { groups } = useGroups();
  const { surveys } = useSurveys();
  const { t } = useTranslation(['campaign', 'alert', 'common']);
  const { dismissModal } = useModal();

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      survey_id: survey_id ?? '',
      user_ids: (user_ids ?? []).join('\n'),
      user_group_id,
    },

    onSubmit: (values) => {
      const confirmed = !window.confirm(
        'Are you sure you want to send the confirmation email to these users? This action can not be undone.',
      );
      if (confirmed) return;

      // todo: add network layer
      dismissModal();
    },
  });

  /**
   * Update regular text fields
   */
  const _handleChange = useCallback(
    (val: string, name?: string) => {
      if (name === 'user_group_id') {
        const group = groups?.find((g) => g.id === val);
        formik.setFieldValue('user_ids', group?.user_ids.join('\n'));
      } else if (name === 'user_ids') formik.setFieldValue('user_group_id', '');

      formik.setFieldValue(name ?? '', val);
    },
    [JSON.stringify(groups)],
  );

  /**
   * Generate modal actions
   */
  const actions = useMemo(() => {
    return [
      { label: 'Cancel', onClick: dismissModal, link: true },
      [{ label: 'Send', onClick: formik.handleSubmit, primary: true }],
    ];
  }, [formik]);

  const groupOptions = useMemo(
    () => [
      { label: 'Select a user group', value: null },
      ...groups.map((group) => ({
        label: group.name,
        value: group.id,
      })),
    ],
    [JSON.stringify(groups)],
  );
  const surveyOptions = useMemo(
    () => [
      { label: 'Select a survey', value: null },
      ...surveys
        ?.filter((survey) => !!survey.typeform_id)
        .map((survey) => ({ label: survey.title, value: survey.id })),
    ],
    [JSON.stringify(surveys)],
  );

  return (
    <>
      <ModalBody>
        <Segment title='Name of whitelist'>
          <Input
            onChange={_handleChange}
            name='name'
            value={formik.values.name}
            placeholder='Coca cola products whitelist'
          />
        </Segment>
        {!user_group_id && (
          <Segment title='Select user group'>
            <Select
              options={groupOptions}
              onChange={_handleChange}
              name='user_group_id'
              value={formik.values.user_group_id ?? null}
            />
          </Segment>
        )}
        <Segment title={t('users')}>
          <Input
            textarea
            onChange={_handleChange}
            name='user_ids'
            value={formik.values.user_ids}
            disabled={!!user_ids}
          />
        </Segment>
        <Segment title='Select survey (Only typeform-based surveys are  supported)'>
          <Select
            options={surveyOptions}
            onChange={_handleChange}
            name='survey_id'
            value={formik.values.survey_id ?? null}
          />
        </Segment>
      </ModalBody>
      <Actions actions={actions} />
    </>
  );
};

export default WhitelistWizard;
