import React from 'react';

export default function IconClickTarget(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-click-target</title>
      <g
        id='icon-click-target'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,12.2442015 21.9912467,12.4863585 21.9740367,12.7261745 C21.7165402,12.50757 21.409906,12.3247133 21.0533703,12.1910125 L19.9974254,11.7950331 C19.8886946,7.47147948 16.3497511,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.3497511 7.47147948,19.8886946 11.7950331,19.9974254 L12.1910125,21.0533703 C12.3247133,21.409906 12.50757,21.7165402 12.7261745,21.9740367 C12.4863585,21.9912467 12.2442015,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,6 C14.9785395,6 17.4501649,8.17035745 17.9196838,11.01588 L15.5317843,10.1204177 C14.8589199,8.85872182 13.5297426,8 12,8 C9.790861,8 8,9.790861 8,12 C8,13.5297426 8.85872182,14.8589199 10.1204177,15.5317843 L11.01588,17.9196838 C8.17035745,17.4501649 6,14.9785395 6,12 C6,8.6862915 8.6862915,6 12,6 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M17.955992,16.5417784 L21.7071068,20.2928932 C22.0976311,20.6834175 22.0976311,21.3165825 21.7071068,21.7071068 C21.3165825,22.0976311 20.6834175,22.0976311 20.2928932,21.7071068 L16.5417784,17.955992 L15.9701425,20.2425356 C15.7321441,21.1945291 14.4082256,21.2699362 14.0636708,20.3511234 L11.0636708,12.3511234 C10.7622134,11.5472371 11.5472371,10.7622134 12.3511234,11.0636708 L20.3511234,14.0636708 C21.2699362,14.4082256 21.1945291,15.7321441 20.2425356,15.9701425 L17.955992,16.5417784 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
