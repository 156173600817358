import React from 'react';

export default function IconChatGroup(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-chat-group</title>
      <g
        id='icon-chat-group'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M14,2 C17.8659932,2 21,5.13400675 21,9 C21,10.0877054 20.7519151,11.1174675 20.309242,12.0357895 L21.3126443,15.0477333 C21.5732303,15.8294913 20.8294913,16.5732303 20.0477333,16.3126443 L17.0357895,15.309242 C16.1174675,15.7519151 15.0877054,16 14,16 C10.1340068,16 7,12.8659932 7,9 C7,5.13400675 10.1340068,2 14,2 Z M11,8 C10.4477153,8 10,8.44771525 10,9 C10,9.55228475 10.4477153,10 11,10 C11.5522847,10 12,9.55228475 12,9 C12,8.44771525 11.5522847,8 11,8 Z M14,8 C13.4477153,8 13,8.44771525 13,9 C13,9.55228475 13.4477153,10 14,10 C14.5522847,10 15,9.55228475 15,9 C15,8.44771525 14.5522847,8 14,8 Z M17,8 C16.4477153,8 16,8.44771525 16,9 C16,9.55228475 16.4477153,10 17,10 C17.5522847,10 18,9.55228475 18,9 C18,8.44771525 17.5522847,8 17,8 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15.8772151,17.8039391 C14.6296654,19.7274906 12.463438,21 10,21 C8.91201426,21 7.88200172,20.7517872 6.96350049,20.3088997 L3.95226674,21.3126443 C3.17050871,21.5732303 2.42676966,20.8294913 2.68735567,20.0477333 L3.69110026,17.0364995 C3.24821279,16.1179983 3,15.0879857 3,14 C3,12.0924128 3.76303868,10.3630428 5.00054909,9.10045699 C5.05440375,14.0247403 9.06296605,18 14,18 C14.6437613,18 15.2717361,17.9324099 15.8772151,17.8039391 L15.8772151,17.8039391 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
