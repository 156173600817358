import React from 'react';

export default function IconAirplane(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-airplane</title>
      <g
        id='icon-airplane'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,11.9047648 L21.6940285,13.0390575 C21.8794355,13.1171212 22,13.2986955 22,13.499861 L22,15.4998301 C22,15.8174065 21.7077687,16.0545139 21.3969948,15.9890899 L12,14.0108363 L2.60300524,15.9890899 C2.29223132,16.0545139 2,15.8174065 2,15.4998301 L2,13.499861 C2,13.2986955 2.12056453,13.1171212 2.3059715,13.0390575 L5,11.9047648 L5,10 C5,9.44771525 5.44771525,9 6,9 C6.55228475,9 7,9.44771525 7,10 L7,11.0626855 L11.8059715,9.0391811 C11.9300488,8.98693963 12.0699512,8.98693963 12.1940285,9.0391811 L17,11.0626855 L17,10 C17,9.44771525 17.4477153,9 18,9 C18.5522847,9 19,9.44771525 19,10 L19,11.9047648 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
        <path
          d='M12,2 C13.1045695,2 14,2.8954305 14,4 L14,12 L14,12 L13,20 L11,20 L10,12 L10,4 C10,2.8954305 10.8954305,2 12,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M8.63736056,22.9782879 C8.31795246,23.0796804 8,22.8132184 8,22.444144 L8,21.333108 C8,21.1537028 8.07798284,20.9853415 8.2093809,20.8810644 L11.7093809,18.1034744 C11.8832297,17.9655085 12.1167703,17.9655085 12.2906191,18.1034744 L15.7906191,20.8810644 C15.9220172,20.9853415 16,21.1537028 16,21.333108 L16,22.444144 C16,22.8132184 15.6820475,23.0796804 15.3626394,22.9782879 L12,21.9108554 L8.63736056,22.9782879 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
