import React from 'react';

export default function IconCheveronSelection(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cheveron-selection</title>
      <g
        id='icon-cheveron-selection'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M8.70710678,14.2928932 L12,17.5857864 L15.2928932,14.2928932 C15.6834175,13.9023689 16.3165825,13.9023689 16.7071068,14.2928932 C17.0976311,14.6834175 17.0976311,15.3165825 16.7071068,15.7071068 L12.7071068,19.7071068 C12.3165825,20.0976311 11.6834175,20.0976311 11.2928932,19.7071068 L7.29289322,15.7071068 C6.90236893,15.3165825 6.90236893,14.6834175 7.29289322,14.2928932 C7.68341751,13.9023689 8.31658249,13.9023689 8.70710678,14.2928932 Z M12.7071068,4.29289322 L16.7071068,8.29289322 C17.0976311,8.68341751 17.0976311,9.31658249 16.7071068,9.70710678 C16.3165825,10.0976311 15.6834175,10.0976311 15.2928932,9.70710678 L12,6.41421356 L8.70710678,9.70710678 C8.31658249,10.0976311 7.68341751,10.0976311 7.29289322,9.70710678 C6.90236893,9.31658249 6.90236893,8.68341751 7.29289322,8.29289322 L11.2928932,4.29289322 C11.6834175,3.90236893 12.3165825,3.90236893 12.7071068,4.29289322 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
