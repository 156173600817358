import React from 'react';

export default function IconChart(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-chart</title>
      <g
        id='icon-chart'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M16,7 C15.4477153,7 15,7.44771525 15,8 L15,8 L15,16 C15,16.5522847 15.4477153,17 16,17 C16.5522847,17 17,16.5522847 17,16 L17,16 L17,8 C17,7.44771525 16.5522847,7 16,7 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M8,11 C8.55228475,11 9,11.4477153 9,12 L9,16 C9,16.5522847 8.55228475,17 8,17 C7.44771525,17 7,16.5522847 7,16 L7,12 C7,11.4477153 7.44771525,11 8,11 Z M12,9 C12.5522847,9 13,9.44771525 13,10 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,10 C11,9.44771525 11.4477153,9 12,9 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
