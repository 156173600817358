import React from 'react';

export default function IconFolder(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-folder</title>
      <g
        id='icon-folder'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M22,10 L2,10 L2,6 C2,4.8954305 2.8954305,4 4,4 L11,4 L13,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,10 Z'
          id='Path'
          fill='currentColor'
        />
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={2}
          y={8}
          width={20}
          height={12}
          rx={2}
        />
      </g>
    </svg>
  );
}
