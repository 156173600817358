import React from 'react';

export default function IconPresentationPlay(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-presentation-play</title>
      <g
        id='icon-presentation-play'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M11,18.618034 L4.4472136,21.8944272 C3.9532351,22.1414164 3.35256206,21.9411921 3.10557281,21.4472136 C2.85858356,20.9532351 3.05880791,20.3525621 3.5527864,20.1055728 L11,16.381966 L11,5 C11,4.44771525 11.4477153,4 12,4 C12.5522847,4 13,4.44771525 13,5 L13,16.381966 L20.4472136,20.1055728 C20.9411921,20.3525621 21.1414164,20.9532351 20.8944272,21.4472136 C20.6474379,21.9411921 20.0467649,22.1414164 19.5527864,21.8944272 L13,18.618034 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,18.618034 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M21,14 C21.5522847,14 22,14.4477153 22,15 C22,15.5522847 21.5522847,16 21,16 L3,16 C2.44771525,16 2,15.5522847 2,15 C2,14.4477153 2.44771525,14 3,14 L3,4 C2.44771525,4 2,3.55228475 2,3 C2,2.44771525 2.44771525,2 3,2 L21,2 C21.5522847,2 22,2.44771525 22,3 C22,3.55228475 21.5522847,4 21,4 L21,14 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15.5144958,8.14250707 C16.1618347,8.53091047 16.1618347,9.46908953 15.5144958,9.85749293 L10.5144958,12.8574929 C9.84797124,13.2574076 9,12.7772945 9,12 L9,6 C9,5.22270552 9.84797124,4.74259237 10.5144958,5.14250707 L15.5144958,8.14250707 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
