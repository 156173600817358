import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useFetch from 'use-http';
import { useGroups, useModal, useUser } from '.';
import { network } from '../core';
import { CampaignModal } from '../modals';
import { iCampaign } from '../types';

export const useCampaigns = () => {
  const { groups, loading: groupsLoading } = useGroups();
  const { user } = useUser();
  const req = useFetch('/campaign');
  const [campaigns, setCampaigns] = useState<iCampaign[] | null>(null);
  const campaignsRef = useRef<iCampaign[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { presentModal } = useModal();

  /**
   * Load user groups
   */
  const _handleLoad = useCallback(async () => {
    const res = await req.get();
    setLoading(false);
    if (res?.campaigns) setCampaigns(res.campaigns);
  }, []);

  /**
   * Open usergroup in modal
   */
  const _handleOpenGroup = useCallback(
    (id: string | iCampaign) => {
      const campaign =
        typeof id === 'string'
          ? campaignsRef.current?.find((c) => c.id === id)
          : id;
      if (!campaign) return;

      presentModal({
        title: campaign.name,
        subtitle: 'Edit campaign',
        content: (
          <CampaignModal
            {...{
              ...campaign,
              groups: groups ?? [],
              onUpdate: _handleUpdate,
              onDelete: _handleDelete,
            }}
          />
        ),
      });
    },
    [JSON.stringify(groups)],
  );

  /**
   * Update group by id
   */
  const _handleCreate = useCallback(async () => {
    if (!campaignsRef.current || !user) return;
    const campaign = await network.campaign.createCampaign(
      'Unnamed campaign',
      user?.token,
    );
    if (!campaign) return window.alert('Failed to campaign');

    // // update in list
    const _campaigns = [...campaignsRef.current];
    _campaigns.push(campaign);
    setCampaigns(_campaigns);
  }, []);

  /**
   * Update group by id
   */
  const _handleUpdate = useCallback(async (id: string, body: iCampaign) => {
    if (!campaignsRef.current || !user) return;
    network.campaign.updateCampaign(id, body, user.token);

    // update in list
    const _campaigns = [...campaignsRef.current];
    const _index = _campaigns.findIndex((g) => g.id === id);
    _campaigns[_index] = body;
    setCampaigns(_campaigns);
  }, []);

  /**
   * Delete group by id
   */
  const _handleDelete = useCallback(async (id: string) => {
    if (!campaignsRef.current || !user) return;
    network.campaign.deleteCampaign(id, user.token);

    // update in list
    const _campaigns = [...campaignsRef.current];
    const _index = _campaigns.findIndex((g) => g.id === id);
    _campaigns.splice(_index, 1);
    setCampaigns(_campaigns);
  }, []);

  // Load groups
  useEffect(() => {
    _handleLoad();
  }, []);

  // Update group ref
  useEffect(() => {
    campaignsRef.current = campaigns ?? [];
  }, [JSON.stringify(campaigns)]);

  const actives = useMemo(() => {
    return campaigns?.filter((c) => c.is_active) ?? [];
  }, [JSON.stringify(campaigns)]);

  const inactives = useMemo(() => {
    return campaigns?.filter((c) => !c.is_active) ?? [];
  }, [JSON.stringify(campaigns)]);

  const statuses = useMemo(() => {
    return Array.from(new Set(campaigns?.map((c) => c.status)));
  }, [JSON.stringify(campaigns)]);

  return {
    campaigns: campaigns ?? [],
    inactives,
    statuses,
    actives,
    loading: loading || groupsLoading,
    createCampaign: _handleCreate,
    updateGroup: _handleUpdate,
    deleteGroup: _handleDelete,
    openModal: _handleOpenGroup,
  };
};
