import React from 'react';

export default function IconNotificationOff(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-notification-off</title>
      <g
        id='icon-notification-off'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M14.7573593,19 L14.9910338,19.2336745 C14.8718012,20.7813211 13.5782194,22 12,22 C10.3431458,22 9,20.6568542 9,19 L14.7573593,19 Z M3.29289322,4.70710678 C2.90236893,4.31658249 2.90236893,3.68341751 3.29289322,3.29289322 C3.68341751,2.90236893 4.31658249,2.90236893 4.70710678,3.29289322 L20.7071068,19.2928932 C21.0976311,19.6834175 21.0976311,20.3165825 20.7071068,20.7071068 C20.3165825,21.0976311 19.6834175,21.0976311 19.2928932,20.7071068 L3.29289322,4.70710678 Z'
          id='Shape'
          fill='currentColor'
        />
        <path
          d='M5.17873344,9.42137413 L14.7573593,19 L4,19 C3.44771525,19 3,18.5522847 3,18 C3,17.4477153 3.44771525,17 4,17 C4.55228475,17 5,16.5522847 5,16 L5,11 C5,10.4571416 5.06179464,9.92871619 5.17873344,9.42137413 Z M12,2 C13.1045695,2 14,2.8954305 14,4 C14,4.09639761 13.9931801,4.19120234 13.9799965,4.28395803 C16.8817592,5.13808456 19,7.82167146 19,11 L19,14.7573593 L8.943449,4.70080831 C9.2874879,4.53356052 9.64728225,4.39366759 10.0200035,4.28395803 C10.0068199,4.19120234 10,4.09639761 10,4 C10,2.8954305 10.8954305,2 12,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
