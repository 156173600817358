import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Segment, Select } from '../components';
import { useModal, useSurveys, useWhitelists } from '../hooks';
import { Row } from '../layout';
import Actions from '../layout/Modal/Actions';
import ModalBody from '../layout/Modal/Body';
import { iCampaign, iUserGroup } from '../types';

interface iCampaignProps extends iCampaign {
  groups: iUserGroup[];
  onUpdate: (id: string, body: iCampaign) => void;
  onDelete: (id: string) => void;
}

const Campaign: FC<iCampaignProps> = ({
  name,
  description,
  id,
  user_ids,
  onUpdate,
  onDelete,
  start_date = '',
  end_date = '',
  user_group_id,
  status,
}) => {
  const { t } = useTranslation(['campaign', 'alert', 'common']);
  const { dismissModal } = useModal();
  const { surveys } = useSurveys();
  const { whitelists } = useWhitelists();

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      description: description ?? '',
      whitelist_id: '',
      start_date: start_date.split('T')?.[0],
      end_date: end_date.split('T')?.[0],
      products: [{ name: 'Coca cola', typeform_id: 'WLK098' }],
    },

    onSubmit: (values) => {
      // onUpdate(id ?? '', {
      //   id,
      //   status,
      //   ...values,
      //   user_group_id: values.user_group_id || undefined,
      //   user_ids: values.user_ids.split('\n').filter((u) => u.length > 0),
      // });
      dismissModal();
    },
  });

  /**
   * Update regular text fields
   */
  const _handleChange = useCallback((val: string, name?: string) => {
    // Set actual field
    formik.setFieldValue(name ?? '', val);
  }, []);

  /**
   * Asks for permission and then deletes the user group
   */
  const _handleDelete = useCallback(() => {
    if (!window.confirm(t('alert:delete'))) return;
    onDelete(id ?? '');
    dismissModal();
  }, []);
  /**
   * Asks for permission and then initialises the campaign
   */
  const _handleInitialise = useCallback(() => {
    if (!window.confirm(t('alert:initialisation'))) return;

    onUpdate(id ?? '', { id, status: 'INITIALISED', name: formik.values.name });
    dismissModal();
  }, []);

  const _handleStart = useCallback(() => {
    if (!window.confirm(t('alert:delete'))) return;

    onUpdate(id ?? '', { id, status: 'ACTIVE', name: formik.values.name });
    dismissModal();
  }, []);
  const _handleFinish = useCallback(() => {
    if (!window.confirm(t('alert:finish'))) return;

    onUpdate(id ?? '', { id, status: 'FINISHED', name: formik.values.name });
    dismissModal();
  }, []);

  //TODO: add state change network requests to actually start the campaign

  /**
   * Generate modal actions
   */
  const actions = useMemo(() => {
    return [
      { label: t('common:cancel'), onClick: dismissModal, link: true },
      [
        { label: t('common:delete'), onClick: _handleDelete },
        status === 'PENDING' && {
          label: t('common:initiate'),
          onClick: _handleInitialise,
        },
        status === 'INITIALISED' && {
          label: t('common:start'),
          onClick: _handleStart,
        },
        status === 'ACTIVE' && {
          label: t('common:finish'),
          onClick: _handleFinish,
        },
        status === 'PENDING' && {
          label: t('common:save'),
          primary: true,
          onClick: formik.handleSubmit,
        },
      ],
    ];
  }, [formik]);

  const whitelistOptions = useMemo(
    () => [
      { label: 'Select a whitelist', value: null, disabled: true },
      ...whitelists.map((whitelist) => ({
        label: whitelist.name ?? 'unnamed whitelist',
        value: whitelist.id,
      })),
    ],
    [whitelists.length],
  );

  const surveyOptions = useMemo(
    () => [
      { label: 'Select a survey', value: null },
      ...surveys
        ?.filter((survey) => !!survey.typeform_id)
        .map((survey) => ({ label: survey.title, value: survey.id })),
    ],
    [JSON.stringify(surveys)],
  );

  const _handleProduct =
    (index: number) => (name: string, typeform_id: string) => {
      const products = [...formik.values.products];
      if (!name && !typeform_id) {
        products.splice(index, 1);
      } else {
        products[index] = { name, typeform_id };
      }

      formik.setFieldValue('products', products);
    };

  return (
    <>
      <ModalBody>
        <Segment title={t('name')}>
          <Input
            disabled={status !== 'PENDING'}
            onChange={_handleChange}
            name='name'
            value={formik.values.name}
          />
        </Segment>
        <Segment title='Attach whitelist'>
          <Select
            options={whitelistOptions}
            disabled={status !== 'PENDING'}
            onChange={_handleChange}
            name='whitelist_id'
            value={formik.values.whitelist_id ?? null}
          />
        </Segment>
        <Row>
          <Segment title={t('starting')}>
            <Input
              disabled={status !== 'PENDING'}
              type='date'
              onChange={_handleChange}
              name='start_date'
              value={formik.values.start_date}
            />
          </Segment>
          <Segment title={t('ending')}>
            <Input
              disabled={status !== 'PENDING'}
              type='date'
              onChange={_handleChange}
              name='end_date'
              value={formik.values.end_date}
            />
          </Segment>
        </Row>
        <Segment title='Products' collapsible>
          {[...formik.values.products, { name: '', typeform_id: '' }].map(
            (product, i) => (
              <Product
                key={`product-${i}`}
                name={product.name}
                typeform_id={product.typeform_id}
                onChange={_handleProduct(i)}
                disabled={status !== 'PENDING'}
                surveyOptions={surveyOptions}
              />
            ),
          )}
        </Segment>
      </ModalBody>
      <Actions actions={actions} />
    </>
  );
};

interface iProductProps {
  name: string;
  typeform_id: string;
  disabled: boolean;
  onChange: (name: string, typeform_id: string) => void;
  surveyOptions: {
    value: string | null;
    label: string;
    disabled?: boolean | undefined;
  }[];
}
const Product: FC<iProductProps> = ({
  name,
  typeform_id,
  onChange,
  disabled,
  surveyOptions,
}) => {
  const _handleChange = (value: string, field?: string) => {
    if (field === 'name') onChange(value, typeform_id);
    else {
      // https://qfx5ygg0x8x.typeform.com/to/wq0y7woL
      const id = value.split('/').pop() ?? value;
      onChange(name, id);
    }
  };
  return (
    <Row>
      <Input
        disabled={disabled}
        placeholder='Product name'
        onChange={_handleChange}
        name='name'
        value={name}
      />
      <Select
        options={surveyOptions}
        onChange={_handleChange}
        name='survey_id'
        value={typeform_id ?? null}
      />
    </Row>
  );
};

export default Campaign;
