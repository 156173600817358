import React from 'react';

export default function IconHistory(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-history</title>
      <g
        id='icon-history'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6.55397341,6.13975985 L7.70710678,7.29289322 C8.33707164,7.92285808 7.89090485,9 7,9 L3,9 C2.44771525,9 2,8.55228475 2,8 L2,4 C2,3.10909515 3.07714192,2.66292836 3.70710678,3.29289322 L5.13901382,4.72480025 C9.05803833,1.02491806 15.2350977,1.09296204 19.0710678,4.92893219 C22.9763107,8.83417511 22.9763107,15.1658249 19.0710678,19.0710678 C15.1658249,22.9763107 8.83417511,22.9763107 4.92893219,19.0710678 C3.02834129,17.1704769 1.99966275,14.6332862 2,11.9987124 C2,11.4464276 2.44784337,10.9987697 3.00012812,10.9988404 C3.55241286,10.9989111 4.00007078,11.4466837 4,11.9989684 C3.99972999,14.1082803 4.82154662,16.1352551 6.34314575,17.6568542 C9.46734008,20.7810486 14.5326599,20.7810486 17.6568542,17.6568542 C20.7810486,14.5326599 20.7810486,9.46734008 17.6568542,6.34314575 C14.6019939,3.28828542 9.69120932,3.22049012 6.55397341,6.13975985 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15.7071068,14.2928932 C16.0976311,14.6834175 16.0976311,15.3165825 15.7071068,15.7071068 C15.3165825,16.0976311 14.6834175,16.0976311 14.2928932,15.7071068 L11.2928932,12.7071068 C11.1053568,12.5195704 11,12.2652165 11,12 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11.5857864 L15.7071068,14.2928932 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
