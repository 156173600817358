import React from 'react';

export default function IconSortDecending(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-sort-decending</title>
      <g
        id='icon-sort-decending'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6,11 L6,4 C6,3.44771525 6.44771525,3 7,3 C7.55228475,3 8,3.44771525 8,4 L8,11 L11,11 C11.2559223,11 11.5118446,11.0976311 11.7071068,11.2928932 C12.0976311,11.6834175 12.0976311,12.3165825 11.7071068,12.7071068 L7.70710678,16.7071068 C7.31658249,17.0976311 6.68341751,17.0976311 6.29289322,16.7071068 L2.29289322,12.7071068 C1.90236893,12.3165825 1.90236893,11.6834175 2.29289322,11.2928932 C2.48815536,11.0976311 2.74407768,11 3,11 L6,11 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M21,21 L8,21 C7.44771525,21 7,20.5522847 7,20 C7,19.4477153 7.44771525,19 8,19 L21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 Z M21,17 L12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 L21,15 C21.5522847,15 22,15.4477153 22,16 C22,16.5522847 21.5522847,17 21,17 Z M21,13 L16,13 C15.4477153,13 15,12.5522847 15,12 C15,11.4477153 15.4477153,11 16,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
