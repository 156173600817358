import React from 'react';

export default function IconCheveronLeftCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cheveron-left-circle</title>
      <g
        id='icon-cheveron-left-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M13.7071068,15.2928932 C14.0976311,15.6834175 14.0976311,16.3165825 13.7071068,16.7071068 C13.3165825,17.0976311 12.6834175,17.0976311 12.2928932,16.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 L12.2928932,7.29289322 C12.6834175,6.90236893 13.3165825,6.90236893 13.7071068,7.29289322 C14.0976311,7.68341751 14.0976311,8.31658249 13.7071068,8.70710678 L10.4142136,12 L13.7071068,15.2928932 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
