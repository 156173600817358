import { FC, useCallback } from 'react';
import { iSurvey } from '../types';
import styles from '../styles/components/survey-banner.module.scss';
import { useHistory } from 'react-router';
interface iSurveyProps extends iSurvey {
  progress: number | null;
  status: number | null;
}

// const statuses: { [key: string]: string } = {
//   IN_PROGRESS: 'Survey in progress ({{PERCENTAGE}}%)',
//   COMPLETED: 'Survey is already completed',
//   TODO: "Survey hasn't been started yet.",
// };
const SurveyBanner: FC<iSurveyProps> = ({
  title,
  subtitle,
  progress,
  short_id,
}) => {
  const history = useHistory();
  const _handleClick = useCallback(
    () => history.push(`/survey/${short_id}`),
    [short_id],
  );

  const actualProgress = (progress ?? 0) * 100;
  return (
    <div tabIndex={0} className={styles.wrapper} onClick={_handleClick}>
      <span className={styles.title}>{title}</span>
      <p>{subtitle}</p>
      <div className={styles.progressWrapper}>
        <div
          className={styles.progress}
          style={{ width: actualProgress + '%' }}
        />
      </div>
    </div>
  );
};

export default SurveyBanner;
