import React from 'react';

export default function IconShoppingBag(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-shopping-bag</title>
      <g
        id='icon-shopping-bag'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M5,8 L19,8 C19.5200886,8 19.9533547,8.3986624 19.9965458,8.91695452 L20.9965458,20.9169545 C21.0451307,21.4999738 20.5850401,22 20,22 L4,22 C3.41495985,22 2.9548693,21.4999738 3.00345424,20.9169545 L4.00345424,8.91695452 C4.04664525,8.3986624 4.47991136,8 5,8 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M9,10 C9,10.5522847 8.55228475,11 8,11 C7.44771525,11 7,10.5522847 7,10 L7,7 C7,4.23857625 9.23857625,2 12,2 C14.7614237,2 17,4.23857625 17,7 L17,10 C17,10.5522847 16.5522847,11 16,11 C15.4477153,11 15,10.5522847 15,10 L15,7 C15,5.34314575 13.6568542,4 12,4 C10.3431458,4 9,5.34314575 9,7 L9,10 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
