import React from 'react';

export default function IconOrderHorizontal(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-order-horizontal</title>
      <g
        id='icon-order-horizontal'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M18.5857864,17 L9,17 C8.44771525,17 8,16.5522847 8,16 C8,15.4477153 8.44771525,15 9,15 L18.5857864,15 L16.2928932,12.7071068 C15.9023689,12.3165825 15.9023689,11.6834175 16.2928932,11.2928932 C16.6834175,10.9023689 17.3165825,10.9023689 17.7071068,11.2928932 L21.7071068,15.2928932 C22.0976311,15.6834175 22.0976311,16.3165825 21.7071068,16.7071068 L17.7071068,20.7071068 C17.3165825,21.0976311 16.6834175,21.0976311 16.2928932,20.7071068 C15.9023689,20.3165825 15.9023689,19.6834175 16.2928932,19.2928932 L18.5857864,17 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M5.41421356,7 L15,7 C15.5522847,7 16,7.44771525 16,8 C16,8.55228475 15.5522847,9 15,9 L5.41421356,9 L7.70710678,11.2928932 C8.09763107,11.6834175 8.09763107,12.3165825 7.70710678,12.7071068 C7.31658249,13.0976311 6.68341751,13.0976311 6.29289322,12.7071068 L2.29289322,8.70710678 C1.90236893,8.31658249 1.90236893,7.68341751 2.29289322,7.29289322 L6.29289322,3.29289322 C6.68341751,2.90236893 7.31658249,2.90236893 7.70710678,3.29289322 C8.09763107,3.68341751 8.09763107,4.31658249 7.70710678,4.70710678 L5.41421356,7 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
