import classNames from 'classnames';
import { FC } from 'react';
import styles from '../styles/components/status.module.scss';

interface iStatusProps {
  type?: string;
}

const Status: FC<iStatusProps> = ({ children, type }) => {
  return (
    <span
      className={classNames(
        styles.wrapper,
        styles[type ?? (children as string)],
      )}
    >
      {String(children).replace('_', ' ')}
    </span>
  );
};

export default Status;
