import React from 'react';

export default function IconUserCouple(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-user-couple</title>
      <g
        id='icon-user-couple'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M17,13 C19.7614237,13 22,15.2385763 22,18 L22,18 L22,19 C22,20.1045695 21.1045695,21 20,21 L20,21 L10,21 C8.8954305,21 8,20.1045695 8,19 L8,19 L8,18 C8,15.2385763 10.2385763,13 13,13 L13,13 Z M15,3 C17.209139,3 19,4.790861 19,7 C19,9.209139 17.209139,11 15,11 C12.790861,11 11,9.209139 11,7 C11,4.790861 12.790861,3 15,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M11,13 C13.7614237,13 16,15.2385763 16,18 L16,18 L16,19 C16,20.1045695 15.1045695,21 14,21 L14,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,19 L2,18 C2,15.2385763 4.23857625,13 7,13 L7,13 Z M9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
