import { FC } from 'react';

// @ts-ignore
import FeatherIcon from 'feather-icons-react';

// Custom icons
import IconAdd from '../assets/icons/IconAdd';
import IconAddCircle from '../assets/icons/IconAddCircle';
import IconAddSquare from '../assets/icons/IconAddSquare';
import IconAirplane from '../assets/icons/IconAirplane';
import IconAnnouncement from '../assets/icons/IconAnnouncement';
import IconApplication from '../assets/icons/IconApplication';
import IconArchive from '../assets/icons/IconArchive';
import IconArrowThickDownCircle from '../assets/icons/IconArrowThickDownCircle';
import IconArrowThickLeftCircle from '../assets/icons/IconArrowThickLeftCircle';
import IconArrowThickRightCircle from '../assets/icons/IconArrowThickRightCircle';
import IconArrowThickUpCircle from '../assets/icons/IconArrowThickUpCircle';
import IconArrowThinDownCircle from '../assets/icons/IconArrowThinDownCircle';
import IconArrowThinLeftCircle from '../assets/icons/IconArrowThinLeftCircle';
import IconArrowThinRightCircle from '../assets/icons/IconArrowThinRightCircle';
import IconArrowThinUpCircle from '../assets/icons/IconArrowThinUpCircle';
import IconArrowsMerge from '../assets/icons/IconArrowsMerge';
import IconArrowsSplit from '../assets/icons/IconArrowsSplit';
import IconAsterisk from '../assets/icons/IconAsterisk';
import IconAt from '../assets/icons/IconAt';
import IconAttach from '../assets/icons/IconAttach';
import IconBatteryFull from '../assets/icons/IconBatteryFull';
import IconBatteryHalf from '../assets/icons/IconBatteryHalf';
import IconBolt from '../assets/icons/IconBolt';
import IconBookClosed from '../assets/icons/IconBookClosed';
import IconBookOpen from '../assets/icons/IconBookOpen';
import IconBox from '../assets/icons/IconBox';
import IconBrick from '../assets/icons/IconBrick';
import IconBug from '../assets/icons/IconBug';
import IconBuoy from '../assets/icons/IconBuoy';
import IconCalculator from '../assets/icons/IconCalculator';
import IconCalendar from '../assets/icons/IconCalendar';
import IconCalendarAdd from '../assets/icons/IconCalendarAdd';
import IconCalendarDate from '../assets/icons/IconCalendarDate';
import IconCalendarRemove from '../assets/icons/IconCalendarRemove';
import IconCamera from '../assets/icons/IconCamera';
import IconCertificate from '../assets/icons/IconCertificate';
import IconChart from '../assets/icons/IconChart';
import IconChat from '../assets/icons/IconChat';
import IconChatGroup from '../assets/icons/IconChatGroup';
import IconChatGroupAlt from '../assets/icons/IconChatGroupAlt';
import IconCheck from '../assets/icons/IconCheck';
import IconCheveronDown from '../assets/icons/IconCheveronDown';
import IconCheveronDownCircle from '../assets/icons/IconCheveronDownCircle';
import IconCheveronLeftCircle from '../assets/icons/IconCheveronLeftCircle';
import IconCheveronRightCircle from '../assets/icons/IconCheveronRightCircle';
import IconCheveronSelection from '../assets/icons/IconCheveronSelection';
import IconCheveronUp from '../assets/icons/IconCheveronUp';
import IconCheveronUpCircle from '../assets/icons/IconCheveronUpCircle';
import IconClickTarget from '../assets/icons/IconClickTarget';
import IconClose from '../assets/icons/IconClose';
import IconCloseCircle from '../assets/icons/IconCloseCircle';
import IconCloseSquare from '../assets/icons/IconCloseSquare';
import IconCloudDownload from '../assets/icons/IconCloudDownload';
import IconCloudUpload from '../assets/icons/IconCloudUpload';
import IconClouds from '../assets/icons/IconClouds';
import IconCode from '../assets/icons/IconCode';
import IconCog from '../assets/icons/IconCog';
import IconCollection from '../assets/icons/IconCollection';
import IconCompass from '../assets/icons/IconCompass';
import IconCreditCard from '../assets/icons/IconCreditCard';
import IconCurrencyDollar from '../assets/icons/IconCurrencyDollar';
import IconCurrencyEuro from '../assets/icons/IconCurrencyEuro';
import IconDashboard from '../assets/icons/IconDashboard';
import IconDeliver from '../assets/icons/IconDeliver';
import IconDesktop from '../assets/icons/IconDesktop';
import IconDeviceSmartphone from '../assets/icons/IconDeviceSmartphone';
import IconDeviceTablet from '../assets/icons/IconDeviceTablet';
import IconDiscount from '../assets/icons/IconDiscount';
import IconDocument from '../assets/icons/IconDocument';
import IconDocumentAdd from '../assets/icons/IconDocumentAdd';
import IconDocumentNotes from '../assets/icons/IconDocumentNotes';
import IconDocumentRemove from '../assets/icons/IconDocumentRemove';
import IconDoorEnter from '../assets/icons/IconDoorEnter';
import IconDoorExit from '../assets/icons/IconDoorExit';
import IconDotsHorizontal from '../assets/icons/IconDotsHorizontal';
import IconDotsVertical from '../assets/icons/IconDotsVertical';
import IconDupicate from '../assets/icons/IconDupicate';
import IconEdit from '../assets/icons/IconEdit';
import IconExternalWindow from '../assets/icons/IconExternalWindow';
import IconFactory from '../assets/icons/IconFactory';
import IconFastForward from '../assets/icons/IconFastForward';
import IconFastRewind from '../assets/icons/IconFastRewind';
import IconFilm from '../assets/icons/IconFilm';
import IconFlag from '../assets/icons/IconFlag';
import IconFolder from '../assets/icons/IconFolder';
import IconFolderAdd from '../assets/icons/IconFolderAdd';
import IconFolderRemove from '../assets/icons/IconFolderRemove';
import IconGlobe from '../assets/icons/IconGlobe';
import IconHarddrive from '../assets/icons/IconHarddrive';
import IconHeadphones from '../assets/icons/IconHeadphones';
import IconHeart from '../assets/icons/IconHeart';
import IconHelp from '../assets/icons/IconHelp';
import IconHistory from '../assets/icons/IconHistory';
import IconHome from '../assets/icons/IconHome';
import IconHourGlass from '../assets/icons/IconHourGlass';
import IconIdentification from '../assets/icons/IconIdentification';
import IconImportant from '../assets/icons/IconImportant';
import IconInboxCheck from '../assets/icons/IconInboxCheck';
import IconInboxDownload from '../assets/icons/IconInboxDownload';
import IconInboxFull from '../assets/icons/IconInboxFull';
import IconInboxUpload from '../assets/icons/IconInboxUpload';
import IconInformation from '../assets/icons/IconInformation';
import IconInterface from '../assets/icons/IconInterface';
import IconKey from '../assets/icons/IconKey';
import IconLaunch from '../assets/icons/IconLaunch';
import IconLayers from '../assets/icons/IconLayers';
import IconLibrary from '../assets/icons/IconLibrary';
import IconLight from '../assets/icons/IconLight';
import IconLink from '../assets/icons/IconLink';
import IconLocationPin from '../assets/icons/IconLocationPin';
import IconLock from '../assets/icons/IconLock';
import IconLockOpen from '../assets/icons/IconLockOpen';
import IconMail from '../assets/icons/IconMail';
import IconMap from '../assets/icons/IconMap';
import IconMenu from '../assets/icons/IconMenu';
import IconMicrophone from '../assets/icons/IconMicrophone';
import IconMoney from '../assets/icons/IconMoney';
import IconMonitor from '../assets/icons/IconMonitor';
import IconMoodHappy from '../assets/icons/IconMoodHappy';
import IconMoodNeutral from '../assets/icons/IconMoodNeutral';
import IconMoodSad from '../assets/icons/IconMoodSad';
import IconNews from '../assets/icons/IconNews';
import IconNotification from '../assets/icons/IconNotification';
import IconNotificationOff from '../assets/icons/IconNotificationOff';
import IconOffice from '../assets/icons/IconOffice';
import IconOrderHorizontal from '../assets/icons/IconOrderHorizontal';
import IconOrderVertical from '../assets/icons/IconOrderVertical';
import IconPackage from '../assets/icons/IconPackage';
import IconPaint from '../assets/icons/IconPaint';
import IconPause from '../assets/icons/IconPause';
import IconPhoneIncomingCall from '../assets/icons/IconPhoneIncomingCall';
import IconPhoneOutgoingCall from '../assets/icons/IconPhoneOutgoingCall';
import IconPhoneRing from '../assets/icons/IconPhoneRing';
import IconPhoto from '../assets/icons/IconPhoto';
import IconPieChart from '../assets/icons/IconPieChart';
import IconPin from '../assets/icons/IconPin';
import IconPlay from '../assets/icons/IconPlay';
import IconPresentation from '../assets/icons/IconPresentation';
import IconPresentationPlay from '../assets/icons/IconPresentationPlay';
import IconPrint from '../assets/icons/IconPrint';
import IconPuzzle from '../assets/icons/IconPuzzle';
import IconReceipt from '../assets/icons/IconReceipt';
import IconRefresh from '../assets/icons/IconRefresh';
import IconRemove from '../assets/icons/IconRemove';
import IconRemoveCircle from '../assets/icons/IconRemoveCircle';
import IconRemoveSquare from '../assets/icons/IconRemoveSquare';
import IconScale from '../assets/icons/IconScale';
import IconSearch from '../assets/icons/IconSearch';
import IconSecurity from '../assets/icons/IconSecurity';
import IconSecurityCheck from '../assets/icons/IconSecurityCheck';
import IconSecurityImportant from '../assets/icons/IconSecurityImportant';
import IconSend from '../assets/icons/IconSend';
import IconServer from '../assets/icons/IconServer';
import IconShoppingBag from '../assets/icons/IconShoppingBag';
import IconShoppingBasket from '../assets/icons/IconShoppingBasket';
import IconShoppingCart from '../assets/icons/IconShoppingCart';
import IconSign from '../assets/icons/IconSign';
import IconSortAscending from '../assets/icons/IconSortAscending';
import IconSortDecending from '../assets/icons/IconSortDecending';
import IconStar from '../assets/icons/IconStar';
import IconStop from '../assets/icons/IconStop';
import IconStore from '../assets/icons/IconStore';
import IconSurvey from '../assets/icons/IconSurvey';
import IconSwatch from '../assets/icons/IconSwatch';
import IconTag from '../assets/icons/IconTag';
import IconTarget from '../assets/icons/IconTarget';
import IconTextCursor from '../assets/icons/IconTextCursor';
import IconThermostatFull from '../assets/icons/IconThermostatFull';
import IconThermostatHalf from '../assets/icons/IconThermostatHalf';
import IconThumbsDown from '../assets/icons/IconThumbsDown';
import IconThumbsUp from '../assets/icons/IconThumbsUp';
import IconTicket from '../assets/icons/IconTicket';
import IconTime from '../assets/icons/IconTime';
import IconTranslate from '../assets/icons/IconTranslate';
import IconTrash from '../assets/icons/IconTrash';
import IconTrendingDown from '../assets/icons/IconTrendingDown';
import IconTrendingUp from '../assets/icons/IconTrendingUp';
import IconTrophy from '../assets/icons/IconTrophy';
import IconTune from '../assets/icons/IconTune';
import IconUmbrella from '../assets/icons/IconUmbrella';
import IconUser from '../assets/icons/IconUser';
import IconUserAdd from '../assets/icons/IconUserAdd';
import IconUserCheck from '../assets/icons/IconUserCheck';
import IconUserCircle from '../assets/icons/IconUserCircle';
import IconUserCouple from '../assets/icons/IconUserCouple';
import IconUserGroup from '../assets/icons/IconUserGroup';
import IconUserRemove from '../assets/icons/IconUserRemove';
import IconVideocam from '../assets/icons/IconVideocam';
import IconViewHidden from '../assets/icons/IconViewHidden';
import IconViewVisible from '../assets/icons/IconViewVisible';
import IconVolumeDown from '../assets/icons/IconVolumeDown';
import IconVolumeMute from '../assets/icons/IconVolumeMute';
import IconVolumeUp from '../assets/icons/IconVolumeUp';
import IconWallet from '../assets/icons/IconWallet';
import IconWidgetAdd from '../assets/icons/IconWidgetAdd';
import IconWifi from '../assets/icons/IconWifi';
import IconWifiOff from '../assets/icons/IconWifiOff';
import IconWork from '../assets/icons/IconWork';
import IconZoomIn from '../assets/icons/IconZoomIn';
import IconZoomOut from '../assets/icons/IconZoomOut';

const icons: { [key: string]: any } = {
  'icon-add-circle': IconAdd,
  'icon-add-square': IconAddCircle,
  'icon-add': IconAddSquare,
  'icon-airplane': IconAirplane,
  'icon-announcement': IconAnnouncement,
  'icon-application': IconApplication,
  'icon-archive': IconArchive,
  'icon-arrow-thick-down-circle': IconArrowThickDownCircle,
  'icon-arrow-thick-left-circle': IconArrowThickLeftCircle,
  'icon-arrow-thick-right-circle': IconArrowThickRightCircle,
  'icon-arrow-thick-up-circle': IconArrowThickUpCircle,
  'icon-arrow-thin-down-circle': IconArrowThinDownCircle,
  'icon-arrow-thin-left-circle': IconArrowThinLeftCircle,
  'icon-arrow-thin-right-circle': IconArrowThinRightCircle,
  'icon-arrow-thin-up-circle': IconArrowThinUpCircle,
  'icon-arrows-merge': IconArrowsMerge,
  'icon-arrows-split': IconArrowsSplit,
  'icon-asterisk': IconAsterisk,
  'icon-at': IconAt,
  'icon-attach': IconAttach,
  'icon-battery-full': IconBatteryFull,
  'icon-battery-half': IconBatteryHalf,
  'icon-bolt': IconBolt,
  'icon-book-closed': IconBookClosed,
  'icon-book-open': IconBookOpen,
  'icon-box': IconBox,
  'icon-brick': IconBrick,
  'icon-bug': IconBug,
  'icon-buoy': IconBuoy,
  'icon-calculator': IconCalculator,
  'icon-calendar-add': IconCalendar,
  'icon-calendar-date': IconCalendarAdd,
  'icon-calendar-remove': IconCalendarDate,
  'icon-calendar': IconCalendarRemove,
  'icon-camera': IconCamera,
  'icon-certificate': IconCertificate,
  'icon-chart': IconChart,
  'icon-chat-group-alt': IconChat,
  'icon-chat-group': IconChatGroup,
  'icon-chat': IconChatGroupAlt,
  'icon-check': IconCheck,
  'icon-cheveron-down-circle': IconCheveronDown,
  'icon-cheveron-down': IconCheveronDownCircle,
  'icon-cheveron-left-circle': IconCheveronLeftCircle,
  'icon-cheveron-right-circle': IconCheveronRightCircle,
  'icon-cheveron-selection': IconCheveronSelection,
  'icon-cheveron-up-circle': IconCheveronUp,
  'icon-cheveron-up': IconCheveronUpCircle,
  'icon-click-target': IconClickTarget,
  'icon-close-circle': IconClose,
  'icon-close-square': IconCloseCircle,
  'icon-close': IconCloseSquare,
  'icon-cloud-download': IconCloudDownload,
  'icon-cloud-upload': IconCloudUpload,
  'icon-clouds': IconClouds,
  'icon-code': IconCode,
  'icon-cog': IconCog,
  'icon-collection': IconCollection,
  'icon-compass': IconCompass,
  'icon-credit-card': IconCreditCard,
  'icon-currency-dollar': IconCurrencyDollar,
  'icon-currency-euro': IconCurrencyEuro,
  'icon-dashboard': IconDashboard,
  'icon-deliver': IconDeliver,
  'icon-desktop': IconDesktop,
  'icon-device-smartphone': IconDeviceSmartphone,
  'icon-device-tablet': IconDeviceTablet,
  'icon-discount': IconDiscount,
  'icon-document-add': IconDocument,
  'icon-document-notes': IconDocumentAdd,
  'icon-document-remove': IconDocumentNotes,
  'icon-document': IconDocumentRemove,
  'icon-door-enter': IconDoorEnter,
  'icon-door-exit': IconDoorExit,
  'icon-dots-horizontal': IconDotsHorizontal,
  'icon-dots-vertical': IconDotsVertical,
  'icon-dupicate': IconDupicate,
  'icon-edit': IconEdit,
  'icon-external-window': IconExternalWindow,
  'icon-factory': IconFactory,
  'icon-fast-forward': IconFastForward,
  'icon-fast-rewind': IconFastRewind,
  'icon-film': IconFilm,
  'icon-flag': IconFlag,
  'icon-folder-add': IconFolder,
  'icon-folder-remove': IconFolderAdd,
  'icon-folder': IconFolderRemove,
  'icon-globe': IconGlobe,
  'icon-harddrive': IconHarddrive,
  'icon-headphones': IconHeadphones,
  'icon-heart': IconHeart,
  'icon-help': IconHelp,
  'icon-history': IconHistory,
  'icon-home': IconHome,
  'icon-hour-glass': IconHourGlass,
  'icon-identification': IconIdentification,
  'icon-important': IconImportant,
  'icon-inbox-check': IconInboxCheck,
  'icon-inbox-download': IconInboxDownload,
  'icon-inbox-full': IconInboxFull,
  'icon-inbox-upload': IconInboxUpload,
  'icon-information': IconInformation,
  'icon-interface': IconInterface,
  'icon-key': IconKey,
  'icon-launch': IconLaunch,
  'icon-layers': IconLayers,
  'icon-library': IconLibrary,
  'icon-light': IconLight,
  'icon-link': IconLink,
  'icon-location-pin': IconLocationPin,
  'icon-lock-open': IconLock,
  'icon-lock': IconLockOpen,
  'icon-mail': IconMail,
  'icon-map': IconMap,
  'icon-menu': IconMenu,
  'icon-microphone': IconMicrophone,
  'icon-money': IconMoney,
  'icon-monitor': IconMonitor,
  'icon-mood-happy': IconMoodHappy,
  'icon-mood-neutral': IconMoodNeutral,
  'icon-mood-sad': IconMoodSad,
  'icon-news': IconNews,
  'icon-notification-off': IconNotification,
  'icon-notification': IconNotificationOff,
  'icon-office': IconOffice,
  'icon-order-horizontal': IconOrderHorizontal,
  'icon-order-vertical': IconOrderVertical,
  'icon-package': IconPackage,
  'icon-paint': IconPaint,
  'icon-pause': IconPause,
  'icon-phone-incoming-call': IconPhoneIncomingCall,
  'icon-phone-outgoing-call': IconPhoneOutgoingCall,
  'icon-phone-ring': IconPhoneRing,
  'icon-photo': IconPhoto,
  'icon-pie-chart': IconPieChart,
  'icon-pin': IconPin,
  'icon-play': IconPlay,
  'icon-presentation-play': IconPresentation,
  'icon-presentation': IconPresentationPlay,
  'icon-print': IconPrint,
  'icon-puzzle': IconPuzzle,
  'icon-receipt': IconReceipt,
  'icon-refresh': IconRefresh,
  'icon-remove-circle': IconRemove,
  'icon-remove-square': IconRemoveCircle,
  'icon-remove': IconRemoveSquare,
  'icon-scale': IconScale,
  'icon-search': IconSearch,
  'icon-security-check': IconSecurity,
  'icon-security-important': IconSecurityCheck,
  'icon-security': IconSecurityImportant,
  'icon-send': IconSend,
  'icon-server': IconServer,
  'icon-shopping-bag': IconShoppingBag,
  'icon-shopping-basket': IconShoppingBasket,
  'icon-shopping-cart': IconShoppingCart,
  'icon-sign': IconSign,
  'icon-sort-ascending': IconSortAscending,
  'icon-sort-decending': IconSortDecending,
  'icon-star': IconStar,
  'icon-stop': IconStop,
  'icon-store': IconStore,
  'icon-survey': IconSurvey,
  'icon-swatch': IconSwatch,
  'icon-tag': IconTag,
  'icon-target': IconTarget,
  'icon-text-cursor': IconTextCursor,
  'icon-thermostat-full': IconThermostatFull,
  'icon-thermostat-half': IconThermostatHalf,
  'icon-thumbs-down': IconThumbsDown,
  'icon-thumbs-up': IconThumbsUp,
  'icon-ticket': IconTicket,
  'icon-time': IconTime,
  'icon-translate': IconTranslate,
  'icon-trash': IconTrash,
  'icon-trending-down': IconTrendingDown,
  'icon-trending-up': IconTrendingUp,
  'icon-trophy': IconTrophy,
  'icon-tune': IconTune,
  'icon-umbrella': IconUmbrella,
  'icon-user-add': IconUserAdd,
  'icon-user-check': IconUserCheck,
  'icon-user-circle': IconUserCircle,
  'icon-user-couple': IconUserCouple,
  'icon-user-group': IconUserGroup,
  'icon-user-remove': IconUserRemove,
  'icon-user': IconUser,
  'icon-videocam': IconVideocam,
  'icon-view-hidden': IconViewHidden,
  'icon-view-visible': IconViewVisible,
  'icon-volume-down': IconVolumeDown,
  'icon-volume-mute': IconVolumeMute,
  'icon-volume-up': IconVolumeUp,
  'icon-wallet': IconWallet,
  'icon-widget-add': IconWidgetAdd,
  'icon-wifi-off': IconWifi,
  'icon-wifi': IconWifiOff,
  'icon-work': IconWork,
  'icon-zoom-in': IconZoomIn,
  'icon-zoom-out': IconZoomOut,
};

interface iIconProps {
  icon: string;
}

const Icon: FC<iIconProps> = ({ icon }) => {
  const Comp = icons[icon];
  if (Comp) return <Comp />;
  return <FeatherIcon icon={icon} />;
};

export default Icon;
