import React from 'react';

export default function IconHeadphones(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-headphones</title>
      <g
        id='icon-headphones'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M22,17 C22,17.5522847 21.5522847,18 21,18 C20.4477153,18 20,17.5522847 20,17 L20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 L4,17 C4,17.5522847 3.55228475,18 3,18 C2.44771525,18 2,17.5522847 2,17 L2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 L22,17 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M7,12 C8.1045695,12 9,12.8954305 9,14 L9,20 C9,21.1045695 8.1045695,22 7,22 C5.8954305,22 5,21.1045695 5,20 L5,14 C5,12.8954305 5.8954305,12 7,12 Z M17,12 C18.1045695,12 19,12.8954305 19,14 L19,20 C19,21.1045695 18.1045695,22 17,22 C15.8954305,22 15,21.1045695 15,20 L15,14 C15,12.8954305 15.8954305,12 17,12 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
