import React from 'react';

export default function IconChatGroupAlt(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-chat-group-alt</title>
      <g
        id='icon-chat-group-alt'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <rect
          id='secondary'
          fill='currentColor'
          x={2}
          y={2}
          width={16}
          height={13}
          rx={2}
        />
        <path
          d='M6,16 L6,8 C6,6.8954305 6.8954305,6 8,6 L20,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,21 C22,21.2559223 21.9023689,21.5118446 21.7071068,21.7071068 C21.3165825,22.0976311 20.6834175,22.0976311 20.2928932,21.7071068 L16.5857864,18 L8,18 C6.8954305,18 6,17.1045695 6,16 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
