import React from 'react';

export default function IconCloudDownload(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cloud-download</title>
      <g
        id='icon-cloud-download'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15,15 L15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 L9,15 L6,15 C3.790861,15 2,13.209139 2,11 C2,9.13157771 3.28104938,7.56236054 5.01273426,7.12276238 C5.20657536,4.26115113 7.58922761,2 10.5,2 C13.1269746,2 15.3237913,3.84172532 15.8696859,6.3044119 C16.3751862,6.10784518 16.9249988,6 17.5,6 C19.9852814,6 22,8.01471863 22,10.5 C22,12.9852814 19.9852814,15 17.5,15 L15,15 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,18.5857864 L11,12 C11,11.4477153 11.4477153,11 12,11 C12.5522847,11 13,11.4477153 13,12 L13,18.5857864 L14.2928932,17.2928932 C14.6834175,16.9023689 15.3165825,16.9023689 15.7071068,17.2928932 C16.0976311,17.6834175 16.0976311,18.3165825 15.7071068,18.7071068 L12.7071068,21.7071068 C12.3165825,22.0976311 11.6834175,22.0976311 11.2928932,21.7071068 L8.29289322,18.7071068 C7.90236893,18.3165825 7.90236893,17.6834175 8.29289322,17.2928932 C8.68341751,16.9023689 9.31658249,16.9023689 9.70710678,17.2928932 L11,18.5857864 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
