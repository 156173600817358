import React from 'react';

export default function IconVolumeDown(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-volume-down</title>
      <g
        id='icon-volume-down'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6.58578644,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,9 C2,8.44771525 2.44771525,8 3,8 L6.58578644,8 L11.2928932,3.29289322 C11.9228581,2.66292836 13,3.10909515 13,4 L13,20 C13,20.8909049 11.9228581,21.3370716 11.2928932,20.7071068 L6.58578644,16 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M14.8284271,9.17157288 C14.4379028,8.78104858 14.4379028,8.1478836 14.8284271,7.75735931 C15.2189514,7.36683502 15.8521164,7.36683502 16.2426407,7.75735931 C18.5857864,10.1005051 18.5857864,13.8994949 16.2426407,16.2426407 C15.8521164,16.633165 15.2189514,16.633165 14.8284271,16.2426407 C14.4379028,15.8521164 14.4379028,15.2189514 14.8284271,14.8284271 C16.3905243,13.26633 16.3905243,10.73367 14.8284271,9.17157288 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
