import React from 'react';

export default function IconCompass(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-compass</title>
      <g
        id='icon-compass'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,10 C10.8954305,10 10,10.8954305 10,12 C10,13.1045695 10.8954305,14 12,14 C13.1045695,14 14,13.1045695 14,12 C14,10.8954305 13.1045695,10 12,10 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M10,12 C10,13.1045695 10.8954305,14 12,14 C12.3845073,14 12.7436717,13.8914937 13.0485433,13.7034307 L7.65025253,17.7497475 C6.72513744,18.4435838 5.55641621,17.2748626 6.25025253,16.3497475 L10.2943457,10.95507 C10.1076513,11.2591646 10,11.6170107 10,12 Z M17.7497475,7.65025253 L13.7034307,13.0485433 C13.8914937,12.7436717 14,12.3845073 14,12 C14,10.8954305 13.1045695,10 12,10 C11.6170107,10 11.2591646,10.1076513 10.95507,10.2943457 L16.3497475,6.25025253 C17.2748626,5.55641621 18.4435838,6.72513744 17.7497475,7.65025253 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
