import React from 'react';

export default function IconThermostatFull(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-thermostat-full</title>
      <g
        id='icon-thermostat-full'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,0 C14.7614237,0 17,2.23857625 17,5 L17,12.1010173 C18.237187,13.3635544 19,15.0926949 19,17 C19,20.8659932 15.8659932,24 12,24 C8.13400675,24 5,20.8659932 5,17 C5,15.0926949 5.76281298,13.3635544 7,12.1010173 L7,5 C7,2.23857625 9.23857625,0 12,0 Z M12,2 C10.3431458,2 9,3.34314575 9,5 L9,12.9175885 L8.42848186,13.5008171 C7.51743456,14.4305318 7,15.6718826 7,17 C7,19.7614237 9.23857625,22 12,22 C14.7614237,22 17,19.7614237 17,17 C17,15.6718826 16.4825654,14.4305318 15.5715181,13.5008171 L15,12.9175885 L15,5 C15,3.34314575 13.6568542,2 12,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,14.1707057 L11,5 C11,4.44771525 11.4477153,4 12,4 C12.5522847,4 13,4.44771525 13,5 L13,14.1707057 C14.1651924,14.5825421 15,15.6937812 15,17 C15,18.6568542 13.6568542,20 12,20 C10.3431458,20 9,18.6568542 9,17 C9,15.6937812 9.83480763,14.5825421 11,14.1707057 L11,14.1707057 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
