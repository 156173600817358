import React from 'react';

export default function IconEdit(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-edit</title>
      <g
        id='icon-edit'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M4,14 C4,13.7347835 4.10535684,13.4804296 4.29289322,13.2928932 L15.2928932,2.29289322 C15.6834175,1.90236893 16.3165825,1.90236893 16.7071068,2.29289322 L19.7071068,5.29289322 C20.0976311,5.68341751 20.0976311,6.31658249 19.7071068,6.70710678 L8.70710678,17.7071068 C8.5195704,17.8946432 8.26521649,18 8,18 L5,18 C4.44771525,18 4,17.5522847 4,17 L4,14 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <rect
          id='secondary'
          fill='currentColor'
          x={2}
          y={20}
          width={20}
          height={2}
          rx={1}
        />
      </g>
    </svg>
  );
}
