import React from 'react';

export default function IconFactory(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-factory</title>
      <g
        id='icon-factory'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M21,21 L2.99996453,21 C2.42314313,21 1.96593083,20.5133198 2.00191196,19.9376217 L3.00191196,3.93762171 C3.03485164,3.41058679 3.47190125,3 3.99996453,3 L5.99996453,3 C6.52802782,3 6.96507743,3.41058679 6.99801711,3.93762171 L7.38920227,10.1965842 L10.2928932,7.29289322 C10.9228581,6.66292836 12,7.10909515 12,8 L12,10.5857864 L15.2928932,7.29289322 C15.9228581,6.66292836 17,7.10909515 17,8 L17,10.5857864 L20.2928932,7.29289322 C20.9228581,6.66292836 22,7.10909515 22,8 L22,20 C22,20.5522847 21.5522847,21 21,21 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M10,17 L10,19 L7,19 L7,17 L10,17 Z M15,17 L15,19 L12,19 L12,17 L15,17 Z M20,17 L20,19 L17,19 L17,17 L20,17 Z M10,13 L10,15 L7,15 L7,13 L10,13 Z M15,13 L15,15 L12,15 L12,13 L15,13 Z M20,13 L20,15 L17,15 L17,13 L20,13 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
