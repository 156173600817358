import React from 'react';

export default function IconFastRewind(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-fast-rewind</title>
      <g
        id='icon-fast-rewind'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M8,5 C8,4.22820917 8.83721439,3.74733806 9.50387103,4.1362211 L21.503871,11.1362211 C22.1653763,11.5220992 22.1653763,12.4779008 21.503871,12.8637789 L9.50387103,19.8637789 C8.83721439,20.2526619 8,19.7717908 8,19 L8,5 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
          transform='translate(15.000000, 12.000000) scale(-1, 1) translate(-15.000000, -12.000000) '
        />
        <path
          d='M1,5 C1,4.22820917 1.83721439,3.74733806 2.50387103,4.1362211 L14.503871,11.1362211 C15.1653763,11.5220992 15.1653763,12.4779008 14.503871,12.8637789 L2.50387103,19.8637789 C1.83721439,20.2526619 1,19.7717908 1,19 L1,5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
          transform='translate(8.000000, 12.000000) scale(-1, 1) translate(-8.000000, -12.000000) '
        />
      </g>
    </svg>
  );
}
