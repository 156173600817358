import React from 'react';

export default function IconClouds(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-clouds</title>
      <g
        id='icon-clouds'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M16.8498732,6.06026729 C17.0605328,6.02069678 17.2778494,6 17.5,6 C19.4329966,6 21,7.56700338 21,9.5 C21,11.4329966 19.4329966,13 17.5,13 C15.7366931,13 14.2779419,11.6960421 14.0354151,9.99979526 C14.0236263,9.99993163 14.0118211,10 14,10 C12.3431458,10 11,8.65685425 11,7 C11,5.34314575 12.3431458,4 14,4 C15.3286313,4 16.4555343,4.8637011 16.8498732,6.06026729 L16.8498732,6.06026729 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M5.03426213,12.1173614 C5.01162477,11.9146975 5,11.7087063 5,11.5 C5,8.46243388 7.46243388,6 10.5,6 C13.4564478,6 15.8680711,8.33267127 15.9947676,11.2579116 C16.4654139,11.0909036 16.9720911,11 17.5,11 C19.9852814,11 22,13.0147186 22,15.5 C22,17.9852814 19.9852814,20 17.5,20 L6,20 C3.790861,20 2,18.209139 2,16 C2,14.1238434 3.29167706,12.5493742 5.03426213,12.1173614 L5.03426213,12.1173614 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
