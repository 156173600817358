import classNames from 'classnames';
import { FC, useEffect } from 'react';
import styles from '../../styles/components/options.module.scss';

interface iOptions {
  options: string[];
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
  max?: number;
}
interface iOptionProps {
  value: string;
  letter: string;
  selected: boolean;
  onSelect: (val: string) => void;
}

const Options: FC<iOptions> = ({ options, value, onChange, max }) => {
  useEffect(() => {
    window.addEventListener('keypress', _handleKeyDown);
    return () => window.removeEventListener('keypress', _handleKeyDown);
  }, [JSON.stringify(value)]);

  const _handleKeyDown = (e: any) => {
    if (['Enter'].includes(e.key)) return;
    const index = e.key.toUpperCase().charCodeAt(0) - 65;
    if (index < options.length) _handleChange?.(options[index]);
  };

  const _handleChange = (val: string) => {
    if (max === 1) onChange?.(val);
    let _value = Array.isArray(value) ? [...value] : value ? [value] : [];

    const index = _value.indexOf(val);
    if (_value.includes(val)) _value.splice(index, 1);
    else _value.unshift(val);

    if (max) _value = _value.slice(0, max);

    onChange?.(_value);
  };

  return (
    <>
      <p>
        Pick{' '}
        {max
          ? `up to ${max} option${max > 1 ? 's' : ''}`
          : 'as many as best fits you'}
      </p>
      <div
        className={classNames(
          styles.wrapper,
          options.length > 3 && styles.grid,
        )}
      >
        {options.map((option, index: number) => (
          <Option
            key={option}
            value={option}
            onSelect={_handleChange}
            selected={value === option || value?.includes(option) || false}
            letter={String.fromCharCode(index + 65)}
          />
        ))}
      </div>
    </>
  );
};
const Option: FC<iOptionProps> = ({ value, letter, selected, onSelect }) => {
  const _handleSelect = () => onSelect(value);
  return (
    <button
      tabIndex={0}
      role='switch'
      aria-checked={selected ? 'true' : 'false'}
      aria-label={value}
      onClick={_handleSelect}
      className={classNames(selected && styles.selected, styles.optionWrapper)}
    >
      <span className={styles.prefix}>{letter}</span>
      <span className={styles.label}>{value}</span>
    </button>
  );
};

export default Options;
