import { FC } from 'react';
import { Route, Switch } from 'react-router';
import { Sidebar } from '../../components';
import { useUser } from '../../hooks';
import Skeleton from '../../layout/Skeleton';
import AdminRouter from '../Admin';
import Notifications from './Notifications';
import WIP from './WIP';

const Dashboard: FC = () => {
  const { user } = useUser();
  return (
    <Skeleton sidebar={<Sidebar />}>
      <Switch>
        <Route path='/' exact component={Notifications} />
        {user?.is_admin && <Route path='/admin' component={AdminRouter} />}
        <Route path='*' exact component={WIP} />
      </Switch>
    </Skeleton>
  );
};

export default Dashboard;
