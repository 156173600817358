import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useTable } from 'react-table';
import { CheckboxInput, Icon } from '.';
import styles from '../styles/components/table.module.scss';
import { eCheckboxValue } from './Checkbox';

interface iTableProps {
  data: any[];
  hoverable?: boolean;
  selectable?: boolean;
  ids?: string[];
  onSort?: (column: string) => void;
  onClick?: (id: string) => void;
  selection?: string[];
  onSelectionChanges?: (val: string[]) => void;
  colums?: { [key: string]: string };
  sort?: { key: string; direction: -1 | 1 };
}
const Table: FC<iTableProps> = ({
  data,
  ids,
  onSort,
  hoverable,
  selectable,
  selection,
  onSelectionChanges,
  colums,
  sort,
  onClick,
}) => {
  const _handleFullSelect = (val: eCheckboxValue) => {
    if (val === 'FILLED') onSelectionChanges?.(ids ?? []);
    else onSelectionChanges?.([]);
  };

  const _handleSelect = (id: string) => () => {
    if (!selectable || !selection || !onSelectionChanges) return;
    const _selection = [...selection];
    if (_selection.includes(id)) _selection.splice(_selection.indexOf(id), 1);
    else _selection.push(id);
    onSelectionChanges(_selection);
  };

  const columns: any[] = useMemo(
    () =>
      colums
        ? Object.entries(colums).map(([key, value]) => ({
            Header: value,
            accessor: key,
          }))
        : Object.keys(colums ?? data?.[0] ?? {})
            .filter((key) => key !== '_id')
            .map((key) => ({
              Header: key,
              accessor: key,
            })),
    [JSON.stringify(Object.keys(data?.[0] ?? {}))],
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const _handleSort = (column: string) => () => {
    console.log(column);
    onSort?.(column);
  };
  return (
    <div className={styles.wrapper}>
      <table {...getTableProps()} cellSpacing={0} cellPadding={0}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {selectable && (
                <th>
                  <CheckboxInput
                    onChange={_handleFullSelect}
                    value={
                      ids?.length === selection?.length
                        ? 'FILLED'
                        : selection?.length
                        ? 'PARTIAL'
                        : 'EMPTY'
                    }
                  />
                </th>
              )}
              {headerGroup.headers.map((column) => (
                <th
                  onClick={_handleSort(column.id)}
                  {...column.getHeaderProps()}
                >
                  <span>
                    {column.render('Header')}
                    {sort?.key === column.id && (
                      <Icon
                        icon={
                          sort.direction < 0 ? 'chevron-down' : 'chevron-up'
                        }
                      />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => onClick?.(row.original._id)}
                className={classNames(hoverable && styles.hoverable)}
              >
                {selectable && (
                  <td>
                    <CheckboxInput
                      value={
                        selection?.includes(row.original._id)
                          ? 'FILLED'
                          : 'EMPTY'
                      }
                      onChange={_handleSelect(row.original._id)}
                    />
                  </td>
                )}
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
