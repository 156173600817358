import { FC, useCallback } from 'react';
import { iSurvey } from '../../types';
import styles from '../../styles/components/usergroup.module.scss';
import Icon from '../Icon';

interface iSurveyProps extends iSurvey {
  onModal?: (survey: string | iSurvey) => void;
}

const Survey: FC<iSurveyProps> = ({
  title,
  subtitle,
  id,
  short_id,
  onModal,
  typeform_id,
  is_mandatory,
  completitions,
}) => {
  const _handleClick = useCallback(() => onModal?.(id), []);

  return (
    <div className={styles.wrapper} onClick={_handleClick}>
      <div className={styles.header}>
        <strong>
          {title}
          {is_mandatory && <Icon icon='icon-important' />}
        </strong>
        <span>{completitions ?? 0} completitions</span>
      </div>
      <div className={styles.footer}>
        <span>{short_id}</span>
        <span>{typeform_id || '-'}</span>
      </div>
    </div>
  );
};

export default Survey;
