import { FC, useCallback } from 'react';
import { iWhitelist } from '../../types';
import styles from '../../styles/components/usergroup.module.scss';
import Icon from '../Icon';
import { formatDifference } from '../../core';
import { useHistory } from 'react-router';

interface iWhitelistProps extends iWhitelist {
  onClick: (id: string) => void;
}

const Whitelist: FC<iWhitelistProps> = ({
  id,
  name,
  query,
  user_ids,
  onClick,
  is_active,
  updated_at,
}) => {
  const history = useHistory();
  const _handleClick = useCallback(() => {
    if (is_active) history.push(`/admin/whitelists/${id}`);
    else onClick?.(id);
  }, [is_active]);

  return (
    <div className={styles.wrapper} onClick={_handleClick}>
      <div className={styles.header}>
        <strong>
          {name}
          {query && <Icon icon='icon-time' />}
        </strong>
        <span>{user_ids?.length ?? 0} users</span>
      </div>
      <div className={styles.footer}>
        <span>{is_active ? 'Active' : 'Pending'}</span>
        <span>Last updated {formatDifference(updated_at)} ago</span>
      </div>
    </div>
  );
};

export default Whitelist;
