import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { Icon } from '.';
import styles from '../styles/components/segment.module.scss';

type tAction = {
  icon: string;
  onClick: () => void;
};

interface iSegmentProps {
  title: string;
  collapsible?: boolean;
  collapsed?: boolean;

  actions?: tAction[];
}
const Segment: FC<iSegmentProps> = ({
  title,
  children,
  collapsible,
  collapsed,
  actions,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed ?? false);
  const _handleToggle = useCallback(() => {
    if (collapsible) setIsCollapsed(!isCollapsed);
  }, [collapsible, isCollapsed, collapsed]);

  const _handleAction = useCallback((cb: () => void) => {
    return (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      cb();
    };
  }, []);
  return (
    <div className={styles.wrapper}>
      <span onClick={_handleToggle} className={styles.title}>
        <label className={classNames(isCollapsed && styles.collapsed)}>
          {collapsible && <Icon icon='chevron-right' />}
          {title}
        </label>
        <div className={styles.actions}>
          {actions?.map((action, i) => (
            <button
              onClick={_handleAction(action.onClick)}
              key={`${action.icon}`}
            >
              <Icon icon={action.icon} />
            </button>
          ))}
        </div>
      </span>
      {!isCollapsed && (
        <div
          className={classNames(
            styles.content,
            isCollapsed && styles.collapsed,
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Segment;
