import { FC } from 'react';
import { Button, Input, Segment, Select } from '../components';
import { useModal } from '../hooks';
import { Row } from '../layout';
import Actions from '../layout/Modal/Actions';
import ModalBody from '../layout/Modal/Body';

interface iUserValidationProps {
  mobile: string;
  postcode: string;
  formatted_address: string[];
  birthdate: string;
  meta: string;
  status: string;
}

const statuses = [
  { value: 'VALID', label: 'Valid' },
  { value: 'CHECK_NEEDED', label: 'Check needed' },
  { value: 'DUPLICATE', label: 'Duplicate' },
  { value: 'INVALID', label: 'Invalid' },
];

const UserValidation: FC<iUserValidationProps> = ({
  mobile,
  postcode,
  formatted_address,
  meta,
  birthdate,
  status,
}) => {
  const { dismissModal } = useModal();
  const { mobileInput, postcodeInput, birthdateInput, addressInput } =
    JSON.parse(meta);
  return (
    <>
      <ModalBody>
        <Segment title='Status'>
          <Select onChange={() => {}} options={statuses} value={status} />
        </Segment>
        <Segment title='Mobile number'>
          <Row>
            <Input value={mobile} showLength />
            <Input value={mobileInput} showLength disabled />
          </Row>
        </Segment>
        <Segment title='Birthdate'>
          <Row>
            <Input value={birthdate} />
            <Input value={birthdateInput} disabled />
          </Row>
        </Segment>
        <Segment title='Postcode'>
          <Row>
            <Input value={postcode} />
            <Input value={postcodeInput} disabled />
          </Row>
        </Segment>
        <Segment title='Address'>
          <Row>
            <Input value={formatted_address?.join('\n')} textarea />
            <Input value={addressInput?.join('\n')} textarea disabled />
          </Row>
        </Segment>
      </ModalBody>
      <Actions>
        <Button onClick={dismissModal}>Cancel</Button>
        <Button primary>Save</Button>
      </Actions>
    </>
  );
};

export default UserValidation;
