import React from 'react';

export default function IconArrowThickRightCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-arrow-thick-right-circle</title>
      <g
        id='icon-arrow-thick-right-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M12,14 L7,14 C6.44771525,14 6,13.5522847 6,13 L6,11 C6,10.4477153 6.44771525,10 7,10 L12,10 L12,8 C12,7.10909515 13.0771419,6.66292836 13.7071068,7.29289322 L17.7071068,11.2928932 C18.0976311,11.6834175 18.0976311,12.3165825 17.7071068,12.7071068 L13.7071068,16.7071068 C13.0771419,17.3370716 12,16.8909049 12,16 L12,14 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
