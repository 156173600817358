import { FC } from 'react';
import { createPortal } from 'react-dom';
import { iModal } from '../../providers/Modal';
import ModalContent from './Modal';

interface iModalProps extends iModal {}

const Modal: FC<iModalProps> = ({ ...props }) => {
  const wrapper = document.querySelector('#modal');
  if (!wrapper || !props.title) return null;
  return createPortal(<ModalContent {...props} />, wrapper);
};

export default Modal;
