import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthSkeleton, ResetForm } from '../layout';

const Forgot: FC = () => {
  const { t } = useTranslation('reset');

  return (
    <AuthSkeleton
      pageTitle={t('page_title')}
      title={t('meta_title')}
      subtitle={t('meta_subtitle')}
    >
      <ResetForm />
    </AuthSkeleton>
  );
};

export default Forgot;
