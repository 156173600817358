import React from 'react';

export default function IconArrowThickDownCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-arrow-thick-down-circle</title>
      <g
        id='icon-arrow-thick-down-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M10,12 L10,7 C10,6.44771525 10.4477153,6 11,6 L13,6 C13.5522847,6 14,6.44771525 14,7 L14,12 L16,12 C16.8909049,12 17.3370716,13.0771419 16.7071068,13.7071068 L12.7071068,17.7071068 C12.3165825,18.0976311 11.6834175,18.0976311 11.2928932,17.7071068 L7.29289322,13.7071068 C6.66292836,13.0771419 7.10909515,12 8,12 L10,12 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
