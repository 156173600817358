import React from 'react';

export default function IconDoorEnter(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-door-enter</title>
      <g
        id='icon-door-enter'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M13,4 L13,3 C13,2.33562112 13.6359049,1.85595714 14.2747211,2.03847605 L21.2534647,4.03240279 C21.6830124,4.14461383 22,4.53528871 22,5 L22,19 C22,19.4647113 21.6830124,19.8553862 21.2534647,19.9675972 L14.2747211,21.9615239 C13.6359049,22.1440429 13,21.6643789 13,21 L13,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,17 C9,16.4477153 9.44771525,16 10,16 C10.5522847,16 11,16.4477153 11,17 L11,18 L13,18 L13,6 L11,6 L11,7 C11,7.55228475 10.5522847,8 10,8 C9.44771525,8 9,7.55228475 9,7 L9,5 C9,4.44771525 9.44771525,4 10,4 L13,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M7.58578644,11 L6.29289322,9.70710678 C5.35008418,8.76429774 6.76429774,7.35008418 7.70710678,8.29289322 L10.7071068,11.2928932 C11.0976311,11.6834175 11.0976311,12.3165825 10.7071068,12.7071068 L7.70710678,15.7071068 C6.76429774,16.6499158 5.35008418,15.2357023 6.29289322,14.2928932 L7.58578644,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L7.58578644,11 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
