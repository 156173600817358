import React from 'react';

export default function IconUserCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-user-circle</title>
      <g
        id='icon-user-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,14.5662059 21.0333733,16.906644 19.4443621,18.6770722 C19.7707445,18.3133928 20.0706034,17.9260577 20.3412762,17.5176938 C19.4792992,16.0134382 17.8580002,16 16,16 L7.77820959,16.0001591 C6.01351082,16.0034604 4.48622176,16.0736084 3.65872385,17.5176938 C3.92939656,17.9260577 4.22925546,18.3133928 4.55532559,18.6767242 C2.96662666,16.906644 2,14.5662059 2,12 C2,6.4771525 6.4771525,2 12,2 Z'
          id='Combined-Shape'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M16,15 C17.8580002,15 19.4792992,16.0134382 20.3412762,17.5176938 C18.5508608,20.2188929 15.4834303,22 12,22 C8.51656966,22 5.44913918,20.2188929 3.65872385,17.5176938 C4.52070083,16.0134382 6.14199983,15 8,15 L16,15 Z M12,5 C14.209139,5 16,6.790861 16,9 C16,11.209139 14.209139,13 12,13 C9.790861,13 8,11.209139 8,9 C8,6.790861 9.790861,5 12,5 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
