import React from 'react';

export default function IconCog(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cog</title>
      <g
        id='icon-cog'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6.80907826,3.45111315 C7.67087502,2.92670485 8.6182261,2.52894081 9.62528821,2.28366432 C9.95406906,3.28051553 10.8930122,4 12,4 C13.1069878,4 14.0459309,3.28051553 14.3747118,2.28366432 C15.3817739,2.52894081 16.329125,2.92670485 17.1909217,3.45111315 C16.7190005,4.3883479 16.8743113,5.56060285 17.6568542,6.34314575 C18.4393972,7.12568865 19.6116521,7.28099949 20.5488868,6.80907826 C21.0732951,7.67087502 21.4710592,8.6182261 21.7163357,9.62528821 C20.7194845,9.95406906 20,10.8930122 20,12 C20,13.1069878 20.7194845,14.0459309 21.7163357,14.3747118 C21.4710592,15.3817739 21.0732951,16.329125 20.5488868,17.1909217 C19.6116521,16.7190005 18.4393972,16.8743113 17.6568542,17.6568542 C16.8743113,18.4393972 16.7190005,19.6116521 17.1909217,20.5488868 C16.329125,21.0732951 15.3817739,21.4710592 14.3747118,21.7163357 C14.0459309,20.7194845 13.1069878,20 12,20 C10.8930122,20 9.95406906,20.7194845 9.62528821,21.7163357 C8.6182261,21.4710592 7.67087502,21.0732951 6.80907826,20.5488868 C7.28099949,19.6116521 7.12568865,18.4393972 6.34314575,17.6568542 C5.56060285,16.8743113 4.3883479,16.7190005 3.45111315,17.1909217 C2.92670485,16.329125 2.52894081,15.3817739 2.28366432,14.3747118 C3.28051553,14.0459309 4,13.1069878 4,12 C4,10.8930122 3.28051553,9.95406906 2.28366432,9.62528821 C2.52894081,8.6182261 2.92670485,7.67087502 3.45111315,6.80907826 C4.3883479,7.28099949 5.56060285,7.12568865 6.34314575,6.34314575 C7.12568865,5.56060285 7.28099949,4.3883479 6.80907826,3.45111315 Z M12,16 C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <circle id='secondary' fill='currentColor' cx={12} cy={12} r={2} />
      </g>
    </svg>
  );
}
