import React from 'react';

export default function IconSwatch(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-swatch</title>
      <g
        id='icon-swatch'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M20,14 C21.1045695,14 22,14.8954305 22,16 L22,20 C22,21.1045695 21.1045695,22 20,22 L9.00105291,22 C9.1873009,21.8598992 9.36599069,21.7050771 9.53553391,21.5355339 L17.0710678,14 L20,14 Z M8,2 C9.1045695,2 10,2.8954305 10,4 L10,18 C10,20.209139 8.209139,22 6,22 C3.790861,22 2,20.209139 2,18 L2,4 C2,2.8954305 2.8954305,2 4,2 L8,2 Z M6,16.5 C5.17157288,16.5 4.5,17.1715729 4.5,18 C4.5,18.8284271 5.17157288,19.5 6,19.5 C6.82842712,19.5 7.5,18.8284271 7.5,18 C7.5,17.1715729 6.82842712,16.5 6,16.5 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,18.6568542 L11,7.34314575 L13.0710678,5.27207794 C13.8521164,4.49102936 15.1184464,4.49102936 15.8994949,5.27207794 L18.7279221,8.10050506 C19.5089706,8.88155365 19.5089706,10.1478836 18.7279221,10.9289322 L11,18.6568542 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
