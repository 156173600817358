import React from 'react';

export default function IconVolumeMute(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-volume-mute</title>
      <g
        id='icon-volume-mute'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M6.58578644,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,9 C2,8.44771525 2.44771525,8 3,8 L6.58578644,8 L11.2928932,3.29289322 C11.9228581,2.66292836 13,3.10909515 13,4 L13,20 C13,20.8909049 11.9228581,21.3370716 11.2928932,20.7071068 L6.58578644,16 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M17.0857864,12 L15.2928932,10.2071068 C14.9023689,9.81658249 14.9023689,9.18341751 15.2928932,8.79289322 C15.6834175,8.40236893 16.3165825,8.40236893 16.7071068,8.79289322 L18.5,10.5857864 L20.2928932,8.79289322 C20.6834175,8.40236893 21.3165825,8.40236893 21.7071068,8.79289322 C22.0976311,9.18341751 22.0976311,9.81658249 21.7071068,10.2071068 L19.9142136,12 L21.7071068,13.7928932 C22.0976311,14.1834175 22.0976311,14.8165825 21.7071068,15.2071068 C21.3165825,15.5976311 20.6834175,15.5976311 20.2928932,15.2071068 L18.5,13.4142136 L16.7071068,15.2071068 C16.3165825,15.5976311 15.6834175,15.5976311 15.2928932,15.2071068 C14.9023689,14.8165825 14.9023689,14.1834175 15.2928932,13.7928932 L17.0857864,12 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
