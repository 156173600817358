import React from 'react';

export default function IconCamera(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-camera</title>
      <g
        id='icon-camera'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M14,3 C14.2652165,3 14.5195704,3.10535684 14.7071068,3.29289322 L17.4142136,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,8 C2,6.8954305 2.8954305,6 4,6 L6.58578644,6 L9.29289322,3.29289322 C9.4804296,3.10535684 9.73478351,3 10,3 L14,3 Z M12,8 C9.23857625,8 7,10.2385763 7,13 C7,15.7614237 9.23857625,18 12,18 C14.7614237,18 17,15.7614237 17,13 C17,10.2385763 14.7614237,8 12,8 Z M19,8 C18.4477153,8 18,8.44771525 18,9 C18,9.55228475 18.4477153,10 19,10 C19.5522847,10 20,9.55228475 20,9 C20,8.44771525 19.5522847,8 19,8 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M12,16 C13.6568542,16 15,14.6568542 15,13 C15,11.3431458 13.6568542,10 12,10 C10.3431458,10 9,11.3431458 9,13 C9,14.6568542 10.3431458,16 12,16 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
