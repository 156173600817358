import React from 'react';

export default function IconWifi(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-wifi</title>
      <g
        id='icon-wifi'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M16.2426407,14.7573593 C16.633165,15.1478836 16.633165,15.7810486 16.2426407,16.1715729 C15.8521164,16.5620972 15.2189514,16.5620972 14.8284271,16.1715729 C13.26633,14.6094757 10.73367,14.6094757 9.17157288,16.1715729 C8.78104858,16.5620972 8.1478836,16.5620972 7.75735931,16.1715729 C7.36683502,15.7810486 7.36683502,15.1478836 7.75735931,14.7573593 C10.1005051,12.4142136 13.8994949,12.4142136 16.2426407,14.7573593 Z M19.0710678,11.9289322 C19.4615921,12.3194565 19.4615921,12.9526215 19.0710678,13.3431458 C18.6805435,13.73367 18.0473785,13.73367 17.6568542,13.3431458 C14.5326599,10.2189514 9.46734008,10.2189514 6.34314575,13.3431458 C5.95262146,13.73367 5.31945648,13.73367 4.92893219,13.3431458 C4.5384079,12.9526215 4.5384079,12.3194565 4.92893219,11.9289322 C8.83417511,8.02368927 15.1658249,8.02368927 19.0710678,11.9289322 Z M21.8994949,9.10050506 C22.2900192,9.49102935 22.2900192,10.1241943 21.8994949,10.5147186 C21.5089706,10.9052429 20.8758057,10.9052429 20.4852814,10.5147186 C15.7989899,5.82842712 8.20101013,5.82842712 3.51471863,10.5147186 C3.12419433,10.9052429 2.49102935,10.9052429 2.10050506,10.5147186 C1.70998077,10.1241943 1.70998077,9.49102935 2.10050506,9.10050506 C7.56784515,3.63316498 16.4321549,3.63316498 21.8994949,9.10050506 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          fillRule='nonzero'
        />
        <path
          d='M12,20.5 C12.8284271,20.5 13.5,19.8284271 13.5,19 C13.5,18.1715729 12.8284271,17.5 12,17.5 C11.1715729,17.5 10.5,18.1715729 10.5,19 C10.5,19.8284271 11.1715729,20.5 12,20.5 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
