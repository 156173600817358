import React from 'react';

export default function IconPhoneOutgoingCall(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-phone-outgoing-call</title>
      <g
        id='icon-phone-outgoing-call'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M4,2 L8,2 C8.47668102,2 8.88709584,2.33645967 8.98058068,2.80388386 L9.98058068,7.80388386 C10.0461527,8.13174414 9.94353019,8.47068337 9.70710678,8.70710678 L7.19257498,11.2216386 C8.37026196,13.6546578 10.3453422,15.629738 12.7783614,16.807425 L15.2928932,14.2928932 C15.5293166,14.0564698 15.8682559,13.9538473 16.1961161,14.0194193 L21.1961161,15.0194193 C21.6635403,15.1129042 22,15.523319 22,16 L22,20 C22,21.1045802 21.1045629,22 20,22 L18,22 C9.163444,22 2,14.836556 2,6 L2,4 C2,2.8954305 2.8954305,2 4,2 L4,2 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M14.7071068,10.7071068 C14.3165825,11.0976311 13.6834175,11.0976311 13.2928932,10.7071068 C12.9023689,10.3165825 12.9023689,9.68341751 13.2928932,9.29289322 L17.5857864,5 L15,5 C14.4477153,5 14,4.55228475 14,4 C14,3.44771525 14.4477153,3 15,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,9 C21,9.55228475 20.5522847,10 20,10 C19.4477153,10 19,9.55228475 19,9 L19,6.41421356 L14.7071068,10.7071068 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
