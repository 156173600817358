import React from 'react';

export default function IconCheveronRightCircle(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-cheveron-right-circle</title>
      <g
        id='icon-cheveron-right-circle'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M15.7071068,15.2928932 C16.0976311,15.6834175 16.0976311,16.3165825 15.7071068,16.7071068 C15.3165825,17.0976311 14.6834175,17.0976311 14.2928932,16.7071068 L10.2928932,12.7071068 C9.90236893,12.3165825 9.90236893,11.6834175 10.2928932,11.2928932 L14.2928932,7.29289322 C14.6834175,6.90236893 15.3165825,6.90236893 15.7071068,7.29289322 C16.0976311,7.68341751 16.0976311,8.31658249 15.7071068,8.70710678 L12.4142136,12 L15.7071068,15.2928932 Z'
          id='secondary'
          fill='currentColor'
          transform='translate(13.000000, 12.000000) rotate(-180.000000) translate(-13.000000, -12.000000) '
        />
      </g>
    </svg>
  );
}
