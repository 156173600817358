import React from 'react';

export default function IconScale(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-scale</title>
      <g
        id='icon-scale'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group' transform='translate(2.000000, 2.000000)'>
          <path
            d='M9,4.38742589 L4.31622777,5.9486833 C4.11096096,6.01710557 3.88903904,6.01710557 3.68377223,5.9486833 L0.683772234,4.9486833 C0.159828916,4.77403553 -0.123331071,4.20771555 0.0513167019,3.68377223 C0.225964475,3.15982892 0.792284448,2.87666893 1.31622777,3.0513167 L4,3.94590745 L9,2.27924078 L9,1 C9,0.44771525 9.44771525,0 10,0 C10.5522847,0 11,0.44771525 11,1 L11,2.27924078 L16,3.94590745 L18.6837722,3.0513167 C19.2077156,2.87666893 19.7740355,3.15982892 19.9486833,3.68377223 C20.1233311,4.20771555 19.8401711,4.77403553 19.3162278,4.9486833 L16.3162278,5.9486833 C16.110961,6.01710557 15.889039,6.01710557 15.6837722,5.9486833 L11,4.38742589 L11,16 C11,17.1045695 11.8954305,18 13,18 L14,18 L14,20 L6,20 L6,18 L7,18 C8.1045695,18 9,17.1045695 9,16 L9,4.38742589 Z'
            id='primary'
            fill='currentColor'
            opacity='0.4'
          />
          <path
            d='M16.9486833,4.68377223 L19.9486833,13.6837722 C20.0912865,14.111582 19.9299142,14.5819077 19.5547002,14.8320503 C18.3976562,15.6034129 17.207895,16 16,16 C14.792105,16 13.6023438,15.6034129 12.4452998,14.8320503 C12.0700858,14.5819077 11.9087135,14.111582 12.0513167,13.6837722 L15.0513167,4.68377223 C15.3552155,3.77207592 16.6447845,3.77207592 16.9486833,4.68377223 Z M4.9486833,4.68377223 L7.9486833,13.6837722 C8.09128654,14.111582 7.92991416,14.5819077 7.5547002,14.8320503 C6.39765624,15.6034129 5.20789503,16 4,16 C2.79210497,16 1.60234376,15.6034129 0.445299804,14.8320503 C0.070085844,14.5819077 -0.0912865434,14.111582 0.0513167019,13.6837722 L3.0513167,4.68377223 C3.35521547,3.77207592 4.64478453,3.77207592 4.9486833,4.68377223 Z M16,8.16227766 L14.3874259,13 L17.6125741,13 L16,8.16227766 Z M4,8.16227766 L2.38742589,13 L5.61257411,13 L4,8.16227766 Z'
            id='secondary'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
}
