import { FC } from 'react';
import Tab from '../components/Tabs/Tab';
import { useUser } from '../hooks';
import styles from '../styles/layout/skeleton.module.scss';

interface iSkeletonProps {
  sidebar: any;
}

const Skeleton: FC<iSkeletonProps> = ({ sidebar, children }) => {
  const { setUser } = useUser();
  const _handleLogout = () => setUser(null);
  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <img
          src='/images/logo/triyit-trademark.png'
          alt='Triyit logo'
          className={styles.logo}
        />
        <article>{sidebar}</article>
        <footer>
          <Tab onClick={_handleLogout} label='Log out' />
        </footer>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Skeleton;
