import React from 'react';

export default function IconTrash(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-trash</title>
      <g
        id='icon-trash'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,5 L18.1107386,20.117444 C18.0485547,21.1745693 17.1731425,22 16.1141898,22 L7.88581016,22 C6.82685754,22 5.95144525,21.1745693 5.88926141,20.117444 L5,5 L19,5 Z M10,10 C9.44771525,10 9,10.4477153 9,11 L9,11 L9,17 C9,17.5522847 9.44771525,18 10,18 C10.5522847,18 11,17.5522847 11,17 L11,17 L11,11 C11,10.4477153 10.5522847,10 10,10 Z M14,10 C13.4477153,10 13,10.4477153 13,11 L13,11 L13,17 C13,17.5522847 13.4477153,18 14,18 C14.5522847,18 15,17.5522847 15,17 L15,17 L15,11 C15,10.4477153 14.5522847,10 14,10 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M8.58578644,4 L10.2928932,2.29289322 C10.4804296,2.10535684 10.7347835,2 11,2 L13,2 C13.2652165,2 13.5195704,2.10535684 13.7071068,2.29289322 L15.4142136,4 L19,4 C19.5522847,4 20,4.44771525 20,5 C20,5.55228475 19.5522847,6 19,6 L5,6 C4.44771525,6 4,5.55228475 4,5 C4,4.44771525 4.44771525,4 5,4 L8.58578644,4 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
