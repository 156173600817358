import React from 'react';

export default function IconUserCheck(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-user-check</title>
      <g
        id='icon-user-check'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M11,13 C13.7614237,13 16,15.2385763 16,18 L16,19 C16,20.1045695 15.1045695,21 14,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,18 C2,15.2385763 4.23857625,13 7,13 L11,13 Z M21.7071068,7.29289322 C22.0976311,7.68341751 22.0976311,8.31658249 21.7071068,8.70710678 L17.7071068,12.7071068 C17.3165825,13.0976311 16.6834175,13.0976311 16.2928932,12.7071068 L14.2928932,10.7071068 C13.9023689,10.3165825 13.9023689,9.68341751 14.2928932,9.29289322 C14.6834175,8.90236893 15.3165825,8.90236893 15.7071068,9.29289322 L17,10.5857864 L20.2928932,7.29289322 C20.6834175,6.90236893 21.3165825,6.90236893 21.7071068,7.29289322 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
