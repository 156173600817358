import React from 'react';

export default function IconSurvey(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-survey</title>
      <g
        id='icon-survey'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,5 C20.1045695,5 21,5.8954305 21,7 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,7 C3,5.8954305 3.8954305,5 5,5 L19,5 Z M12,16 L8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L8,18 L12,18 C12.5522847,18 13,17.5522847 13,17 C13,16.4477153 12.5522847,16 12,16 L12,16 Z M16,12 L8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L8,14 L16,14 C16.5522847,14 17,13.5522847 17,13 C17,12.4477153 16.5522847,12 16,12 L16,12 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M15,4 C16.1045695,4 17,4.8954305 17,6 L17,7 C17,7.55228475 16.5522847,8 16,8 L8,8 C7.44771525,8 7,7.55228475 7,7 L7,6 C7,4.8954305 7.8954305,4 9,4 C9,2.8954305 9.8954305,2 11,2 L13,2 C14.1045695,2 15,2.8954305 15,4 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
