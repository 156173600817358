import React from 'react';

export default function IconTranslate(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-translate</title>
      <g
        id='icon-translate'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M10.4142136,10 L11.7071068,11.2928932 C12.0976311,11.6834175 12.0976311,12.3165825 11.7071068,12.7071068 C11.3165825,13.0976311 10.6834175,13.0976311 10.2928932,12.7071068 L9,11.4142136 L5.70710678,14.7071068 C5.31658249,15.0976311 4.68341751,15.0976311 4.29289322,14.7071068 C3.90236893,14.3165825 3.90236893,13.6834175 4.29289322,13.2928932 L7.58578644,10 L6.29289322,8.70710678 C5.90236893,8.31658249 5.90236893,7.68341751 6.29289322,7.29289322 C6.68341751,6.90236893 7.31658249,6.90236893 7.70710678,7.29289322 L9,8.58578644 L9.53553391,8.05025253 C10.3581335,7.22765295 10.8645056,6.147939 10.9764097,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L8,3 L8,2 C8,1.44771525 8.44771525,1 9,1 C9.55228475,1 10,1.44771525 10,2 L10,3 L15,3 C15.5522847,3 16,3.44771525 16,4 C16,4.55228475 15.5522847,5 15,5 L12.9831724,5 C12.8665464,6.67920822 12.1476468,8.26656677 10.9497475,9.46446609 L10.4142136,10 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M16.9230769,8.61538462 L21.9230769,20.6153846 C22.1354941,21.1251859 21.8944167,21.7106597 21.3846154,21.9230769 C20.8748141,22.1354941 20.2893403,21.8944167 20.0769231,21.3846154 L18.6666667,18 L13.3333333,18 L11.9230769,21.3846154 C11.7106597,21.8944167 11.1251859,22.1354941 10.6153846,21.9230769 C10.1055833,21.7106597 9.86450587,21.1251859 10.0769231,20.6153846 L15.0769231,8.61538462 C15.4188034,7.79487179 16.5811966,7.79487179 16.9230769,8.61538462 Z M16,11.6 L14.1666667,16 L17.8333333,16 L16,11.6 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
