import React from 'react';

export default function IconArrowsMerge(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-arrows-merge</title>
      <g
        id='icon-arrows-merge'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M22,22 L18,22 L18,20.1421356 C18,18.0204037 17.1571453,15.9855724 15.6568542,14.4852814 L10.2928932,9.12132034 C9.90236893,8.73079605 9.90236893,8.09763107 10.2928932,7.70710678 L11.7071068,6.29289322 C12.0976311,5.90236893 12.7307961,5.90236893 13.1213203,6.29289322 L18.4852814,11.6568542 L18.4852814,11.6568542 C20.7357179,13.9072908 22,16.9595377 22,20.1421356 L22,22 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
          transform='translate(16.000000, 14.000000) scale(-1, -1) translate(-16.000000, -14.000000) '
        />
        <path
          d='M13.945559,9 C13.6814351,11.7634576 12.4649034,14.3635237 10.4852814,16.3431458 L5.12132034,21.7071068 C4.73079605,22.0976311 4.09763107,22.0976311 3.70710678,21.7071068 L2.29289322,20.2928932 C1.90236893,19.9023689 1.90236893,19.2692039 2.29289322,18.8786797 L7.65685425,13.5147186 C8.88529614,12.2862767 9.67296816,10.6994591 9.91808382,9 L7,9 C6.10909515,9 5.66292836,7.92285808 6.29289322,7.29289322 L11.2928932,2.29289322 C11.6834175,1.90236893 12.3165825,1.90236893 12.7071068,2.29289322 L17.7071068,7.29289322 C18.3370716,7.92285808 17.8909049,9 17,9 L13.945559,9 L13.945559,9 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
