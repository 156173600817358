import React from 'react';

export default function IconSecurityImportant(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-security-important</title>
      <g
        id='icon-security-important'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M4,4 L12,2 L20,4 L20,13.0557281 C20,16.0859046 18.287981,18.8560095 15.5777088,20.2111456 L12,22 L12,22 L8.42229124,20.2111456 C5.71201901,18.8560095 4,16.0859046 4,13.0557281 L4,4 L4,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12,15 C12.8284271,15 13.5,15.6715729 13.5,16.5 C13.5,17.3284271 12.8284271,18 12,18 C11.1715729,18 10.5,17.3284271 10.5,16.5 C10.5,15.6715729 11.1715729,15 12,15 Z M12.4998701,6 C13.0914536,6 13.5537075,6.5108179 13.4948427,7.09950372 L12.9948752,12.0995037 C12.8748168,13.3001654 11.1249884,13.3001654 11.00493,12.0995037 L10.5049625,7.09950372 C10.4460977,6.5108179 10.9083516,6 11.4999351,6 L12.4998701,6 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
