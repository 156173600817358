import React from 'react';

export default function IconPrint(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-print</title>
      <g
        id='icon-print'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19,8 C20.1045695,8 21,8.8954305 21,10 L21,15 C21,16.1045695 20.1045695,17 19,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,10 C3,8.8954305 3.8954305,8 5,8 L19,8 Z M8,10 L6,10 C5.44771525,10 5,10.4477153 5,11 C5,11.5522847 5.44771525,12 6,12 L6,12 L8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 L8,10 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M18,14 L18,20 C18,20.5522847 17.5522847,21 17,21 L7,21 C6.44771525,21 6,20.5522847 6,20 L6,14 L18,14 Z M17,3 C17.5522847,3 18,3.44771525 18,4 L18,8 L6,8 L6,4 C6,3.44771525 6.44771525,3 7,3 L17,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
