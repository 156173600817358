import React from 'react';

export default function IconRefresh(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-refresh</title>
      <g
        id='icon-refresh'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M8.52334296,7.11076302 C9.52315518,6.39908436 10.7303273,6.00200408 12,6.00200408 C14.4077414,6.00200408 16.5532877,7.43316645 17.5005061,9.60088085 C17.7215836,10.1068177 17.4905223,10.6961194 16.9844164,10.917123 C16.4783105,11.1381266 15.8888119,10.9071425 15.6677344,10.4012056 C15.0359867,8.95544746 13.6056177,8.00133606 12,8.00133606 C11.2702348,8.00133606 10.572917,8.19717869 9.96774793,8.55468554 L10.7071068,9.29379743 C11.3370716,9.92355187 10.8909049,11.000334 10,11.000334 L7,11.000334 C6.44771525,11.000334 6,10.5527683 6,10.000668 L6,7.00167007 C6,6.1110628 7.07714192,5.66504503 7.70710678,6.29479947 L8.52334296,7.11076302 L8.52334296,7.11076302 Z M14.0322518,15.4453142 L13.2928932,14.7062026 C12.6629284,14.0764481 13.1090951,12.999666 14,12.999666 L17,12.999666 C17.5522847,12.999666 18,13.4472317 18,13.999332 L18,16.9983299 C18,17.8889372 16.9228581,18.334955 16.2928932,17.7052005 L15.4766562,16.8892362 C14.4768203,17.6009335 13.269649,17.9979959 12,17.9979959 C9.59438263,17.9979959 7.45036627,16.5693565 6.5018576,14.4045213 C6.28028035,13.898803 6.51075919,13.3092734 7.01664649,13.0877702 C7.5225338,12.8662669 8.11226034,13.0966688 8.33383759,13.6023871 C8.96645195,15.0462388 10.3957945,15.9986639 12,15.9986639 C12.7297567,15.9986639 13.4270722,15.8028293 14.0322518,15.4453142 L14.0322518,15.4453142 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
