import React from 'react';

export default function IconBrick(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-brick</title>
      <g
        id='icon-brick'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M3,6 L12,10 L12,22 L3,18 L3,6 Z M17,3 L17,5 C17,6.1045695 14.7614237,7 12,7 C9.23857625,7 7,6.1045695 7,5 L7,3 C7,4.1045695 9.23857625,5 12,5 C14.7614237,5 17,4.1045695 17,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <polygon
          id='secondary'
          fill='currentColor'
          points='21 6 12 10 12 22 21 18'
        />
      </g>
    </svg>
  );
}
