import React from 'react';

export default function IconMonitor(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-monitor</title>
      <g
        id='icon-monitor'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M21,14 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,14 L6,14 C6.33435319,14 6.64658452,13.8328989 6.83205029,13.5547002 L10,8.80277564 L15.1679497,16.5547002 C15.5637718,17.1484333 16.4362282,17.1484333 16.8320503,16.5547002 L18.5351838,14 L21,14 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M21,12 L18,12 C17.6656468,12 17.3534155,12.1671011 17.1679497,12.4452998 L16,14.1972244 L10.8320503,6.4452998 C10.4362282,5.85156673 9.56377175,5.85156673 9.16794971,6.4452998 L5.46481624,12 L3,12 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,12 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
