import React from 'react';

export default function IconArrowsSplit(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-arrows-split</title>
      <g
        id='icon-arrows-split'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M14.0012393,16.0012393 L10.5501847,12.5501847 C11.124204,10.7205939 12.1338022,9.03777064 13.5147186,7.65685425 L15.8786797,5.29289322 L14.2928932,3.70710678 C13.6629284,3.07714192 14.1090951,2 15,2 L21,2 C21.5522847,2 22,2.44771525 22,3 L22,9 C22,9.89090485 20.9228581,10.3370716 20.2928932,9.70710678 L18.7071068,8.12132034 L16.3431458,10.4852814 C14.8760956,11.9523315 14.0376718,13.9304974 14.0012393,16.0012393 Z'
          id='Path'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M8.12132034,5.29289322 L10.4852814,7.65685425 C12.7357179,9.90729079 14,12.9595377 14,16.1421356 L14,21 C14,21.5522847 13.5522847,22 13,22 L11,22 C10.4477153,22 10,21.5522847 10,21 L10,16.1421356 C10,14.0204037 9.15714528,11.9855724 7.65685425,10.4852814 L5.29289322,8.12132034 L3.70710678,9.70710678 C3.07714192,10.3370716 2,9.89090485 2,9 L2,3 C2,2.44771525 2.44771525,2 3,2 L9,2 C9.89090485,2 10.3370716,3.07714192 9.70710678,3.70710678 L8.12132034,5.29289322 L8.12132034,5.29289322 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
