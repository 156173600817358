import React from 'react';

export default function IconMicrophone(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-microphone</title>
      <g
        id='icon-microphone'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,1 C14.209139,1 16,2.790861 16,5 L16,11 C16,13.209139 14.209139,15 12,15 C9.790861,15 8,13.209139 8,11 L8,5 C8,2.790861 9.790861,1 12,1 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
        <path
          d='M13,18.9381062 L13,21 L16,21 C16.5522847,21 17,21.4477153 17,22 C17,22.5522847 16.5522847,23 16,23 L8,23 C7.44771525,23 7,22.5522847 7,22 C7,21.4477153 7.44771525,21 8,21 L11,21 L11,18.9381062 C7.05368842,18.4460082 4,15.0796177 4,11 C4,10.4477153 4.44771525,10 5,10 C5.55228475,10 6,10.4477153 6,11 C6,14.3137085 8.6862915,17 12,17 C15.3137085,17 18,14.3137085 18,11 C18,10.4477153 18.4477153,10 19,10 C19.5522847,10 20,10.4477153 20,11 C20,15.0796177 16.9463116,18.4460082 13,18.9381062 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
