import React from 'react';

export default function IconCloseSquare(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-close-square</title>
      <g
        id='icon-close-square'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={3}
          y={3}
          width={18}
          height={18}
          rx={2}
        />
        <path
          d='M13.4142136,12 L16.2426407,14.8284271 C16.633165,15.2189514 16.633165,15.8521164 16.2426407,16.2426407 C15.8521164,16.633165 15.2189514,16.633165 14.8284271,16.2426407 L12,13.4142136 L9.17157288,16.2426407 C8.78104858,16.633165 8.1478836,16.633165 7.75735931,16.2426407 C7.36683502,15.8521164 7.36683502,15.2189514 7.75735931,14.8284271 L10.5857864,12 L7.75735931,9.17157288 C7.36683502,8.78104858 7.36683502,8.1478836 7.75735931,7.75735931 C8.1478836,7.36683502 8.78104858,7.36683502 9.17157288,7.75735931 L12,10.5857864 L14.8284271,7.75735931 C15.2189514,7.36683502 15.8521164,7.36683502 16.2426407,7.75735931 C16.633165,8.1478836 16.633165,8.78104858 16.2426407,9.17157288 L13.4142136,12 L13.4142136,12 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
