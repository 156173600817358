import { FC, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Query,
  Segment,
  Table,
} from '../../../components';
import { useUser } from '../../../hooks';
import network from '../../../core/network';
import { PageSkeleton } from '../../../layout';
import Loading from '../../Survey/Loading';

const DEFAULT_QUERY = `SELECT DISTINCT users.id, firstname
FROM public.user AS users
INNER JOIN get_users_by_answer('home', '{"The kids"}') AS a1 USING(id)
INNER JOIN get_users_by_answer('gender', '{"Female"}') AS a2 USING(id)`;

interface iResult {
  data: null | any[];
  error: null | string;
  loading: boolean;
}

const QueryPage: FC = () => {
  const { user } = useUser();
  const [query, setQuery] = useState<string>(DEFAULT_QUERY);
  const [res, setRes] = useState<iResult>({
    data: null,
    error: null,
    loading: false,
  });

  const _handleQuery = async () => {
    setRes({ data: null, loading: true, error: null });

    const res = await network.database.query(query, user?.token ?? '');

    if (res.data) setRes({ data: res.data, error: null, loading: false });
    else setRes({ data: null, error: res.error, loading: false });

    return res.data ?? [];
  };

  const _handleUserGroup = async () => {
    const results = await _handleQuery();
    const ids = results?.map((row: any) => row.id);
    if (ids.every((id: string | undefined) => !id)) {
      return alert('No id field found, are you sure you are returning one?');
    }
    const name = prompt('Name of user group');
    if (!name) return;

    network.group.createUserGroup(name, query, user?.token ?? '');
  };

  return (
    <PageSkeleton
      title='Query'
      pageTitle='Query database'
      header={
        <ButtonGroup>
          <Button
            label='Create user group based on query'
            onClick={_handleUserGroup}
            suffix='icon-user-add'
          />
          <Button
            label='Execute'
            onClick={_handleQuery}
            suffix='icon-play'
            primary
          />
        </ButtonGroup>
      }
    >
      <Segment title='Query database'>
        <Query
          {...{ code: query, setCode: setQuery, onSubmit: _handleQuery }}
        />
      </Segment>
      {res.loading && <Loading />}
      {res.data && (
        <Segment title='Results'>
          <Table data={res.data} />
        </Segment>
      )}
      {res.error && (
        <Segment title='Error'>
          <pre>{res.error}</pre>
        </Segment>
      )}
    </PageSkeleton>
  );
};

export default QueryPage;
