import React from 'react';

export default function IconVideocam(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-videocam</title>
      <g
        id='icon-videocam'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M13.5857864,12.0020048 L20.2928932,5.29489797 C20.9228581,4.66493311 22,5.1110999 22,6.00200475 L22,18.0020048 C22,18.8929096 20.9228581,19.3390764 20.2928932,18.7091115 L13.5857864,12.0020048 Z'
          id='secondary'
          fill='currentColor'
          fillRule='nonzero'
        />
        <rect
          id='primary'
          fill='currentColor'
          opacity='0.4'
          x={2}
          y={5}
          width={14}
          height={14}
          rx={2}
        />
      </g>
    </svg>
  );
}
