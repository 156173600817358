import React from 'react';

export default function IconDashboard(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-dashboard</title>
      <g
        id='icon-dashboard'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 L3,11 L5,11 Z M21,11 L21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 L21,11 Z M6.34314575,4.92893219 L7.75735931,6.34314575 C8.1478836,6.73367004 8.1478836,7.36683502 7.75735931,7.75735931 C7.36683502,8.1478836 6.73367004,8.1478836 6.34314575,7.75735931 L4.92893219,6.34314575 L6.34314575,4.92893219 Z M17.6568542,4.92893219 L19.0710678,6.34314575 L17.6568542,7.75735931 C17.26633,8.1478836 16.633165,8.1478836 16.2426407,7.75735931 C15.8521164,7.36683502 15.8521164,6.73367004 16.2426407,6.34314575 L17.6568542,4.92893219 Z M13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 L13,3 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,13.4571486 4.38957714,14.8233065 5.07025756,16 L5.07025756,16 L18.9297424,16 C19.6104229,14.8233065 20,13.4571486 20,12 C20,7.581722 16.418278,4 12,4 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
        <path
          d='M12,8 C12.5522847,8 13,8.44771525 13,9 L13.0010775,14.2681881 C13.5982846,14.6141507 14,15.2601625 14,16 C14,17.1045695 13.1045695,18 12,18 C10.8954305,18 10,17.1045695 10,16 C10,15.2597476 10.4021661,14.6134261 10.9999275,14.2676063 L11,9 C11,8.44771525 11.4477153,8 12,8 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
