import React from 'react';

export default function IconSortAscending(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-sort-ascending</title>
      <g
        id='icon-sort-ascending'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M18,13 L18,20 C18,20.5522847 17.5522847,21 17,21 C16.4477153,21 16,20.5522847 16,20 L16,13 L13,13 C12.7440777,13 12.4881554,12.9023689 12.2928932,12.7071068 C11.9023689,12.3165825 11.9023689,11.6834175 12.2928932,11.2928932 L16.2928932,7.29289322 C16.6834175,6.90236893 17.3165825,6.90236893 17.7071068,7.29289322 L21.7071068,11.2928932 C22.0976311,11.6834175 22.0976311,12.3165825 21.7071068,12.7071068 C21.5118446,12.9023689 21.2559223,13 21,13 L18,13 Z'
          id='secondary'
          fill='currentColor'
        />
        <path
          d='M8,11 C8.55228475,11 9,11.4477153 9,12 C9,12.5522847 8.55228475,13 8,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L8,11 Z M12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 L3,9 C2.44771525,9 2,8.55228475 2,8 C2,7.44771525 2.44771525,7 3,7 L12,7 Z M16,3 C16.5522847,3 17,3.44771525 17,4 C17,4.55228475 16.5522847,5 16,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L16,3 Z'
          id='primary'
          fill='currentColor'
          opacity='0.4'
        />
      </g>
    </svg>
  );
}
