import React from 'react';

export default function IconCurrencyDollar(props) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>icon-currency-dollar</title>
      <g
        id='icon-currency-dollar'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='primary'
          fill='currentColor'
          opacity='0.4'
          cx={12}
          cy={12}
          r={10}
        />
        <path
          d='M12,4 C12.5522847,4 13,4.44771525 13,5 L13,5.07948388 C14.6565444,5.34614603 16.0631219,6.26650374 16.6790259,7.57806131 C16.9137815,8.07796956 16.6988328,8.67353227 16.1989246,8.90828787 C15.6990163,9.14304347 15.1034536,8.92809478 14.868698,8.42818653 C14.5865952,7.82745317 13.8891051,7.33399373 13,7.11780953 L13,11.0794602 C15.2498656,11.4417429 17,13.0055451 17,15 C17,16.9944549 15.2498656,18.5582571 13,18.9205398 L13,19 C13,19.5522847 12.5522847,20 12,20 C11.4477153,20 11,19.5522847 11,19 L11,18.920517 C9.34798235,18.6545866 7.94443455,17.738513 7.32585688,16.4322929 C7.08948069,15.9331489 7.30249609,15.3368919 7.80164009,15.1005157 C8.3007841,14.8641395 8.89704103,15.0771549 9.13341723,15.5762989 C9.41701524,16.175159 10.1132776,16.6665832 11,16.8821888 L11,12.9205398 C8.75013439,12.5582571 7,10.9944549 7,9 C7,7.00554505 8.75013439,5.44174287 11,5.07946022 L11,5 C11,4.44771525 11.4477153,4 12,4 Z M13,13.1164622 L13,16.8835378 C14.1883038,16.5963999 15,15.81823 15,15 C15,14.18177 14.1883038,13.4036001 13,13.1164622 Z M11,7.11646216 C9.81169624,7.4036001 9,8.18177003 9,9 C9,9.81822997 9.81169624,10.5963999 11,10.8835378 L11,7.11646216 Z'
          id='secondary'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
