import { FC } from 'react';
import { SurveyBanner } from '../../components';
import { useUser } from '../../hooks';
import { useTodos } from '../../hooks/survey';
import { PageSkeleton } from '../../layout';
import styles from '../../styles/pages/notifications.module.scss';
import Loading from '../Survey/Loading';

const Notifications: FC = () => {
  const { user } = useUser();
  const { loading, todos } = useTodos();
  return (
    <PageSkeleton title='Notifications'>
      {loading ? (
        <Loading />
      ) : (
        <>
          {todos?.length ? (
            <div className={styles.todos}>
              <h3>Remaining surveys</h3>
              <div className={styles.surveys}>
                {todos.map((survey: any) => (
                  <SurveyBanner {...survey} key={survey.id} />
                ))}
              </div>
            </div>
          ) : (
            <>
              <h1>🥳</h1>
              <h2>WOOHOO {user?.firstname}</h2>
              <p>
                Your profile is now fully complete and you're eligible for
                campaign matchmaking.
              </p>
              <p>
                We will be in touch when we have a campaign that's right for
                you.
              </p>
            </>
          )}
        </>
      )}
    </PageSkeleton>
  );
};

export default Notifications;
