import { FC } from 'react';
import { Button, ButtonGroup, CampaignStatus } from '../../../components';
import { Loader, PageSkeleton } from '../../../layout';
import { useCampaigns } from '../../../hooks';

const CampaigsPage: FC = () => {
  const { loading, campaigns, statuses, createCampaign, openModal } =
    useCampaigns();
  return (
    <PageSkeleton
      title='Campaigns'
      header={
        <ButtonGroup>
          <Button
            label='Create new'
            suffix='plus'
            primary
            onClick={createCampaign}
          />
        </ButtonGroup>
      }
    >
      <Loader {...{ loading }}>
        {statuses.map((status) => (
          <CampaignStatus {...{ status, openModal, campaigns }} key={status} />
        ))}
      </Loader>
    </PageSkeleton>
  );
};

export default CampaigsPage;
